import React from 'react'
import Header from '../component/header';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
// import { ChevronUp } from 'react-bootstrap-icons';
// import ExpandLessIcon from '@mui/icons-material/ExpandLess';
function Home() {
    return (
        <>
           
            {/* header-section start */}
            <Header/>
            {/* Hero Section Starts */}
            <section className="hero_area pt-120 pb-16 position-relative">
                <div className="container z-1">
                    <div className="row justify-content-center mt-8 mt-sm-13 mt-md-0">
                        <div className="col-xl-9">
                            <div className="hero_area__content pt-17 pt-sm-20 pt-lg-0 text-center">
                                <h2 className="fs-five py-2 px-3 px-sm-5 mb-4 wow fadeInUp">
                                ALDER COIN
                                </h2>
                                <h1 className="display-three mb-5 mb-md-6 wow fadeInUp">
                                EMPOWERING WITH INTELLIGENCE
                                </h1>
                                <p className="mb-8 mb-md-10 wow fadeInUp">
                                Alder Coin combines the power of decentralization with advanced technology to offer a secure, scalable, and efficient solution for modern financial needs
                                </p>
                                <div className="d-flex align-items-center justify-content-center flex-wrap gap-4 gap-md-6 mb-10 mb-md-13 wow fadeInUp">
                                    <a
                                        className="hero_area__content-btnone cmn-btn px-6 px-md-8 py-3 d-center gap-3"
                                        href="#javascript:void(0)"
                                    >
                                        Get Started
                                        <i className="ti ti-chevron-right fs-five px-1 bg4-color p6-color rounded-3 fw-bolder" />
                                    </a>
                                    <a
                                        className="hero_area__content-btntwo cmn-btn third-alt px-6 px-md-8 py-3 d-center gap-3"
                                        href="#javascript:void(0)"
                                    >
                                        Explore
                                        <i className="ti ti-chevron-right fs-five px-1 bg2-color rounded-3 fw-bolder" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="hero_area__thumb wow fadeInUp position-relative">
                                <img
                                    className="rounded-5"
                                    src="assets/images/hero-banner2.png"
                                    alt="Hero Banner"
                                />
                                <img
                                    className="hero_area__thumb-style leftright-animation position-absolute"
                                    src="assets/images/round-image-for-home-page.png"
                                    alt="Image"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                <div className="hero_area__shape">
                    <img
                        className="position-absolute rotated_animattwo"
                        src="assets/images/hero-shape.png"
                        alt="Shape"
                    />
                </div>
                <div className="hero_area__lishtone">
                    <img
                        className="position-absolute opacity-75"
                        src="assets/images/lightone.png"
                        alt="light"
                    />
                </div>
                <div className="hero_area__lishttwo">
                    <img
                        className="position-absolute opacity-75"
                        src="assets/images/lighttwo.png"
                        alt="light"
                    />
                </div>
            </section>
            {/* Hero Section Ends */}
            {/* Brand Slider Starts */}
            <div className="brand_slider overflow-hidden pb-15 bg9-color">
                <div className="container-fluid pt-120">
                    <div className="row">
                        <div className="hero_area__sliderarea px-0">
                            <span className="hero_area__backgroundrote d-block" />
                            <div className="hero_area__sliders bg4-color">
                                <div className="swiper brad-carousel overflow-visible d-center">
                                    <div className="brandslider swiper-wrapper d-flex align-items-center mt-5 mt-md-0">
                                        <div className="swiper-slide">
                                            <div className="items-wrapper">
                                                <img src="assets/images/icon/ripple.png" alt="Brand" />
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="items-wrapper">
                                                <img src="assets/images/icon/coinbase.png" alt="Brand" />
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="items-wrapper">
                                                <img src="assets/images/icon/bitfinex.png" alt="Brand" />
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="items-wrapper">
                                                <img src="assets/images/icon/coinbase.png" alt="Brand" />
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="items-wrapper">
                                                <img src="assets/images/icon/steemit.png" alt="Brand" />
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="items-wrapper">
                                                <img src="assets/images/icon/bitfinex.png" alt="Brand" />
                                            </div>
                                        </div>
                                        <div className="swiper-slide">
                                            <div className="items-wrapper">
                                                <img src="assets/images/icon/coinbase.png" alt="Brand" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* Brand Slider Ends */}
            {/* Explore Coinx Web3 Products Starts */}
            <section className="web3_product how_join bg9-color pt-120 pb-120" id="technology">
                <div className="container">
                    <div className="row gy-5 gy-md-6">
                        <div className="how_join__title text-center mb-10 mb-md-15">
                            <h2 className="wow fadeInUp">ALDER TECHNOLOGY</h2>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xxl-3">
                            <div className="web3_product__item how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative wow fadeInUp">
                                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                                    <img src="assets/images/icon/network.png" alt="Icons" style={{ width: "100px", height: "100px" }} />
                                </div>
                                <h4 className="mb-4 mb-md-5">Decentralized Governance</h4>
                                <p className="mb-6 mb-md-8">
                                Alder empowers its community through
                                decentralized governance. Token holders
                                actively participate in decision- making
                                processes, shaping the future of the Alder
                                ecosystem
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xxl-3">
                            <div className="web3_product__item how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative wow fadeInUp">
                                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                                    <img src="assets/images/icon/binance.png" alt="Icons" style={{ width: "100px", height: "100px" }}/>
                                </div>
                                <h4 className="mb-4 mb-md-5">BUILT ON BSC</h4>
                                <p className="mb-6 mb-md-8">
                                Alder harnesses the speed and efficiency
                                of the Binance Smart Chain, providing
                                users with fast and cost-effective
                                transactions.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xxl-3">
                            <div className="web3_product__item how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative wow fadeInUp">
                                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                                    <img src="assets/images/icon/garf.png" alt="Icons" style={{ width: "100px", height: "100px" }}/>
                                </div>
                                <h4 className="mb-4 mb-md-5">Innovative Tokenomics</h4>
                                <p className="mb-6 mb-md-8">
                                Alder’s tokenomics are carefully crafted to
                                create a balanced and sustainable ecosystem.
                                From a finite total supply to staking mechanisms
                                and buyback strategies, Alder offers a
                                comprehensive approach to token economics.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xxl-3">
                            <div className="web3_product__item how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative wow fadeInUp">
                                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                                    <img src="assets/images/icon/chain.png" alt="Icons" style={{ width: "100px", height: "100px" }}/>
                                </div>
                                <h4 className="mb-4 mb-md-5">Smart Contract Audits</h4>
                                <p className="mb-6 mb-md-8">
                                Security is a top priority. Alder’s smart
                                contracts undergo regular audits by
                                reputable third-party firms, ensuring the
                                integrity and reliability of the platform.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Explore Coinx Web3 Products Ends */}
            {/* Markets Section starts */}
            <section className="market_more what_trending bg5-color pt-120 pb-120">
                <div className="container">
                <div className="row gy-5 gy-md-6">
                        <div className="how_join__title text-center mb-10 mb-md-15">
                            <h2 className="wow fadeInUp">ABOUT ALDER COIN</h2>
                            <span class="mt-5">Alder Coin is a cutting-edge cryptocurrency designed to provide stability, flexibility,
                                    and trust in the fast-evolving digital economy. Our token is built with the following key
                                    features:
                            </span>
                        </div>
                        <div className="col-sm-12 col-lg-4 col-xxl-4">
                            <div className="web3_product__item how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative wow fadeInUp">
                                <div className=" mb-4 mb-md-5 text-center p-6  ">
                                    <img src="assets/images/icon/stable coin.png" alt="Icons" style={{ width: "150", height: "150px" }}/>
                                </div>
                                <h4 className="mb-4 mb-md-5">Stable Token</h4>
                                <p className="mb-6 mb-md-8">
                                Alder Coin is engineered
                                to maintain a stable
                                value, making it a reliable
                                choice for everyday
                                transactions and long-
                                term investments.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4 col-xxl-4">
                            <div className="web3_product__item how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative wow fadeInUp">
                                <div className=" mb-4 mb-md-5 text-center p-6  ">
                                    <img src="assets/images/icon/lock.png" alt="Icons" style={{ width: "150", height: "150px" }}/>
                                </div>
                                <h4 className="mb-4 mb-md-5">Cross-Chain Token</h4>
                                <p className="mb-6 mb-md-8">
                                Designed to operate
                                seamlessly across multiple
                                blockchains, Alder Coin
                                ensures interoperability and
                                flexibility, allowing users to
                                transact on their preferred
                                networks.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-12 col-lg-4 col-xxl-4">
                            <div className="web3_product__item how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative wow fadeInUp">
                              <div className=" mb-4 mb-md-5 text-center p-6  ">
                                    <img src="assets/images/icon/binance.png" alt="Icons" style={{ width: "150", height: "150px" }}/>
                                </div>
                                <h4 className="mb-4 mb-md-5">Developed on Binance
                                Blockchain</h4>
                                <p className="mb-6 mb-md-8">
                                Leveraging the security and trust of
                                the Binance blockchain, Alder Coin
                                is built on one of the world’s most
                                trusted and scalable platforms,
                                ensuring robust performance and
                                widespread adoption.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Markets Section ends */}
            <section className="web3_product how_join bg9-color pt-120 pb-120"id="tokenomics">
                <div className="container">
                    <div className="row gy-5 gy-md-6">
                        <div className="how_join__title text-center mb-10 mb-md-15">
                            <h2 className="wow fadeInUp">TOKENOMICS</h2>
                        </div>
                        <div className="col-sm-6 col-lg-6 col-xxl-6">
                            <div className="web3_product__item how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative wow fadeInUp">
                                <h6 className="mb-2">Contract Address</h6>
                                <h5 className="mb-4 p1-color">0x55d398326f99059ff775485246999027b3197955</h5>
                                <hr className="p1-color"></hr>

                                <h6 className="mb-2 ">Token Name <b>Alder Coin</b></h6>
                                <h5 className="mb-4  p1-color">Alder Coin</h5>
                                <hr className="p1-color"></hr>
                                <h6 className="mb-2 ">Decimal</h6>
                                <h5 className="mb-4  p1-color">18</h5>
                                <hr className="p1-color"></hr>
                                <h6 className="mb-2 ">Total Supply</h6>
                                <h5 className="mb-4 p1-color ">21 Million</h5>
                                <hr className="p1-color"></hr>
                                <h6 className="mb-2 ">Circulating Supply</h6>
                                <h5 className="mb-4 p1-color">20 Million</h5>
                                <hr className="p1-color"></hr>
                                <h6 className="mb-2">Locked</h6>
                                <h5 className="mb-4 p1-color">1 Million</h5>
                            </div>
                            
                        </div>
                        <div className="col-sm-6 col-lg-6 col-xxl-6">
                            <div className="web3_product__item how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative wow fadeInUp">
                                <div className=" mb-4 mb-md-5 text-center p-6  ">
                                    <img src="assets/images/aldercoin_home.png" alt="Icons" style={{ height: "100%" }}/>
                                </div>
                                
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* How to get started Starts */}
            <section className="get_started pt-120 pb-120 bg7-colo">
                <div className="container">
                    <div className="row justify-content-center justify-content-sm-between align-items-center gy-5 gy-md-6 mb-10 mb-mb-15 pb-8 pb-md-10">
                        <div className="col-lg-5 col-lg-5 col-xxl-4">
                            <h2 className="text-center text-sm-start wow fadeInUp">
                                How to get started
                            </h2>
                        </div>
                        <div className="col-lg-7 col-lg-6 col-xxl-5">
                            <p className="roboto text-center text-sm-start wow fadeInUp">
                                Our comprehensive cybersecurity platform, driven by artificial
                                intelligence, not only safeguards your organization but also
                                educates your workforce.
                            </p>
                        </div>
                    </div>
                    <div className="row bg1-color rounded-4">
                        <div className="col-md-6 col-xl-4 col-xxl-3 p-xxl-0">
                            <div className="get_started__item px-5 py-13 text-center position-relative wow fadeInUp">
                                <span className="get_started__item-icn py-3 px-4 rounded-5 bg1-color mb-5 mb-md-6">
                                    <i className="ti ti-user fs-four" />
                                </span>
                                <h4 className="p1-color mb-5 mb-md-6">Create account</h4>
                                <span className="roboto mb-8 mb-mb-10 d-block">
                                    Sign in to Alder Coin to register a new profile. the verification
                                    process.
                                </span>
                                <a href="/sign_up"  className="cmn-btn third-alt py-3 px-5 px-md-6">
                                    Register now
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 col-xxl-3 p-xxl-0">
                            <div className="get_started__item px-5 py-13 text-center position-relative wow fadeInUp">
                                <span className="get_started__item-icn py-3 px-4 rounded-5 bg1-color mb-5 mb-md-6">
                                    <i className="ti ti-shield-filled fs-four" />
                                </span>
                                <h4 className="p1-color mb-5 mb-md-6">Verify your identity</h4>
                                <span className="roboto mb-8 mb-mb-10 d-block">
                                    Spend less than five minutes completing the verification process.
                                </span>
                                <a href="/sign_up"  className="cmn-btn third-alt py-3 px-5 px-md-6">
                                    Register now
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 col-xxl-3 p-xxl-0">
                            <div className="get_started__item px-5 py-13 text-center position-relative wow fadeInUp">
                                <span className="get_started__item-icn py-3 px-4 rounded-5 bg1-color mb-5 mb-md-6">
                                    <i className="ti ti-home-dollar fs-four" />
                                </span>
                                <h4 className="p1-color mb-5 mb-md-6">Deposit and buy crypto</h4>
                                <span className="roboto mb-8 mb-mb-10 d-block">
                                Depositing and buying on Web3 is made very easy and secure.
                                </span>
                                <a href="/sign_up"  className="cmn-btn third-alt py-3 px-5 px-md-6">
                                    Register now
                                </a>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4 col-xxl-3 p-xxl-0">
                            <div className="get_started__item px-5 py-13 text-center position-relative wow fadeInUp">
                                <span className="get_started__item-icn py-3 px-4 rounded-5 bg1-color mb-5 mb-md-6">
                                    <i className="ti ti-user fs-four" />
                                </span>
                                <h4 className="p1-color mb-5 mb-md-6">Start your journey</h4>
                                <span className="roboto mb-8 mb-mb-10 d-block">
                                    Less then 2 Minute start your earning journey.
                                </span>
                                <a href="/sign_up" className="cmn-btn third-alt py-3 px-5 px-md-6">
                                    Register now
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* How to get started Ends */}
            {/* Discover Web3 starts */}
            <section className="discover_web3 pools_tables pt-120 pb-120 bg9-color" id="revenue">
                <div className="container">
                <div className="row gy-5 gy-md-6">
                        <div className="col-12">
                            <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 wow fadeInUp">
                                <h2>
                                SOURCES OF  <span className="p1-color"> REVENUE</span>
                                </h2>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <img src="assets/images/source1.png" alt="revenue" />
                        </div>

                        <div className="col-xl-6">
                            <img src="assets/images/source2.png" alt="revenue" />
                        </div><div className="col-xl-6">
                            <img src="assets/images/source3.png" alt="revenue" />
                        </div><div className="col-xl-6">
                            <img src="assets/images/source4.png" alt="revenue" />
                        </div>
                    </div>
                </div>
            </section>
            {/* Discover Web3 ends */}
            {/* Earn crypto flexibly Starts */}
            <section className="earn_crypto pt-120 pb-120" id="roadmap">
                <div className="container">
                    <div className="row gy-5 gy-md-6">
                        <div className="col-12">
                            <div className="earn_crypto__title text-sm-center mb-7 mb-md-11 wow fadeInUp">
                                <h2>
                                   -- <span className="p1-color">ROADMAP</span> --
                                </h2>
                            </div>
                        </div>
                        <div className="col-md-3 col-xl-3 col-xxl-3 col-6 md-5">
                            <img src="assets/images/roadmap/block01.png" alt="roadmap" />
                        </div>
                        <div className="col-md-3 col-xl-3 col-xxl-3 col-6 md-5">
                            <img src="assets/images/roadmap/block2.png" alt="roadmap" />
                        </div>
                        {/* <div className="col-xl-3 md-5">
                            <img src="assets/images/roadmap/block3.png" alt="roadmap" />
                        </div> */}
                        <div className="col-md-3 col-xl-3 col-xxl-3 col-6 md-5">
                            <img src="assets/images/roadmap/block4.png" alt="roadmap" />
                        </div>
                        <div className="col-md-3 col-xl-3 col-xxl-3 col-6 md-5">
                            <img src="assets/images/roadmap/block5.png" alt="roadmap" />
                        </div>
                        <div className="col-md-3 col-xl-3 col-xxl-3 col-6 md-5">
                            <img src="assets/images/roadmap/block6.png" alt="roadmap" />
                        </div>
                        <div className="col-md-3 col-xl-3 col-xxl-3 col-6 md-5">
                            <img src="assets/images/roadmap/block7.png" alt="roadmap" />
                        </div>
                        <div className="col-md-3 col-xl-3 col-xxl-3 col-6 md-5">
                            <img src="assets/images/roadmap/block8.png" alt="roadmap" />
                        </div>
                        <div className="col-md-3 col-xl-3 col-xxl-3 col-6 md-5">
                            <img src="assets/images/roadmap/block9.png" alt="roadmap" />
                        </div>

                       
                    </div>
                </div>
            </section>
            {/* Earn crypto flexibly Ends */}
            {/* Life in the digital world Starts */}
            <section className="life_digital pt-120 pb-120">
                <div className="container">
                    <div className="row justify-content-center pb-10 pb-sm-15 pb-md-20">
                        <div className="col-xl-6">
                            <div className="life_digital__title text-center">
                                <span className="bg1-color rounded-20 py-2 px-5 mb-5 mb-md-6 wow fadeInUp">
                                    Blockchain Technology
                                </span>
                                <h2 className="wow fadeInUp">Life in the digital world</h2>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="counter_section__area d-flex align-items-center justify-content-center justify-content-lg-between flex-wrap flex-lg-nowrap gap-5 gap-md-10">
                            <div className="counter_section__item">
                                <div className="hero_area__countdown mb-3 mb-md-5 counters d-flex align-items-center justify-content-center">
                                    <span
                                        className="odometer hero_area__countdown-number display-three fw_500"
                                        data-odometer-final={8327}
                                    />
                                </div>
                                <h4 className="text-center mb-5 mb-md-6">Market price</h4>
                                <p className="text-center">
                                    Claritas est etiam processus dynamicus, sequitur consuetudium
                                    lectorum.
                                </p>
                            </div>
                            <span className="v-line lgx mb-20 pb-6 d-none d-lg-block" />
                            <div className="counter_section__item">
                                <div className="hero_area__countdown mb-3 mb-md-5 counters d-flex align-items-center justify-content-center">
                                    <span
                                        className="odometer hero_area__countdown-number display-three fw_500"
                                        data-odometer-final={16}
                                    />
                                    <span className="display-three fw_500">mb</span>
                                </div>
                                <h4 className="text-center mb-5 mb-md-6">Average block size</h4>
                                <p className="text-center">
                                    Claritas est etiam processus dynamicus, sequitur consuetudium
                                    lectorum.
                                </p>
                            </div>
                            <span className="v-line lgx mb-20 pb-6 d-none d-lg-block" />
                            <div className="counter_section__item">
                                <div className="hero_area__countdown mb-3 mb-md-5 counters d-flex align-items-center justify-content-center">
                                    <span
                                        className="odometer hero_area__countdown-number display-three fw_500"
                                        data-odometer-final={8327}
                                    />
                                </div>
                                <h4 className="text-center mb-5 mb-md-6">Clients worldwide</h4>
                                <p className="text-center">
                                    Claritas est etiam processus dynamicus, sequitur consuetudium
                                    lectorum.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Life in the digital world Ends */}
            {/* Rewards FAQs Starts */}
            <section className="rewards_faq pt-120 pb-120 position-relative" id="faq">
                <div className="container">
                    <div className="row align-items-center justify-content-center gy-8">
                        <div className="col-xl-7">
                            <div className="rewards_faq__content">
                                <h2 className="mb-5 mb-md-6 wow fadeInUp">FAQs</h2>
                                <p className="roboto mb-8 mb-md-10 wow fadeInUp">
                                    Explore our FAQs for fast, informative answers to frequently asked
                                    questions and common concerns.
                                </p>
                                <div className="accordion-section">
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What is the Alder Coin ?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                Alder Coin is a community-focused cryptocurrency designed to support and empower Web3 users. It facilitates secure and easy trading within a growing network of enthusiasts, all while providing unique rewards and benefits to its holders.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                               How do I buy Alder Coin?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                You can purchase Alder Coin directly through our platform by connecting your Web3 wallet. Simply go to the "Buy Alder Coin" section on our website, choose your desired amount, and follow the on-screen instructions to complete the transaction.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                 What wallets are compatible with Alder Coin?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                Alder Coin is compatible with most popular Web3 wallets, such as MetaMask, Trust Wallet, and Coinbase Wallet. Connect any of these wallets to securely store, send, and receive Alder Coin.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                 How can I use Alder Coin within the community?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                Alder Coin holders gain access to exclusive community benefits, including special events, governance voting, and reward programs. Additionally, you can use Alder Coin to trade with other cryptocurrencies on our platform or to participate in community-led initiatives.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                Is Alder Coin secure?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                Yes, Alder Coin is built on a secure blockchain and leverages decentralized Web3 technologies to ensure your transactions are safe. Our platform also adheres to best security practices, including wallet encryption and two-factor authentication.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What are the benefits of holding Alder Coin?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                Holding Alder Coin offers several advantages, including eligibility for rewards, access to community events, and the chance to participate in project governance. As the Alder Coin community grows, more opportunities and benefits will become available.
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-wrap gap-2 wow fadeInUp">
                                    <span>Can't see your question listed above?</span>
                                    <a
                                        href="javascript:void(0)"
                                        className="d-flex align-items-center gap-1 p6-color astyle"
                                    >
                                        Visit our Help Center{" "}
                                        <i className="ti ti-arrow-narrow-right fs-four mt-1" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="rewards_faq__thumb">
                                <img
                                    src="assets/images/faq-thumb.png"
                                    className="max-un leftright-animation"
                                    alt="Images"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <img class="rewards_faq__light position-absolute top-0 start-0" src="assets/images/light-faq.png" alt="Icon"> */}
            </section>
            {/* Rewards FAQs Ends */}
            {/* Footer Section Starts */}
            <footer className="footer pt-120 bg5-color">
                <div className="container">
                    <div className="row gy-8 pb-120 justify-content-between">
                        <div className="col-md-12 col-lg-6 col-xxl-5">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <h2>Alder Coin </h2>
                                </a>
                                <p className="mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp">
                                Welcome to Alder Coin, your gateway to the Web3 community! Our user-friendly platform empowers you to explore and trade Alder Coin alongside popular cryptocurrencies, all while connecting with a vibrant community of enthusiasts.
                                </p>
                                <p >Support : support@alderblockchain.com</p>
                                <div className="footer__decs-subscribe mb-9 mb-md-12 wow fadeInUp mt-5">
                                    <form className="d-flex align-items-center rounded-1 py-2 pe-2">
                                        <input type="email" placeholder="Enter your email" />
                                        <button className="cmn-btn py-3  px-8 rounded-1 d-center gap-2">
                                            Subscribe
                                            <i className="ti ti-send fs-five d-none d-md-block" />
                                        </button>
                                    </form>
                                </div>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                                   
                                    <a href="javascript:void(0)" className=" p4-color fs-four fw-normal p-2" >
                                        <img src="assets/images/icon/facebook-icon.png" style={{ height: "30px" }}/> 
                                    </a>
                                    <a href="javascript:void(0)" className=" p4-color fs-four fw-normal p-2" >
                                        <img src="assets/images/icon/twitter-icon.png" style={{ height: "30px" }}/> 
                                    </a>
                                    <a href="javascript:void(0)" className=" p4-color fs-four fw-normal p-2" >
                                        <img src="assets/images/icon/youtube-icon.png" style={{ height: "30px" }}/> 
                                    </a>
                                    <a href="javascript:void(0)" className=" p4-color fs-four fw-normal p-2" >
                                        <img src="assets/images/icon/instagram-icon.png" style={{ height: "30px" }}/> 
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2 ms-xxl-20">
                            <div className="footer__discover">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Company</h4>
                                <div className="footer__discover-nav">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">About Alder Coin</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="/sign_up">Join Us</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">Blog</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">Academy</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Security</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">Contact Validator</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">Protection fund</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">Security</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Assets</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">BNB Chain</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">Ethereum</a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)">ERC20</a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-md-start">
                                        Copyright 2024 -
                                        <a href="/" className="p4-color">
                                            Alder Coin
                                        </a>
                                        All Rights Reserved {" "}
                                        <span className="p4-color fs-five mx-2">|</span> 
                                        <a
                                            href="Javascript:void(0)"
                                            className="p4-color roboto"
                                        >
                                            
                                        </a>
                                    </span>
                                </div>
                                <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="p4-color roboto ps-4 ps-sm-6"
                                    >
                                        Privacy Policy
                                    </a>
                                    <span className="p4-color fs-five">|</span>
                                    <a href="javascript:void(0)" className="p4-color roboto">
                                        Cookie Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section Ends */}
        </>
    )
}

export default Home