import React from 'react'

function Ido() {
    return (
        <>
            {/* Scroll To Top Start*/}
            <button
                className="scrollToTop d-none d-md-flex d-center"
                aria-label="scroll Bar Button"
            >
                <i className="ti ti-chevron-up fs-four p6-color" />
            </button>
            {/* Scroll To Top End */}
            {/* header-section start */}
            <div className="navbar_top bg2-color py-4 d-none d-lg-block">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 col-xxl-5">
                            <div className="navbar_top__left d-flex align-items-center gap-2 gap-xl-6">
                                <div className="navbar_top__location d-flex align-items-center gap-1 gap-xl-3">
                                    <i className="ti ti-map-pin-filled fs-four p7-color" />
                                    <span className="roboto p7-color">
                                        102/B New Market, Sandigo-USA
                                    </span>
                                </div>
                                <span className="v-line mb-9" />
                                <a
                                    href="https://softivus.com/cdn-cgi/l/email-protection#e88d90898598848da88f85898184c68b8785"
                                    className="navbar_top__email roboto p7-color d-flex align-items-center gap-3"
                                >
                                    <i className="ti ti-mail-opened-filled fs-four" />
                                    <span
                                        className="__cf_email__"
                                        data-cfemail="dabfa2bbb7aab6bf9abdb7bbb3b6f4b9b5b7"
                                    >
                                        [email&nbsp;protected]
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xxl-6">
                            <div className="navbar_top__right d-flex align-items-center justify-content-end gap-2 gap-xl-6">
                                <div className="navbar_top__call d-flex align-items-center gap-3">
                                    <span className="bg6-color py-2 px-3 rounded-item">
                                        <i className="ti ti-phone-call fs-four p7-color " />
                                    </span>
                                    <div>
                                        <span className="p7-color fw-bolder d-block">Contact Us:</span>
                                        <a href="tel:+9802344567" className="d-block p7-color">
                                            +980 234 4567
                                        </a>
                                    </div>
                                </div>
                                <span className="v-line mb-9" />
                                <div className="navbar_top__social d-flex align-items-center gap-2 gap-xl-3">
                                    <span className="p7-color fw-bolder">Follow Us:</span>
                                    <div className="navbar_top__social-icon d-flex align-items-center  gap-1 gap-xl-2">
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-facebook fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-instagram fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-twitter fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-linkedin fs-four p7-color " />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
                <div className="navbar_mainhead header-fixed w-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 workready">
                                    <a
                                        href="/"
                                        className="navbar-brand d-flex align-items-center gap-2"
                                    >
                                        <img src="assets/images/logo.png" className="logo" alt="logo" />
                                    </a>
                                    <div
                                        className="collapse navbar-collapse justify-content-between"
                                        id="navbar-content"
                                    >
                                        <ul className="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                                            <li className="dropdown">
                                                <a href="/" className="fs-ten">
                                                    Home
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Staking <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/staking">
                                                            Staking Pool
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/staking_details"
                                                        >
                                                            Pool details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    IDO <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/ido">
                                                            IDO
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/idodetails"
                                                        >
                                                            IDO Details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Swap <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/swap">
                                                            Swap
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/swap_checkout"
                                                        >
                                                            Swap to share
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/apes" className="fs-ten">
                                                    ApeX
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Buy Crypto
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/buycrypto"
                                                        >
                                                            Buy Crypto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/pricing_plan"
                                                        >
                                                            Pricing plan
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Pages
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/rewards">
                                                            Rewards
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogs"
                                                        >
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogdetails"
                                                        >
                                                            Blog details
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/contact_us"
                                                        >
                                                            Contact us
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/terms_conditions"
                                                        >
                                                            Terms &amp; condition
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/error">
                                                            Error
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                                        <div className="single-item cart-area search-area">
                                            <div className="cmn-head">
                                                <button
                                                    type="button"
                                                    aria-label="Shopping Button"
                                                    className="common_toggles2 icon-area p-0 me-3 me-lg-0 box-second d-center position-relative"
                                                >
                                                    <i className="ti ti-search slide-toggle2 fs-four p6-color" />
                                                </button>
                                                <div className="msg_area common_area2 p2-bg p-5 rounded-2">
                                                    <form className="d-flex align-items-center ">
                                                        <input type="text" />
                                                        <button type="submit" className="p-2">
                                                            <i className="ti ti-search slide-toggle2 fs-four p2-color" />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-section__modalstyle">
                                            {/* Button trigger modal */}
                                            <button
                                                type="button"
                                                className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <span className="p7-color fw-semibold d-none d-sm-block">
                                                    Connect
                                                </span>
                                                Wallet
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        aria-label="Navbar Toggler"
                                        data-bs-target="#navbar-content"
                                        aria-expanded="true"
                                        id="nav-icon3"
                                    >
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="modal_areastyle">
                {/* Modal */}
                <div
                    className="modal"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content px-2 px-md-6 pt-md-3 pb-2 pb-md-6">
                            <div className="modal-header border-0">
                                <h3 className="modal-title p1-color" id="exampleModalLabel">
                                    Connect Your Wallet
                                </h3>
                                <button
                                    type="button"
                                    className="btn-close p7-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="ti ti-xbox-x p6-color fs-three fw-normal" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between mb-5 mb-md-6"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/dog.png" alt="Icon" />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/wallet-connect.png" alt="Icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* header-section end */}
            {/*Common hero Section Starts */}
            <section className="cmn_heros pb-120 pt-120">
                <div className="container">
                    <div className="row justify-content-center mt-5 mt-md-8 mt-lg-0">
                        <div className="col-xxl-6">
                            <div className="cmn_heros__title text-center pt-15 pt-lg-6">
                                <h1 className="display-three mb-5 mb-md-7 wow fadeInUp">IDO</h1>
                                <p className="roboto wow fadeInUp">
                                    Earn passive income easily with just one click!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Common hero Section Ends */}
            {/* Odometer Section Starts */}
            <section className="counter_section pb-120 pt-120 bg5-color">
                <div className="container">
                    <div className="row">
                        <div className="counter_section__area d-flex align-items-center justify-content-center justify-content-sm-between flex-wrap gap-5">
                            <div className="counter_section__item">
                                <div className="hero_area__countdown counters d-flex align-items-center justify-content-center">
                                    <span className="fs-one fw_500">$</span>
                                    <span
                                        className="odometer hero_area__countdown-number fs-one fw_500"
                                        data-odometer-final="498.7"
                                    />
                                    <span className="fs-one fw_500">K</span>
                                </div>
                                <h4 className="text-center">Total Raised</h4>
                            </div>
                            <span className="v-line lgx mb-20 pb-6 d-none d-md-block" />
                            <div className="counter_section__item">
                                <div className="hero_area__countdown counters d-flex align-items-center justify-content-center">
                                    <span
                                        className="odometer hero_area__countdown-number fs-one fw_500"
                                        data-odometer-final="245.91"
                                    />
                                    <span className="fs-one fw_500">K</span>
                                </div>
                                <h4 className="text-center">Total Participants</h4>
                            </div>
                            <span className="v-line lgx mb-20 pb-6 d-none d-md-block" />
                            <div className="counter_section__item">
                                <div className="hero_area__countdown counters d-flex align-items-center justify-content-center">
                                    <span
                                        className="odometer hero_area__countdown-number fs-one fw_500"
                                        data-odometer-final="1700.84"
                                    />
                                    <span className="fs-one fw_500">%</span>
                                </div>
                                <h4 className="text-center">Average ROI</h4>
                            </div>
                            <span className="v-line lgx mb-20 pb-6 d-none d-xl-block" />
                            <div className="counter_section__item">
                                <div className="hero_area__countdown counters d-flex align-items-center justify-content-center">
                                    <span
                                        className="odometer hero_area__countdown-number fs-one fw_500"
                                        data-odometer-final={12}
                                    />
                                </div>
                                <h4 className="text-center">Number of Projects</h4>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Odometer Section Ends */}
            {/* Completed Projects Starts */}
            <section className="completed_project pt-120 pb-120">
                <div className="container">
                    <div className="row gy-5 gy-md-6">
                        <div className="completed_project__title mb-10 mb-md-15">
                            <h2 className="wow fadeInUp">Completed Projects</h2>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="completed_project__item br2 rounded-3 bg1-color px-5 px-md-6 py-3 py-sm-6 py-lg-10 wow fadeInUp">
                                <div className="completed_project__itemhead d-flex gap-3 mb-7 mb-md-10">
                                    <div className="completed_project__itemhead-icon">
                                        <img src="assets/images/icon/pop-social.png" alt="Icon" />
                                    </div>
                                    <div className="completed_project__itemhead-content">
                                        <a href="javascript:void(0)">
                                            <h3 className="mb-1">Pop Social</h3>
                                        </a>
                                        <span className="fs-five">Ppt</span>
                                    </div>
                                </div>
                                <div className="completed_project__roitotal d-flex align-items-center justify-content-between gap-2 mb-5 mb-md-6">
                                    <div className="text-start">
                                        <span className="fs-five mb-1">Peak ROI</span>
                                        <h3 className="p2-color">716.8%</h3>
                                    </div>
                                    <div className="text-end">
                                        <span className="fs-five mb-1">Total Participants</span>
                                        <h3>24,194</h3>
                                    </div>
                                </div>
                                <p className="roboto mb-7 mb-md-10">
                                    Connect, share, and engage with our vibrant Pop Social community.
                                </p>
                                <div className="completed_project__ccard p-2 p-sm-3 p-lg-4 rounded-2 bg1-color br2">
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Raised</span>
                                        <span className="roboto">$48,200/$50,000</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Issued</span>
                                        <span className="roboto">500,000 PPT</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between flex-wrap mb-3">
                                        <span className="roboto">Completed At</span>
                                        <span className="roboto">2024-10-25 10:00:00 UTC</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="completed_project__item br2 rounded-3 bg1-color px-5 px-md-6 py-3 py-sm-6 py-lg-10 wow fadeInUp">
                                <div className="completed_project__itemhead d-flex gap-3 mb-7 mb-md-10">
                                    <div className="completed_project__itemhead-icon">
                                        <img src="assets/images/icon/cyber-arena.png" alt="Icon" />
                                    </div>
                                    <div className="completed_project__itemhead-content">
                                        <a href="javascript:void(0)">
                                            <h3 className="mb-1">Cyber Arena</h3>
                                        </a>
                                        <span className="fs-five">CAT</span>
                                    </div>
                                </div>
                                <div className="completed_project__roitotal d-flex align-items-center justify-content-between gap-2 mb-5 mb-md-6">
                                    <div className="text-start">
                                        <span className="fs-five mb-1">Peak ROI</span>
                                        <h3 className="p2-color">716.8%</h3>
                                    </div>
                                    <div className="text-end">
                                        <span className="fs-five mb-1">Total Participants</span>
                                        <h3>24,194</h3>
                                    </div>
                                </div>
                                <p className="roboto mb-7 mb-md-10">
                                    Immerse in the ultimate digital battleground - welcome to Cyber
                                    Arena.
                                </p>
                                <div className="completed_project__ccard p-2 p-sm-3 p-lg-4 rounded-2 bg1-color br2">
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Raised</span>
                                        <span className="roboto">$53,800/$100,000</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Issued</span>
                                        <span className="roboto">72,463,769 CAT</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between flex-wrap mb-3">
                                        <span className="roboto">Completed At</span>
                                        <span className="roboto">2024-10-06 10:00:00 UTC</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="completed_project__item br2 rounded-3 bg1-color px-5 px-md-6 py-3 py-sm-6 py-lg-10 wow fadeInUp">
                                <div className="completed_project__itemhead d-flex gap-3 mb-7 mb-md-10">
                                    <div className="completed_project__itemhead-icon">
                                        <img src="assets/images/icon/virtual-version.png" alt="Icon" />
                                    </div>
                                    <div className="completed_project__itemhead-content">
                                        <a href="javascript:void(0)">
                                            <h3 className="mb-1">Virtual Versions</h3>
                                        </a>
                                        <span className="fs-five">VV</span>
                                    </div>
                                </div>
                                <div className="completed_project__roitotal d-flex align-items-center justify-content-between gap-2 mb-5 mb-md-6">
                                    <div className="text-start">
                                        <span className="fs-five mb-1">Peak ROI</span>
                                        <h3 className="p2-color">716.8%</h3>
                                    </div>
                                    <div className="text-end">
                                        <span className="fs-five mb-1">Total Participants</span>
                                        <h3>24,194</h3>
                                    </div>
                                </div>
                                <p className="roboto mb-7 mb-md-10">
                                    Digital incarnations offering versatile and immersive experiences
                                    for users.
                                </p>
                                <div className="completed_project__ccard p-2 p-sm-3 p-lg-4 rounded-2 bg1-color br2">
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Raised</span>
                                        <span className="roboto">$92,800/$100,000</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Issued</span>
                                        <span className="roboto">17,142,845 VV</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between flex-wrap mb-3">
                                        <span className="roboto">Completed At</span>
                                        <span className="roboto">2024-08-23 12:00:00 UTC</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="completed_project__item br2 rounded-3 bg1-color px-5 px-md-6 py-3 py-sm-6 py-lg-10 wow fadeInUp">
                                <div className="completed_project__itemhead d-flex gap-3 mb-7 mb-md-10">
                                    <div className="completed_project__itemhead-icon">
                                        <img src="assets/images/icon/berby-stars.png" alt="Icon" />
                                    </div>
                                    <div className="completed_project__itemhead-content">
                                        <a href="javascript:void(0)">
                                            <h3 className="mb-1">Derby Stars</h3>
                                        </a>
                                        <span className="fs-five">DSRUN</span>
                                    </div>
                                </div>
                                <div className="completed_project__roitotal d-flex align-items-center justify-content-between gap-2 mb-5 mb-md-6">
                                    <div className="text-start">
                                        <span className="fs-five mb-1">Peak ROI</span>
                                        <h3 className="p2-color">399.99%</h3>
                                    </div>
                                    <div className="text-end">
                                        <span className="fs-five mb-1">Total Participants</span>
                                        <h3>2,865</h3>
                                    </div>
                                </div>
                                <p className="roboto mb-7 mb-md-10">
                                    Experience the thrill of Derby Stars' competitive gaming
                                    excitement and wins.
                                </p>
                                <div className="completed_project__ccard p-2 p-sm-3 p-lg-4 rounded-2 bg1-color br2">
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Raised</span>
                                        <span className="roboto">$28,500/$30,000</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Issued</span>
                                        <span className="roboto">3,000,000 DSRUN</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between flex-wrap mb-3">
                                        <span className="roboto">Completed At</span>
                                        <span className="roboto">2024-08-03 08:00:00 UTC</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="completed_project__item br2 rounded-3 bg1-color px-5 px-md-6 py-3 py-sm-6 py-lg-10 wow fadeInUp">
                                <div className="completed_project__itemhead d-flex gap-3 mb-7 mb-md-10">
                                    <div className="completed_project__itemhead-icon">
                                        <img src="assets/images/icon/cyber-arena.png" alt="Icon" />
                                    </div>
                                    <div className="completed_project__itemhead-content">
                                        <a href="javascript:void(0)">
                                            <h3 className="mb-1">GameSwift</h3>
                                        </a>
                                        <span className="fs-five">GSWIFT</span>
                                    </div>
                                </div>
                                <div className="completed_project__roitotal d-flex align-items-center justify-content-between gap-2 mb-5 mb-md-6">
                                    <div className="text-start">
                                        <span className="fs-five mb-1">Peak ROI</span>
                                        <h3 className="p2-color">490.13%</h3>
                                    </div>
                                    <div className="text-end">
                                        <span className="fs-five mb-1">Total Participants</span>
                                        <h3>21,194</h3>
                                    </div>
                                </div>
                                <p className="roboto mb-7 mb-md-10">
                                    Enhance gaming performance effortlessly with the powerful
                                    optimization tool, GameSwift.
                                </p>
                                <div className="completed_project__ccard p-2 p-sm-3 p-lg-4 rounded-2 bg1-color br2">
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Raised</span>
                                        <span className="roboto">$47,600/$50,000</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Issued</span>
                                        <span className="roboto">3,472,222 GSWIFT</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between flex-wrap mb-3">
                                        <span className="roboto">Completed At</span>
                                        <span className="roboto">2024-10-06 10:00:00 UTC</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-4">
                            <div className="completed_project__item br2 rounded-3 bg1-color px-5 px-md-6 py-3 py-sm-6 py-lg-10 wow fadeInUp">
                                <div className="completed_project__itemhead d-flex gap-3 mb-7 mb-md-10">
                                    <div className="completed_project__itemhead-icon">
                                        <img src="assets/images/icon/eldarune.png" alt="Icon" />
                                    </div>
                                    <div className="completed_project__itemhead-content">
                                        <a href="javascript:void(0)">
                                            <h3 className="mb-1">Eldarune</h3>
                                        </a>
                                        <span className="fs-five">ELDA</span>
                                    </div>
                                </div>
                                <div className="completed_project__roitotal d-flex align-items-center justify-content-between gap-2 mb-5 mb-md-6">
                                    <div className="text-start">
                                        <span className="fs-five mb-1">Peak ROI</span>
                                        <h3 className="p2-color">533.3%</h3>
                                    </div>
                                    <div className="text-end">
                                        <span className="fs-five mb-1">Total Participants</span>
                                        <h3>24,194</h3>
                                    </div>
                                </div>
                                <p className="roboto mb-7 mb-md-10">
                                    Digital incarnations offering versatile and immersive experiences
                                    for users.
                                </p>
                                <div className="completed_project__ccard p-2 p-sm-3 p-lg-4 rounded-2 bg1-color br2">
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Raised</span>
                                        <span className="roboto">$47,300/$50,000</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between mb-3">
                                        <span className="roboto">Total Issued</span>
                                        <span className="roboto">ELDA</span>
                                    </div>
                                    <div className="completed_project__ccard-citem d-flex align-items-center justify-content-between flex-wrap mb-3">
                                        <span className="roboto">Completed At</span>
                                        <span className="roboto">2024-05-29 10:00:00 UTC</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="completed_project__btn text-center mt-3 mt-md-4">
                                <a
                                    href="javascript:void(0)"
                                    className="cmn-btn py-2 py-md-3 px-3 px-md-4 rounded-2"
                                >
                                    View More
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Completed Projects Ends */}
            {/* How to Join Starts */}
            <section className="how_join bg9-color pt-120 pb-120">
                <div className="container">
                    <div className="row gy-5 gy-md-6">
                        <div className="how_join__title d-flex align-items-center justify-content-between gap-4 flex-wrap mb-8 mb-md-12">
                            <h2 className="wow fadeInUp">How to Join</h2>
                            <a
                                href="javascript:void(0)"
                                className="cmn-btn py-3 px-5 px-md-6 d-center roboto wow fadeInUp"
                            >
                                VIEW MORE <i className="ti ti-chevron-right fs-four" />
                            </a>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xxl-3">
                            <div className="how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br1 position-relative text-center wow fadeInUp">
                                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                                    <img src="assets/images/icon/subscription.png" alt="Icons" />
                                </div>
                                <h4 className="mb-4 mb-md-5 text-center">Subscription</h4>
                                <p className="text-center">
                                    Please ensure your wallet balance meets the requirements for
                                    participation.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xxl-3">
                            <div className="how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br1 position-relative text-center wow fadeInUp">
                                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                                    <img src="assets/images/icon/snapshot.png" alt="Icons" />
                                </div>
                                <h4 className="mb-4 mb-md-5 text-center">Snapshot</h4>
                                <p className="text-center">
                                    Commit the required tokens for a chance to win the project's token
                                    allocation.
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xxl-3">
                            <div className="how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br1 position-relative text-center wow fadeInUp">
                                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                                    <img src="assets/images/icon/lottery.png" alt="Icons" />
                                </div>
                                <h4 className="mb-4 mb-md-5 text-center">Lottery</h4>
                                <p className="text-center">
                                    Participate in our exciting lottery games for a chance to win big
                                    prizes!
                                </p>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xxl-3">
                            <div className="how_join__item py-7 py-md-10 px-6 px-md-8 rounded-3 br1 position-relative text-center wow fadeInUp">
                                <div className="how_join__item-thumb mb-4 mb-md-5 text-center p-6 bg1-color rounded-item d-inline-block">
                                    <img src="assets/images/icon/redemption.png" alt="Icons" />
                                </div>
                                <h4 className="mb-4 mb-md-5 text-center">Redemption</h4>
                                <p className="text-center">
                                    Complete the process for redemption to access exclusive rewards
                                    effortlessly.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* How to Join Ends */}
            {/* Footer Section Starts */}
            <footer className="footer pt-120 bg5-color">
                <div className="container">
                    <div className="row gy-8 pb-120 justify-content-between">
                        <div className="col-md-12 col-lg-6 col-xxl-5">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <h2>Coinx Wallet</h2>
                                </a>
                                <p className="fs-ten mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp">
                                    Welcome to Coinx, your gateway to the world of Web3 trading! Our
                                    user-friendly platform empowers you to explore a wide range of
                                    popular cryptocurrencies
                                </p>
                                <div className="footer__decs-subscribe mb-9 mb-md-12 wow fadeInUp">
                                    <form className="d-flex align-items-center rounded-1 py-2 pe-2">
                                        <input type="email" placeholder="Enter your email" />
                                        <button className="cmn-btn py-3  px-8 rounded-1 d-center gap-2">
                                            Subscribe
                                            <i className="ti ti-send fs-five d-none d-md-block" />
                                        </button>
                                    </form>
                                </div>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-facebook-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-linkedin p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-instagram p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2 ms-xxl-20">
                            <div className="footer__discover">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Company</h4>
                                <div className="footer__discover-nav">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                About Coinx Wallet
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Join Us
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Blog
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Academy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Security</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Contact Validator
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Protection fund
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Security
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Assets</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                BNB Chain
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Ethereum
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                ERC20
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-md-start">
                                        Copyright 2024 -
                                        <a href="/" className="p4-color">
                                            ConiX
                                        </a>
                                        All Rights Reserved{" "}
                                        <span className="p4-color fs-five mx-2">|</span> Design By
                                        <a
                                            href="https://themeforest.net/user/uiaxis"
                                            className="p4-color roboto"
                                        >
                                            {" "}
                                            UIAXIS
                                        </a>
                                    </span>
                                </div>
                                <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="p4-color roboto ps-4 ps-sm-6"
                                    >
                                        Privacy Policy
                                    </a>
                                    <span className="p4-color fs-five">|</span>
                                    <a href="javascript:void(0)" className="p4-color roboto">
                                        Cookie Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section Ends */}
        </>

    )
}

export default Ido