import React from 'react'

function Swap() {
    return (
        <>
            {/* Scroll To Top Start*/}
            <button
                className="scrollToTop d-none d-md-flex d-center"
                aria-label="scroll Bar Button"
            >
                <i className="ti ti-chevron-up fs-four p6-color" />
            </button>
            {/* Scroll To Top End */}
            {/* header-section start */}
            <div className="navbar_top bg2-color py-4 d-none d-lg-block">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 col-xxl-5">
                            <div className="navbar_top__left d-flex align-items-center gap-2 gap-xl-6">
                                <div className="navbar_top__location d-flex align-items-center gap-1 gap-xl-3">
                                    <i className="ti ti-map-pin-filled fs-four p7-color" />
                                    <span className="roboto p7-color">
                                        102/B New Market, Sandigo-USA
                                    </span>
                                </div>
                                <span className="v-line mb-9" />
                                <a
                                    href="https://softivus.com/cdn-cgi/l/email-protection#3f5a475e524f535a7f58525e5653115c5052"
                                    className="navbar_top__email roboto p7-color d-flex align-items-center gap-3"
                                >
                                    <i className="ti ti-mail-opened-filled fs-four" />
                                    <span
                                        className="__cf_email__"
                                        data-cfemail="06637e676b766a6346616b676f6a2865696b"
                                    >
                                        [email&nbsp;protected]
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xxl-6">
                            <div className="navbar_top__right d-flex align-items-center justify-content-end gap-2 gap-xl-6">
                                <div className="navbar_top__call d-flex align-items-center gap-3">
                                    <span className="bg6-color py-2 px-3 rounded-item">
                                        <i className="ti ti-phone-call fs-four p7-color " />
                                    </span>
                                    <div>
                                        <span className="p7-color fw-bolder d-block">Contact Us:</span>
                                        <a href="tel:+9802344567" className="d-block p7-color">
                                            +980 234 4567
                                        </a>
                                    </div>
                                </div>
                                <span className="v-line mb-9" />
                                <div className="navbar_top__social d-flex align-items-center gap-2 gap-xl-3">
                                    <span className="p7-color fw-bolder">Follow Us:</span>
                                    <div className="navbar_top__social-icon d-flex align-items-center  gap-1 gap-xl-2">
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-facebook fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-instagram fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-twitter fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-linkedin fs-four p7-color " />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
                <div className="navbar_mainhead header-fixed w-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 workready">
                                    <a
                                        href="/"
                                        className="navbar-brand d-flex align-items-center gap-2"
                                    >
                                        <img src="assets/images/logo.png" className="logo" alt="logo" />
                                    </a>
                                    <div
                                        className="collapse navbar-collapse justify-content-between"
                                        id="navbar-content"
                                    >
                                        <ul className="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                                            <li className="dropdown">
                                                <a href="/" className="fs-ten">
                                                    Home
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Staking
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/staking">
                                                            Staking Pool
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/staking_details"
                                                        >
                                                            Pool details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    IDO <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/ido">
                                                            IDO
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/idodetails"
                                                        >
                                                            IDO Details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Swap
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/swap">
                                                            Swap
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/swap_checkout"
                                                        >
                                                            Swap to share
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/apes" className="fs-ten">
                                                    ApeX
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Buy Crypto
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/buycrypto"
                                                        >
                                                            Buy Crypto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/pricing_plan"
                                                        >
                                                            Pricing plan
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Pages
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/rewards">
                                                            Rewards
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogs"
                                                        >
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogdetails"
                                                        >
                                                            Blog details
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/contact_us"
                                                        >
                                                            Contact us
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/terms_conditions"
                                                        >
                                                            Terms &amp; condition
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/error">
                                                            Error
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                                        <div className="single-item cart-area search-area">
                                            <div className="cmn-head">
                                                <button
                                                    type="button"
                                                    aria-label="Shopping Button"
                                                    className="common_toggles2 icon-area p-0 me-3 me-lg-0 box-second d-center position-relative"
                                                >
                                                    <i className="ti ti-search slide-toggle2 fs-four p6-color" />
                                                </button>
                                                <div className="msg_area common_area2 p2-bg p-5 rounded-2">
                                                    <form className="d-flex align-items-center ">
                                                        <input type="text" />
                                                        <button type="submit" className="p-2">
                                                            <i className="ti ti-search slide-toggle2 fs-four p2-color" />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-section__modalstyle">
                                            {/* Button trigger modal */}
                                            <button
                                                type="button"
                                                className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <span className="p7-color fw-semibold d-none d-sm-block">
                                                    Connect
                                                </span>
                                                Wallet
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        aria-label="Navbar Toggler"
                                        data-bs-target="#navbar-content"
                                        aria-expanded="true"
                                        id="nav-icon3"
                                    >
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="modal_areastyle">
                {/* Modal */}
                <div
                    className="modal"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content px-2 px-md-6 pt-md-3 pb-2 pb-md-6">
                            <div className="modal-header border-0">
                                <h3 className="modal-title p1-color" id="exampleModalLabel">
                                    Connect Your Wallet
                                </h3>
                                <button
                                    type="button"
                                    className="btn-close p7-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="ti ti-xbox-x p6-color fs-three fw-normal" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between mb-5 mb-md-6"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/dog.png" alt="Icon" />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/wallet-connect.png" alt="Icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* header-section end */}
            {/*Common hero Section Starts */}
            <section className="cmn_heros pb-120 pt-120">
                <div className="container">
                    <div className="row justify-content-center mt-5 mt-md-8 mt-lg-0">
                        <div className="col-xxl-6">
                            <div className="cmn_heros__title text-center pt-15 pt-lg-6">
                                <h1 className="display-three mb-5 mb-md-7 wow fadeInUp">Swap</h1>
                                <p className="roboto wow fadeInUp">
                                    Earn passive income easily with just one click!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Common hero Section Ends */}
            {/* Count Down Starts*/}
            <section className="countdwon_event pt-120 pb-120">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xxl-5">
                            <div className="countdwon_event__title">
                                <h3 className="p4-color mb-5 mb-md-6 wow fadeInUp">
                                    Coinx Web3 USDC Odyssey - Swap &amp; Hold
                                </h3>
                                <a
                                    className="cmn-btn fifth-alt fw-bold py-3 px-5 px-md-6 d-inline-flex align-items-center gap-2 wow fadeInUp"
                                    href="javascript:void(0)"
                                >
                                    Check Out <i className="ti ti-arrow-right fs-five" />
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5">
                            <div className="countdwon_event__part">
                                <span className="fs-ten p4-color text-end d-block mb-5 mb-mb-6">
                                    The event will end in
                                </span>
                                <div className="coundown-container d-flex align-items-center gap-4">
                                    <div className="countdown-el days-container d-flex align-items-center gap-4">
                                        <h3
                                            className="big-text p4-color py-3 px-5 px-md-6 bg6-color rounded-2"
                                            id="days"
                                        >
                                            0
                                        </h3>
                                        <span className="p4-color fs-five">d</span>
                                    </div>
                                    <div className="countdown-el hours-container d-flex align-items-center gap-4">
                                        <h3
                                            className="big-text p4-color py-3 px-5 px-md-6 bg6-color rounded-2"
                                            id="hours"
                                        >
                                            0
                                        </h3>
                                        <span className="p4-color fs-five">h</span>
                                    </div>
                                    <div className="countdown-el minutes-container d-flex align-items-center gap-4">
                                        <h3
                                            className="big-text p4-color p4-color py-3 px-5 px-md-6 bg6-color rounded-2"
                                            id="minutes"
                                        >
                                            0
                                        </h3>
                                        <span className="p4-color fs-five">m</span>
                                    </div>
                                    <div className="countdown-el seconds-container d-flex align-items-center gap-4">
                                        <h3
                                            className="big-text p4-color p4-color py-3 px-5 px-md-6 bg6-color rounded-2"
                                            id="seconds"
                                        >
                                            0
                                        </h3>
                                        <span className="p4-color fs-five">s</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Count Down Ends*/}
            {/* Markets Section Starts */}
            <section className="markets_section pt-120 pb-120 bg5-color">
                <div className="container">
                    <div className="row gy-6">
                        <div className="markets_section__title mb-6 mb-md-9">
                            <h2 className="wow fadeInUp">Markets</h2>
                        </div>
                        <div className="col-xxl-8">
                            <div className="row gy-6">
                                <div className="col-md-6 col-xl-4 col-xxl-6">
                                    <div className="markets_section__card bg1-color rounded-20 br2 p-5 p-md-6 wow fadeInUp">
                                        <div className="markets_section__card-head d-flex align-items-center gap-2 mb-4 mb-md-5">
                                            <img src="assets/images/icon/bticoin505.png" alt="icon" />
                                            <h4>Bitcoin</h4>
                                            <span className="fs-ten">BTC/USD</span>
                                        </div>
                                        <div className="markets_section__card-chart mb-4 mb-md-5">
                                            <div id="crm-total-customers2"></div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center gap-2">
                                                <span className="fs-four">USD</span>
                                                <span className="fs-four p1-color">19251.11</span>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <span className="p1-color">0.35%</span>
                                                <i className="ti ti-caret-up-filled p1-color fs-four" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 col-xxl-6">
                                    <div className="markets_section__card bg1-color rounded-20 br2 p-5 p-md-6 wow fadeInUp">
                                        <div className="markets_section__card-head d-flex align-items-center gap-2 mb-4 mb-md-5">
                                            <img src="assets/images/icon/cryptocurrency.png" alt="icon" />
                                            <h4>ETH</h4>
                                            <span className="fs-ten">ETH/USD</span>
                                        </div>
                                        <div className="markets_section__card-chart mb-4 mb-md-5">
                                            <div id="crm-total-customers"></div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center gap-2">
                                                <span className="fs-four">USD</span>
                                                <span className="fs-four p1-color">5600.11</span>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <span className="p1-color">0.40%</span>
                                                <i className="ti ti-caret-up-filled p1-color fs-four" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 col-xxl-6">
                                    <div className="markets_section__card bg1-color rounded-20 br2 p-5 p-md-6 wow fadeInUp">
                                        <div className="markets_section__card-head d-flex align-items-center gap-2 mb-4 mb-md-5">
                                            <img src="assets/images/icon/litecoin_ltc.png" alt="icon" />
                                            <h4>LFC</h4>
                                            <span className="fs-ten">LFC/USD</span>
                                        </div>
                                        <div className="markets_section__card-chart mb-4 mb-md-5">
                                            <div id="crm-total-customers3"></div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center gap-2">
                                                <span className="fs-four">USD</span>
                                                <span className="fs-four p1-color">4922.11</span>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <span className="p1-color">0.31%</span>
                                                <i className="ti ti-caret-up-filled p1-color fs-four" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-6 col-xl-4 col-xxl-6">
                                    <div className="markets_section__card bg1-color rounded-20 br2 p-5 p-md-6 wow fadeInUp">
                                        <div className="markets_section__card-head d-flex align-items-center gap-2 mb-4 mb-md-5">
                                            <img src="assets/images/icon/tether.png" alt="icon" />
                                            <h4>TLP</h4>
                                            <span className="fs-ten">TLP/USD</span>
                                        </div>
                                        <div className="markets_section__card-chart mb-4 mb-md-5">
                                            <div id="crm-total-customers4"></div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between">
                                            <div className="d-flex align-items-center gap-2">
                                                <span className="fs-four">USD</span>
                                                <span className="fs-four p1-color">19251.11</span>
                                            </div>
                                            <div className="d-flex align-items-center gap-2">
                                                <span className="p1-color">0.29%</span>
                                                <i className="ti ti-caret-up-filled p1-color fs-four" />
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="markets_section__tetherusd mt-6 mt-md-9 p-5 p-md-6 br2 rounded-20 bg1-color wow fadeInUp">
                                        <div className="markets_section__tetherusd-head d-flex align-items-center justify-content-between gap-4 flex-wrap mb-6 mb-md-8">
                                            <div className="d-flex align-items-center gap-4 gap-sm-5 gap-md-6 flex-wrap ">
                                                <img src="assets/images/icon/usd-coin.png" alt="Icon" />
                                                <div className="d-flex align-items-center gap-1">
                                                    <h2>Tether USD</h2>
                                                    <span className="p1-color py-1 px-2 bg1-color rounded-1 fs-seven">
                                                        USDT
                                                    </span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-end gap-1">
                                                <span className="fs-four">$1.0010</span>
                                                <span className="p1-color">0.01%</span>
                                            </div>
                                        </div>
                                        <div className="markets_section__tetherusd-chartarea">
                                            <div id="chart-timeline" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-4">
                            <div className="row gy-5 gy-md-6 justify-content-center">
                                <div className="col-lg-6 col-xxl-12">
                                    <div className="markets_section__rcard px-3 px-sm-5 px-md-6 py-6 py-sm-7 py-md-8 br2 bg7-color rounded-20 wow fadeInUp">
                                        <div className="markets_section__rcard-head d-flex align-items-center justify-content-between mb-5 mb-md-6">
                                            <h4 className="d-block ">Tether USD</h4>
                                            <div className="apex_section__slider-selector markets_section__rcard-selector">
                                                <div className="f-group">
                                                    <select id="select2" className="f-control f-dropdown">
                                                        <option
                                                            value={1}
                                                            selected=""
                                                            data-image="assets/images/icon/cryptocurrency56304.png"
                                                        >
                                                            Ethereum
                                                        </option>
                                                        <option
                                                            value={2}
                                                            data-image="assets/images/icon/currencyCategory5333a.png"
                                                        >
                                                            ETH
                                                        </option>
                                                        <option
                                                            value={3}
                                                            data-image="assets/images/icon/currencyCategory6bd4a.png"
                                                        >
                                                            LFC
                                                        </option>
                                                        <option
                                                            value={4}
                                                            data-image="assets/images/icon/currencyCategory71949.png"
                                                        >
                                                            TLP
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="markets_section__rcard-card p-4 p-sm-5 p-md-6 bg1-color rounded-20">
                                            <div className="d-flex align-items-center gap-3 justify-content-between mb-3">
                                                <span className="roboto">Pay</span>
                                                <div className="d-flex align-items-center gap-2">
                                                    <i className="ti ti-wallet fs-five" />
                                                    <span className="opacity-50">---</span>
                                                    <span>USDT</span>
                                                    <span className="p1-color">Max</span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between gap-3">
                                                <div className="text-end">
                                                    <div className="apex_section__slider-selector markets_section__rcard-selector">
                                                        <div className="f-group">
                                                            <select id="select3" className="f-control f-dropdown">
                                                                <option
                                                                    value={1}
                                                                    selected=""
                                                                    data-image="assets/images/icon/cryptocurrency56304.png"
                                                                >
                                                                    USDT
                                                                </option>
                                                                <option
                                                                    value={2}
                                                                    data-image="assets/images/icon/currencyCategory5333a.png"
                                                                >
                                                                    ETH
                                                                </option>
                                                                <option
                                                                    value={3}
                                                                    data-image="assets/images/icon/currencyCategory6bd4a.png"
                                                                >
                                                                    LFC
                                                                </option>
                                                                <option
                                                                    value={4}
                                                                    data-image="assets/images/icon/currencyCategory71949.png"
                                                                >
                                                                    TLP
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <span className="me-2">Ethereum</span>
                                                </div>
                                                <span className="fs-four opacity-50">0.00</span>
                                            </div>
                                        </div>
                                        <div className="markets_section__middleicon d-center">
                                            <div className="markets_section__middleicon-box px-2 py-3 rounded-5 bg7-color cpoint">
                                                <i className="ti ti-exchange fs-five p-2 rounded-5 bg2-color p4-color" />
                                            </div>
                                        </div>
                                        <div className="markets_section__rcard-card p-4 p-sm-5 p-md-6 bg1-color rounded-20 mb-5 mb-md-6">
                                            <div className="d-flex align-items-center gap-3 justify-content-between mb-3">
                                                <span className="roboto">Receive</span>
                                                <div className="d-flex align-items-center gap-2">
                                                    <i className="ti ti-wallet fs-five" />
                                                    <span className="opacity-50">---</span>
                                                    <span>USDT</span>
                                                    <span className="p1-color">Max</span>
                                                </div>
                                            </div>
                                            <div className="d-flex align-items-center justify-content-between gap-3">
                                                <div className="text-end">
                                                    <div className="apex_section__slider-selector markets_section__rcard-selector">
                                                        <div className="f-group">
                                                            <select id="select4" className="f-control f-dropdown">
                                                                <option
                                                                    value={1}
                                                                    selected=""
                                                                    data-image="assets/images/icon/cryptocurrency56304.png"
                                                                >
                                                                    USDT
                                                                </option>
                                                                <option
                                                                    value={2}
                                                                    data-image="assets/images/icon/currencyCategory5333a.png"
                                                                >
                                                                    ETH
                                                                </option>
                                                                <option
                                                                    value={3}
                                                                    data-image="assets/images/icon/currencyCategory6bd4a.png"
                                                                >
                                                                    LFC
                                                                </option>
                                                                <option
                                                                    value={4}
                                                                    data-image="assets/images/icon/currencyCategory71949.png"
                                                                >
                                                                    TLP
                                                                </option>
                                                            </select>
                                                        </div>
                                                    </div>
                                                    <span className="me-2">Ethereum</span>
                                                </div>
                                                <span className="fs-four opacity-50">--</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-3 mb-md-4">
                                            <span>Swap Fee</span>
                                            <span>---</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-3 mb-md-4">
                                            <span>Gas Fees</span>
                                            <span>---</span>
                                        </div>
                                        <div className="d-flex align-items-center justify-content-between mb-5 mb-md-6">
                                            <span>Min. Amount Received</span>
                                            <span>---</span>
                                        </div>
                                        <a
                                            className="cmn-btn py-3 px-6 w-100"
                                            data-bs-toggle="modal"
                                            href="#exampleModalToggle"
                                            role="button"
                                        >
                                            Log In
                                        </a>
                                        <div
                                            className="modal fade"
                                            id="exampleModalToggle"
                                            aria-hidden="true"
                                            aria-labelledby="exampleModalToggleLabel"
                                            tabIndex={-1}
                                        >
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content bg7-color rounded-20">
                                                    <div className="modal-header">
                                                        <h5
                                                            className="modal-title fs-5"
                                                            id="exampleModalToggleLabel"
                                                        >
                                                            Welcome to CoinX
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="p6-color"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            <i className="ti ti-x fs-four" />
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="apex_section__aside-logintop d-flex align-items-center gap-3">
                                                            <span>New to Bybit?</span>{" "}
                                                            <i className="ti ti-arrows-exchange-2 fs-four p1-color" />
                                                            <button
                                                                className="d-flex align-items-center gap-3 p1-color"
                                                                data-bs-target="#exampleModalToggle2"
                                                                data-bs-toggle="modal"
                                                            >
                                                                Sign up
                                                            </button>
                                                        </div>
                                                        <div className="apex_section__aside-tabs mt-4">
                                                            <div className="singletab mb-5 mb-md-6">
                                                                <ul className="tablinks d-flex align-items-center gap-5 gap-md-6 mb-5 mb-md-6">
                                                                    <li className="nav-links position-relative">
                                                                        <button className="tablink fw-bold">
                                                                            Email
                                                                        </button>
                                                                    </li>
                                                                    <li className="nav-links position-relative">
                                                                        <button className="tablink fw-bold">
                                                                            Mobile
                                                                        </button>
                                                                    </li>
                                                                    <li className="nav-links position-relative">
                                                                        <button className="tablink fw-bold">
                                                                            QR Code
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                                <div className="tabcontents">
                                                                    <div className="tabitem active">
                                                                        <div className="apex_section__forms">
                                                                            <form>
                                                                                <input
                                                                                    className="br2 mb-3 mb-md-4"
                                                                                    type="email"
                                                                                    placeholder="Email"
                                                                                />
                                                                                <input
                                                                                    className="br2 mb-3 mb-md-4"
                                                                                    type="password"
                                                                                    placeholder="Swap Fee"
                                                                                />
                                                                                <a
                                                                                    className="d-block text-end p1-color"
                                                                                    href="javascript:void(0)"
                                                                                >
                                                                                    Forgot password
                                                                                </a>
                                                                                <button className="cmn-btn px-6 py-3 w-100 mt-5 mt-md-6">
                                                                                    Login
                                                                                </button>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tabitem">
                                                                        <div className="apex_section__forms">
                                                                            <form>
                                                                                <input
                                                                                    className="br2 mb-3 mb-md-4"
                                                                                    type="tel"
                                                                                    placeholder="Phone"
                                                                                />
                                                                                <input
                                                                                    className="br2 mb-3 mb-md-4"
                                                                                    type="password"
                                                                                    placeholder="Swap Fee"
                                                                                />
                                                                                <a
                                                                                    className="d-block "
                                                                                    href="javascript:void(0)"
                                                                                >
                                                                                    Forgot password
                                                                                </a>
                                                                                <button className="cmn-btn px-6 py-3 w-100 mt-5 mt-md-6">
                                                                                    Login
                                                                                </button>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tabitem">
                                                                        <div className="apex_section__qrcode">
                                                                            <img
                                                                                src="assets/images/qrcode.png"
                                                                                alt="rqcode"
                                                                            />
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-3 mb-md-4">
                                                                <hr className="w-100 br2" />
                                                                <span className="w-100">or log in with</span>
                                                                <hr className="w-100 br2" />
                                                            </div>
                                                            <div className="d-flex align-items-center gap-5 gap-md-6 mb-3">
                                                                <a
                                                                    className="cmn-btn w-100 py-3 px-5 px-md-6 d-center gap-2 fourth-alt"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    <img
                                                                        src="assets/images/icon/google-icon.png"
                                                                        alt="icons"
                                                                    />
                                                                    Google
                                                                </a>
                                                                <a
                                                                    className="cmn-btn w-100 py-3 px-5 px-md-6 d-center gap-2 fourth-alt"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    <img
                                                                        src="assets/images/icon/apple-icon.png"
                                                                        alt="icons"
                                                                    />
                                                                    Google
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="modal-footer d-center">
                                                        <a
                                                            href="javascript:void(0)"
                                                            className="d-flex align-items-center gap-3 p1-color"
                                                        >
                                                            Log in with Subaccount
                                                            <i className="ti ti-arrow-right fs-four p1-color" />
                                                        </a>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div
                                            className="modal fade"
                                            id="exampleModalToggle2"
                                            aria-hidden="true"
                                            aria-labelledby="exampleModalToggleLabel3"
                                            tabIndex={-1}
                                        >
                                            <div className="modal-dialog modal-dialog-centered">
                                                <div className="modal-content bg7-color rounded-20">
                                                    <div className="modal-header">
                                                        <h5
                                                            className="modal-title fs-5"
                                                            id="exampleModalToggleLabel3"
                                                        >
                                                            Create Account
                                                        </h5>
                                                        <button
                                                            type="button"
                                                            className="p6-color"
                                                            data-bs-dismiss="modal"
                                                            aria-label="Close"
                                                        >
                                                            <i className="ti ti-x fs-four" />
                                                        </button>
                                                    </div>
                                                    <div className="modal-body">
                                                        <div className="apex_section__aside-logintop d-flex align-items-center gap-3 flex-wrap">
                                                            <span>Already have an account ?</span>{" "}
                                                            <i className="ti ti-arrows-exchange-2 fs-four p1-color" />
                                                            <button
                                                                className="d-flex align-items-center gap-3 p1-color"
                                                                data-bs-target="#exampleModalToggle2"
                                                                data-bs-toggle="modal"
                                                            >
                                                                Log In
                                                            </button>
                                                        </div>
                                                        <div className="apex_section__aside-tabs mt-4">
                                                            <div className="singletab mb-5 mb-md-6">
                                                                <ul className="tablinks d-flex align-items-center gap-5 gap-md-6 mb-5 mb-md-6">
                                                                    <li className="nav-links position-relative">
                                                                        <button className="tablink fw-bold">
                                                                            Email
                                                                        </button>
                                                                    </li>
                                                                    <li className="nav-links position-relative">
                                                                        <button className="tablink fw-bold">
                                                                            Mobile
                                                                        </button>
                                                                    </li>
                                                                </ul>
                                                                <div className="tabcontents">
                                                                    <div className="tabitem active">
                                                                        <div className="apex_section__forms">
                                                                            <form className="d-flex flex-column ">
                                                                                <input
                                                                                    className="br2 mb-3 mb-md-4"
                                                                                    type="email"
                                                                                    placeholder="Email"
                                                                                />
                                                                                <input
                                                                                    className="br2 mb-3 mb-md-4"
                                                                                    type="password"
                                                                                    placeholder="Swap Fee"
                                                                                />
                                                                                <span className="mb-3 mb-md-4 text-start">
                                                                                    Referral Code (Optional)
                                                                                </span>
                                                                                <input
                                                                                    className="br2 mb-3 mb-md-4"
                                                                                    type="text"
                                                                                />
                                                                                <div className="d-flex align-items-center gap-3">
                                                                                    <input
                                                                                        type="checkbox"
                                                                                        id="rcode"
                                                                                        name="vehicle1"
                                                                                        defaultValue="rcode"
                                                                                    />
                                                                                    <label
                                                                                        className="i-aggres text-start"
                                                                                        htmlFor="rcode"
                                                                                    >
                                                                                        Agree to Terms of Service and Privacy
                                                                                        Policy
                                                                                    </label>
                                                                                </div>
                                                                                <button className="cmn-btn px-6 py-3 w-100 mt-5 mt-md-6">
                                                                                    Create Account
                                                                                </button>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tabitem">
                                                                        <div className="apex_section__forms">
                                                                            <form>
                                                                                <input
                                                                                    className="br2 mb-3 mb-md-4"
                                                                                    type="tel"
                                                                                    placeholder="Phone"
                                                                                />
                                                                                <input
                                                                                    className="br2 mb-3 mb-md-4"
                                                                                    type="password"
                                                                                    placeholder="Swap Fee"
                                                                                />
                                                                                <a
                                                                                    className="d-block "
                                                                                    href="javascript:void(0)"
                                                                                >
                                                                                    Forgot password
                                                                                </a>
                                                                                <button className="cmn-btn px-6 py-3 w-100 mt-5 mt-md-6">
                                                                                    Login
                                                                                </button>
                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="d-flex align-items-center mb-3 mb-md-4">
                                                                <hr className="w-100 br2" />
                                                                <span className="w-100">or log in with</span>
                                                                <hr className="w-100 br2" />
                                                            </div>
                                                            <div className="d-flex align-items-center gap-5 gap-md-6 mb-3">
                                                                <a
                                                                    className="cmn-btn w-100 py-3 px-5 px-md-6 d-center gap-2 fourth-alt"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    <img
                                                                        src="assets/images/icon/google-icon.png"
                                                                        alt="icons"
                                                                    />
                                                                    Google
                                                                </a>
                                                                <a
                                                                    className="cmn-btn w-100 py-3 px-5 px-md-6 d-center gap-2 fourth-alt"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    <img
                                                                        src="assets/images/icon/apple-icon.png"
                                                                        alt="icons"
                                                                    />
                                                                    Google
                                                                </a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-6 col-xxl-12">
                                    <div className="markets_section__roundchart px-3 px-sm-5 px-md-6 py-3 py-sm-5 py-md-10 bg1-color br2 rounded-20 wow fadeInUp">
                                        <div id="roundedchart" className="d-center" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Markets Section Ends */}
            {/* Pools section starts */}
            <section className="pools_table pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="pools_table__title mb-5 mb-md-6">
                            <h2 className="mb-5 mb-md-6 wow fadeInUp">Pools</h2>
                            <p className="roboto wow fadeInUp">
                                Access DApps via the Bybit Wallet extension or the respective mobile
                                app
                            </p>
                        </div>
                        <div className="pools_table__part">
                            <div className="singletab">
                                <ul className="tablinks d-flex align-items-center gap-5 gap-sm-10 gap-md-15 gap-lg-19 mb-6 mb-md-8 wow fadeInUp">
                                    <li className="nav-links clickable-active active">
                                        <button className="tablink ">All</button>
                                    </li>
                                    <li className="nav-links clickable-active">
                                        <button className="tablink ">Events</button>
                                    </li>
                                    <li className="nav-links clickable-active">
                                        <button className="tablink ">Single Asset</button>
                                    </li>
                                </ul>
                                <div className="tabcontents">
                                    <div className="tabitem active wow fadeInUp">
                                        <div className="pools_table__totalitem overflow-auto">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Pool Name</th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>Current APY</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint pools_table__totalitem-ticonone" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint pools_table__totalitem-ticontwo" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>CNetwork</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint pools_table__totalitem-ticonone" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint pools_table__totalitem-ticontwo" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>TVL</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint pools_table__totalitem-ticonone" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint pools_table__totalitem-ticontwo" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon1.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">USDC</span>
                                                                    <span className="roboto">LDO</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">26.80%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>$181.48 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8 p1-color"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto">stETH-ng</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.15%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$ 96.88 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon10.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">cvxeth</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">8.72%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">ironback</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">10.09%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$6.85 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="pools_table__pagination mt-6 mt-md-8">
                                            <nav>
                                                <ul className="d-flex align-items-center gap-3 gap-md-5">
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-left fs-four p1-color" />
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">1</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">2</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">3</span>
                                                    </li>
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-right fs-four p1-color" />
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="tabitem wow fadeInUp">
                                        <div className="pools_table__totalitem overflow-auto">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Pool Name</th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>Current APY</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>CNetwork</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>TVL</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon1.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">USDC</span>
                                                                    <span className="roboto">LDO</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">26.80%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>$181.48 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8 p1-color"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto">stETH-ng</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.15%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$ 96.88 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon10.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">cvxeth</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">8.72%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">ironback</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">10.09%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$6.85 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="pools_table__pagination mt-6 mt-md-8">
                                            <nav>
                                                <ul className="d-flex align-items-center gap-3 gap-md-5">
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-left fs-four p1-color" />
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">1</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">2</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">3</span>
                                                    </li>
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-right fs-four p1-color" />
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="tabitem wow fadeInUp">
                                        <div className="pools_table__totalitem overflow-auto">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Pool Name</th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>Current APY</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>CNetwork</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>TVL</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon1.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">USDC</span>
                                                                    <span className="roboto">LDO</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">26.80%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>$181.48 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8 p1-color"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto">stETH-ng</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.15%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$ 96.88 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon10.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">cvxeth</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">8.72%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">ironback</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">10.09%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$6.85 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="pools_table__pagination mt-6 mt-md-8">
                                            <nav>
                                                <ul className="d-flex align-items-center gap-3 gap-md-5">
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-left fs-four p1-color" />
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">1</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">2</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">3</span>
                                                    </li>
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-right fs-four p1-color" />
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Pools section ends */}
            {/* Footer Section Starts */}
            <footer className="footer pt-120 bg5-color">
                <div className="container">
                    <div className="row gy-8 pb-120 justify-content-between">
                        <div className="col-md-12 col-lg-6 col-xxl-5">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <h2>Coinx Wallet</h2>
                                </a>
                                <p className="fs-ten mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp">
                                    Welcome to Coinx, your gateway to the world of Web3 trading! Our
                                    user-friendly platform empowers you to explore a wide range of
                                    popular cryptocurrencies
                                </p>
                                <div className="footer__decs-subscribe mb-9 mb-md-12 wow fadeInUp">
                                    <form className="d-flex align-items-center rounded-1 py-2 pe-2">
                                        <input type="email" placeholder="Enter your email" />
                                        <button className="cmn-btn py-3  px-8 rounded-1 d-center gap-2">
                                            Subscribe
                                            <i className="ti ti-send fs-five d-none d-md-block" />
                                        </button>
                                    </form>
                                </div>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-facebook-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-linkedin p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-instagram p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2 ms-xxl-20">
                            <div className="footer__discover">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Company</h4>
                                <div className="footer__discover-nav">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                About Coinx Wallet
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Join Us
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Blog
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Academy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Security</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Contact Validator
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Protection fund
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Security
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Assets</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                BNB Chain
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Ethereum
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                ERC20
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-md-start">
                                        Copyright 2024 -
                                        <a href="/" className="p4-color">
                                            ConiX
                                        </a>
                                        All Rights Reserved{" "}
                                        <span className="p4-color fs-five mx-2">|</span> Design By
                                        <a
                                            href="https://themeforest.net/user/uiaxis"
                                            className="p4-color roboto"
                                        >
                                            {" "}
                                            UIAXIS
                                        </a>
                                    </span>
                                </div>
                                <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="p4-color roboto ps-4 ps-sm-6"
                                    >
                                        Privacy Policy
                                    </a>
                                    <span className="p4-color fs-five">|</span>
                                    <a href="javascript:void(0)" className="p4-color roboto">
                                        Cookie Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section Ends */}
        </>

    )
}

export default Swap