import React from 'react'

function Idodetails() {
    return (
        <>
            {/* Scroll To Top Start*/}
            <button
                className="scrollToTop d-none d-md-flex d-center"
                aria-label="scroll Bar Button"
            >
                <i className="ti ti-chevron-up fs-four p6-color" />
            </button>
            {/* Scroll To Top End */}
            {/* header-section start */}
            <div className="navbar_top bg2-color py-4 d-none d-lg-block">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 col-xxl-5">
                            <div className="navbar_top__left d-flex align-items-center gap-2 gap-xl-6">
                                <div className="navbar_top__location d-flex align-items-center gap-1 gap-xl-3">
                                    <i className="ti ti-map-pin-filled fs-four p7-color" />
                                    <span className="roboto p7-color">
                                        102/B New Market, Sandigo-USA
                                    </span>
                                </div>
                                <span className="v-line mb-9" />
                                <a
                                    href="https://softivus.com/cdn-cgi/l/email-protection#1e7b667f736e727b5e79737f7772307d7173"
                                    className="navbar_top__email roboto p7-color d-flex align-items-center gap-3"
                                >
                                    <i className="ti ti-mail-opened-filled fs-four" />
                                    <span
                                        className="__cf_email__"
                                        data-cfemail="cca9b4ada1bca0a98caba1ada5a0e2afa3a1"
                                    >
                                        [email&nbsp;protected]
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xxl-6">
                            <div className="navbar_top__right d-flex align-items-center justify-content-end gap-2 gap-xl-6">
                                <div className="navbar_top__call d-flex align-items-center gap-3">
                                    <span className="bg6-color py-2 px-3 rounded-item">
                                        <i className="ti ti-phone-call fs-four p7-color " />
                                    </span>
                                    <div>
                                        <span className="p7-color fw-bolder d-block">Contact Us:</span>
                                        <a href="tel:+9802344567" className="d-block p7-color">
                                            +980 234 4567
                                        </a>
                                    </div>
                                </div>
                                <span className="v-line mb-9" />
                                <div className="navbar_top__social d-flex align-items-center gap-2 gap-xl-3">
                                    <span className="p7-color fw-bolder">Follow Us:</span>
                                    <div className="navbar_top__social-icon d-flex align-items-center  gap-1 gap-xl-2">
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-facebook fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-instagram fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-twitter fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-linkedin fs-four p7-color " />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
                <div className="navbar_mainhead header-fixed w-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 workready">
                                    <a
                                        href="/"
                                        className="navbar-brand d-flex align-items-center gap-2"
                                    >
                                        <img src="assets/images/logo.png" className="logo" alt="logo" />
                                    </a>
                                    <div
                                        className="collapse navbar-collapse justify-content-between"
                                        id="navbar-content"
                                    >
                                        <ul className="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                                            <li className="dropdown">
                                                <a href="/" className="fs-ten">
                                                    Home
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Staking
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/staking">
                                                            Staking Pool
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/staking_details"
                                                        >
                                                            Pool details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    IDO <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/ido">
                                                            IDO
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/idodetails"
                                                        >
                                                            IDO Details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Swap
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/swap">
                                                            Swap
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/swap_checkout"
                                                        >
                                                            Swap to share
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/apes" className="fs-ten">
                                                    ApeX
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Buy Crypto
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/buycrypto"
                                                        >
                                                            Buy Crypto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/pricing_plan"
                                                        >
                                                            Pricing plan
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Pages
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/rewards">
                                                            Rewards
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogs"
                                                        >
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogdetails"
                                                        >
                                                            Blog details
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/contact_us"
                                                        >
                                                            Contact us
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/terms_conditions"
                                                        >
                                                            Terms &amp; condition
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/error">
                                                            Error
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                                        <div className="single-item cart-area search-area">
                                            <div className="cmn-head">
                                                <button
                                                    type="button"
                                                    aria-label="Shopping Button"
                                                    className="common_toggles2 icon-area p-0 me-3 me-lg-0 box-second d-center position-relative"
                                                >
                                                    <i className="ti ti-search slide-toggle2 fs-four p6-color" />
                                                </button>
                                                <div className="msg_area common_area2 p2-bg p-5 rounded-2">
                                                    <form className="d-flex align-items-center ">
                                                        <input type="text" />
                                                        <button type="submit" className="p-2">
                                                            <i className="ti ti-search slide-toggle2 fs-four p2-color" />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-section__modalstyle">
                                            {/* Button trigger modal */}
                                            <button
                                                type="button"
                                                className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <span className="p7-color fw-semibold d-none d-sm-block">
                                                    Connect
                                                </span>
                                                Wallet
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        aria-label="Navbar Toggler"
                                        data-bs-target="#navbar-content"
                                        aria-expanded="true"
                                        id="nav-icon3"
                                    >
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="modal_areastyle">
                {/* Modal */}
                <div
                    className="modal"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content px-2 px-md-6 pt-md-3 pb-2 pb-md-6">
                            <div className="modal-header border-0">
                                <h3 className="modal-title p1-color" id="exampleModalLabel">
                                    Connect Your Wallet
                                </h3>
                                <button
                                    type="button"
                                    className="btn-close p7-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="ti ti-xbox-x p6-color fs-three fw-normal" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between mb-5 mb-md-6"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/dog.png" alt="Icon" />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/wallet-connect.png" alt="Icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* header-section end */}
            {/* Cyber Arena Starts */}
            <section className="cyber_arena pt-120 pb-120 bg5-color">
                <div className="container pt-17 pt-sm-20 pt-lg-0">
                    <div className="row ">
                        <div className="col-12">
                            <div className="cyber_arena__tophead d-flex align-items-center justify-content-between flex-wrap gap-4 mb-10 mb-md-15 wow fadeInUp">
                                <div className="cyber_arena__tphead d-flex align-items-center gap-3 flex-wrap">
                                    <div className="cyber_arena__tphead-itemone d-flex align-items-center gap-3 gap-sm-6 flex-wrap">
                                        <div className="cyber_arena__tphead-icon">
                                            <img src="assets/images/icon/cyber-arenaone.png" alt="Icon" />
                                        </div>
                                        <a
                                            href="javascript:void(0)"
                                            className="cyber_arena__tphead-name"
                                        >
                                            <h2>Pop Social</h2>
                                        </a>
                                    </div>
                                    <div className="cyber_arena__tphead-itemtwo d-flex align-items-center gap-3">
                                        <i className="ti ti-share fs-four" />
                                        <button
                                            type="button"
                                            className="px-5 py-2 fs-four bg1-color rounded-1"
                                        >
                                            Ended
                                        </button>
                                    </div>
                                </div>
                                <div className="cyber_arena__tophead-viewbtn">
                                    <a
                                        href="javascript:void(0)"
                                        className="d-inline-flex align-items-center rounded-2 gap-2 roboto py-3 px-5 px-md-6 bg8-color p7-color fw-bold"
                                    >
                                        View Result{" "}
                                        <i className="ti ti-chevron-right fs-five fw-bold" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="cyber_arena__webcnct d-flex align-items-center gap-3 gap-sm-5 gap-md-6 flex-wrap mb-8 mb-md-10 wow fadeInUp">
                                <a
                                    href="javascript:void(0)"
                                    className="d-flex align-items-center gap-2 py-2 px-3 px-md-4 fs-ten bg1-color br2 rounded-2"
                                >
                                    <i className="ti ti-world-www fs-five p2-color" /> Official
                                    Website
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="d-flex align-items-center gap-2 py-2 px-3 px-md-4 fs-ten bg1-color br2 rounded-2"
                                >
                                    <i className="ti ti-wallpaper fs-five p2-color" />
                                    Whitepaper
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="d-flex align-items-center gap-2 py-2 px-3 px-md-4 fs-ten bg1-color br2 rounded-2"
                                >
                                    <i className="ti ti-copy fs-five p2-color" />
                                    Contact Address
                                </a>
                            </div>
                            <p className="roboto mb-6 mb-md-8 wow fadeInUp">
                                CyberArena AR/VR is a project that integrates AR/VR features and
                                crypto economics. It provides 30+ million gamers in Imperium
                                ecosystem and 2+ billion gamers worldwide with a unique opportunity
                                to monetize their skills beyond just streaming. By transcending the
                                limitations of Web2 gaming, CyberArena opens up new possibilities
                                for immersive experiences and value-staked skill games.
                            </p>
                            <div className="cyber_arena__social d-flex align-items-center gap-3 flex-wrap mb-8 mb-md-10 wow fadeInUp">
                                <a
                                    href="javascript:void(0)"
                                    className="br5 px-3 py-2 d-center rounded-item"
                                >
                                    <i className="ti ti-brand-facebook-filled p2-color fs-four fw-normal" />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="br5 px-3 py-2 d-center rounded-item"
                                >
                                    <i className="ti ti-brand-linkedin p2-color fs-four fw-normal" />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="br5 px-3 py-2 d-center rounded-item"
                                >
                                    <i className="ti ti-brand-instagram p2-color fs-four fw-normal" />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="br5 px-3 py-2 d-center rounded-item"
                                >
                                    <i className="ti ti-brand-twitter-filled p2-color fs-four fw-normal" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="counter_section__area d-flex align-items-center justify-content-center justify-content-sm-evenly flex-wrap gap-5 br2 p-3 p-sm-6 p-md-8 rounded-20 bg1-color mb-10 mb-md-15">
                            <div className="cyber_arena__item text-center">
                                <div className="cyber_arena__countdown counters d-flex align-items-center justify-content-center">
                                    <span
                                        className="odometer hero_area__countdown-number fs-three fw_500"
                                        data-odometer-final="4390.13"
                                    />
                                    <span className="fs-three fw_500">%</span>
                                </div>
                                <span className="text-center fs-ten">Peak ROI</span>
                            </div>
                            <span className="v-line lgx mb-20 pb-6 d-none d-md-block" />
                            <div className="cyber_arena__item text-center">
                                <div className="cyber_arena__countdown counters d-flex align-items-center justify-content-center">
                                    <span className="fs-three fw_500">$</span>
                                    <span
                                        className="odometer hero_area__countdown-number fs-three fw_500"
                                        data-odometer-final="47,600"
                                    />
                                    <span className="fs-three fw_500">/</span>
                                    <span className="fs-three fw_500">$</span>
                                    <span
                                        className="odometer hero_area__countdown-number fs-three fw_500"
                                        data-odometer-final="50,000"
                                    />
                                </div>
                                <span className="text-center fs-ten">Total Raised</span>
                            </div>
                            <span className="v-line lgx mb-20 pb-6 d-none d-md-block" />
                            <div className="cyber_arena__item">
                                <div className="cyber_arena__countdown counters d-flex align-items-center justify-content-center">
                                    <span
                                        className="odometer hero_area__countdown-number fs-three fw_500"
                                        data-odometer-final="28,751"
                                    />
                                </div>
                                <span className="text-center fs-ten">Total Participants</span>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-5 gy-sm-6 mb-10 mb-md-15">
                        <div className="col-12">
                            <h3 className="cyber_arena__tstyle mb-5 mb-md-6 wow fadeInUp">
                                IDO Details
                            </h3>
                            <p className="roboto mb-3 mb-md-5 wow fadeInUp">
                                All participants will have an equal chance to win the token
                                allocation lottery. Holders of winning tickets may redeem their
                                allocations (denominated in USDT) of IDO tokens with USDT from their
                                Bybit Wallets.
                            </p>
                        </div>
                        <div className="col-md-8 col-xl-9">
                            <div className="cyber_arena__totalcard p-6 p-md-8 br2 bg1-color rounded-20 h-100">
                                <div className="d-flex align-items-center justify-content-between mb-6 mb-md-8 gap-4 flex-wrap flex-sm-nowrap">
                                    <div className="cyber_arena__totalcard-title">
                                        <span className="mb-3 fs-ten">Total Amount</span>
                                        <div className="d-flex align-items-end gap-2">
                                            <span className="fs-four p1-color fw-bold">50,000</span>
                                            <span className="fs-ten">USDT</span>
                                        </div>
                                    </div>
                                    <div className="cyber_arena__totalcard-title text-sm-end">
                                        <span className="mb-3 fs-ten">Total Issued</span>
                                        <div className="d-flex align-items-end gap-2">
                                            <span className="fs-four p1-color fw-bold">3,472,222</span>
                                            <span className="fs-ten">CAT</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cyber_arena__nastcard p-5 p-md-6 rounded-20 bg1-color d-flex flex-column gap-4 gap-md-6 br2">
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Total Winning Tickets</span>
                                        <span>250 Tickets</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Redemption Amount</span>
                                        <span>200 USDT per Ticket</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Total ParticipantsV</span>
                                        <span>28,751</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-4 col-xl-3">
                            <div className="cyber_arena__totalcardtwo bg1-color rounded-20 br2 py-7 py-md-9 px-5 px-md-6 br2 text-center">
                                <div className="d-flex align-items-center justify-content-center gap-2 gap-sm-3 mb-3">
                                    <img src="assets/images/icon/tstart.png" alt="icon" />
                                    <span className="fs-five">USDT</span>
                                </div>
                                <div className="d-flex align-items-center mb-3 mb-md-6">
                                    <hr className="w-100 " />
                                    <i className="ti ti-arrow-down fs-four br2 px-1 rounded-5" />
                                    <hr className="w-100" />
                                </div>
                                <span className="mb-3 mb-md-4 d-block ">Launch Price</span>
                                <span className="fs-four d-block p1-color fw-bold mb-6 mb-md-8">
                                    0.0144 USDT
                                </span>
                                <div className="d-flex align-items-center mb-3 mb-md-6">
                                    <hr className="w-100 " />
                                    <i className="ti ti-arrow-down fs-four br2 px-1 rounded-5" />
                                    <hr className="w-100" />
                                </div>
                                <div className="d-flex align-items-center justify-content-center gap-2 gap-sm-3 mb-3">
                                    <img src="assets/images/icon/tstart.png" alt="icon" />
                                    <span className="fs-five">USDT</span>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="cyber_arena__decs mb-8 mb-md-10">
                            <h3 className="mb-5 mb-md-6 wow fadeInUp">IDO Timeline</h3>
                            <p className="roboto mb-8 mb-md-10 wow fadeInUp">
                                All participants will have an equal chance to win the token
                                allocation lottery. Holders of winning tickets may redeem their
                                allocations (denominated in USDT) of IDO tokens with USDT from their
                                Bybit Wallets.
                            </p>
                            <div className="cyber_arena__decs-card p-4 p-sm-6 p-md-8 rounded-20 mb-5 mb-md-6 wow fadeInUp">
                                <div className="d-flex align-items-center justify-content-between flex-wrap mb-5 mb-md-6 gap-3">
                                    <h4>Subscription Period</h4>
                                    <span className="roboto">09-25 10:00 - 10-05 10:00 UTC</span>
                                </div>
                                <p className="mb-6 mb-md-8">
                                    The Subscription Period refers to the duration during which a
                                    service or product subscription remains active, offering access to
                                    features, benefits, or content as per the subscribed plan's terms
                                    and conditions.
                                </p>
                                <a
                                    href="javascript:void(0)"
                                    className="d-inline-flex align-items-center cmn-btn rounded-2 gap-2 roboto py-3 px-5 px-md-6 p7-color fw-bold"
                                >
                                    How It Works
                                    <i className="ti ti-chevron-right fs-five fw-bold" />
                                </a>
                            </div>
                            <div className="cyber_arena__decs-card p-4 p-sm-6 p-md-8 rounded-20 mb-5 mb-md-6 wow fadeInUp">
                                <div className="d-flex align-items-center justify-content-between flex-wrap mb-5 mb-md-6 gap-3">
                                    <h4>Snapshot Period</h4>
                                    <span className="roboto">09-25 10:00 - 10-05 10:00 UTC</span>
                                </div>
                                <p className="mb-6 mb-md-8">
                                    Snapshot Period refers to the specific timeframe during which data
                                    or information is captured for analysis, typically used in
                                    blockchain governance to record holders' balances or voting rights
                                    for decision-making processes.
                                </p>
                                <a
                                    href="javascript:void(0)"
                                    className="d-inline-flex align-items-center cmn-btn rounded-2 gap-2 roboto py-3 px-5 px-md-6 p7-color fw-bold"
                                >
                                    How It Works
                                    <i className="ti ti-chevron-right fs-five fw-bold" />
                                </a>
                            </div>
                            <div className="cyber_arena__decs-card p-4 p-sm-6 p-md-8 rounded-20 mb-5 mb-md-6 wow fadeInUp">
                                <div className="d-flex align-items-center justify-content-between flex-wrap mb-5 mb-md-6 gap-3">
                                    <h4>Lottery Period</h4>
                                    <span className="roboto">09-25 10:00 - 10-05 10:00 UTC</span>
                                </div>
                                <p className="mb-6 mb-md-8">
                                    During the Lottery Period, participants have the opportunity to
                                    enter for a chance to win exciting rewards, fostering anticipation
                                    and engagement within the community. Join now and be part of the
                                    thrill!
                                </p>
                                <a
                                    href="javascript:void(0)"
                                    className="d-inline-flex align-items-center cmn-btn rounded-2 gap-2 roboto py-3 px-5 px-md-6 p7-color fw-bold"
                                >
                                    How It Works
                                    <i className="ti ti-chevron-right fs-five fw-bold" />
                                </a>
                            </div>
                            <div className="cyber_arena__decs-card p-4 p-sm-6 p-md-8 rounded-20 mb-5 mb-md-6 wow fadeInUp">
                                <div className="d-flex align-items-center justify-content-between flex-wrap mb-5 mb-md-6 gap-3">
                                    <h4>Redemption Period</h4>
                                    <span className="roboto">09-25 10:00 - 10-05 10:00 UTC</span>
                                </div>
                                <p className="mb-6 mb-md-8">
                                    The Redemption Period refers to the timeframe after a foreclosure
                                    sale when the homeowner may reclaim their property by paying off
                                    the outstanding debts and associated costs, typically within a
                                    specific legal timeframe.
                                </p>
                                <a
                                    href="javascript:void(0)"
                                    className="d-inline-flex align-items-center cmn-btn rounded-2 gap-2 roboto py-3 px-5 px-md-6 p7-color fw-bold"
                                >
                                    How It Works
                                    <i className="ti ti-chevron-right fs-five fw-bold" />
                                </a>
                            </div>
                            <p className="roboto wow fadeInUp">
                                All participants will have an equal chance to win the token
                                allocation lottery. Holders of winning tickets may redeem their
                                allocations (denominated in USDT) of IDO tokens with USDT from their
                                CoinX Wallets.
                            </p>
                        </div>
                        <div className="cyber_arena__table wow fadeInUp">
                            <table className="table">
                                <thead>
                                    <tr className="rounded-20">
                                        <th scope="col">Vesting Time (UTC)</th>
                                        <th scope="col">Release Percentage</th>
                                        <th scope="col">Amount (CAT)</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    <tr>
                                        <td>2024-10-05 10:10</td>
                                        <td>25%</td>
                                        <td>36,231</td>
                                    </tr>
                                    <tr>
                                        <td>2024-01-05 10:00</td>
                                        <td>5%</td>
                                        <td>7,246</td>
                                    </tr>
                                    <tr>
                                        <td>2024-02-05 10:00</td>
                                        <td>5%</td>
                                        <td>7,246</td>
                                    </tr>
                                    <tr>
                                        <td>2024-03-05 10:00</td>
                                        <td>5%</td>
                                        <td>7,246</td>
                                    </tr>
                                    <tr>
                                        <td>2024-04-05 10:00</td>
                                        <td>5%</td>
                                        <td>7,246</td>
                                    </tr>
                                    <tr className="last-footer">
                                        <td className="fs-four fw-normal p1-color">Total</td>
                                        <td className="fs-four fw-normal p1-color">100%</td>
                                        <td className="fs-four fw-normal p1-color">65,215</td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>
                    </div>
                </div>
            </section>
            {/* Cyber Arena Ends */}
            {/* Rewards FAQs Starts */}
            <section className="rewards_faq pt-120 pb-120 position-relative">
                <div className="container">
                    <div className="row align-items-center justify-content-center gy-8">
                        <div className="col-xl-7">
                            <div className="rewards_faq__content">
                                <h2 className="mb-5 mb-md-6 wow fadeInUp">FAQs</h2>
                                <p className="roboto mb-8 mb-md-10 wow fadeInUp">
                                    Explore our FAQs for fast, informative answers to frequently asked
                                    questions and common concerns.
                                </p>
                                <div className="accordion-section">
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What currencies does the Coinx Exchange support?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What is the Coinx Exchange?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What currencies does the Coinx Exchange support?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What currencies does the Coinx Exchange support?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What is the Coinx Exchange?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What currencies does the Coinx Exchange support?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-wrap gap-2 wow fadeInUp">
                                    <span>Can't see your question listed above?</span>
                                    <a
                                        href="javascript:void(0)"
                                        className="d-flex align-items-center gap-1 p6-color astyle"
                                    >
                                        Visit our Help Center{" "}
                                        <i className="ti ti-arrow-narrow-right fs-four mt-1" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="rewards_faq__thumb">
                                <img
                                    src="assets/images/faq-thumb.png"
                                    className="max-un leftright-animation"
                                    alt="Images"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <img class="rewards_faq__light position-absolute top-0 start-0" src="assets/images/light-faq.png" alt="Icon"> */}
            </section>
            {/* Rewards FAQs Ends */}
            {/* Footer Section Starts */}
            <footer className="footer pt-120 bg5-color">
                <div className="container">
                    <div className="row gy-8 pb-120 justify-content-between">
                        <div className="col-md-12 col-lg-6 col-xxl-5">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <h2>Coinx Wallet</h2>
                                </a>
                                <p className="fs-ten mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp">
                                    Welcome to Coinx, your gateway to the world of Web3 trading! Our
                                    user-friendly platform empowers you to explore a wide range of
                                    popular cryptocurrencies
                                </p>
                                <div className="footer__decs-subscribe mb-9 mb-md-12 wow fadeInUp">
                                    <form className="d-flex align-items-center rounded-1 py-2 pe-2">
                                        <input type="email" placeholder="Enter your email" />
                                        <button className="cmn-btn py-3  px-8 rounded-1 d-center gap-2">
                                            Subscribe
                                            <i className="ti ti-send fs-five d-none d-md-block" />
                                        </button>
                                    </form>
                                </div>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-facebook-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-linkedin p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-instagram p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2 ms-xxl-20">
                            <div className="footer__discover">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Company</h4>
                                <div className="footer__discover-nav">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                About Coinx Wallet
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Join Us
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Blog
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Academy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Security</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Contact Validator
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Protection fund
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Security
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Assets</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                BNB Chain
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Ethereum
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                ERC20
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-md-start">
                                        Copyright 2024 -
                                        <a href="/" className="p4-color">
                                            ConiX
                                        </a>
                                        All Rights Reserved{" "}
                                        <span className="p4-color fs-five mx-2">|</span> Design By
                                        <a
                                            href="https://themeforest.net/user/uiaxis"
                                            className="p4-color roboto"
                                        >
                                            {" "}
                                            UIAXIS
                                        </a>
                                    </span>
                                </div>
                                <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="p4-color roboto ps-4 ps-sm-6"
                                    >
                                        Privacy Policy
                                    </a>
                                    <span className="p4-color fs-five">|</span>
                                    <a href="javascript:void(0)" className="p4-color roboto">
                                        Cookie Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section Ends */}
        </>

    )
}

export default Idodetails