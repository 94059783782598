import React, { useState } from 'react';
import UserHeader from '../../component/userHeader';
import Auth from '../../component/auth';
import { QRCodeCanvas } from 'qrcode.react';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

function Receive() {
    const loginToken = localStorage.getItem('userToken');
    const [walletAddress] = useState(localStorage.getItem('web3address')); // Replace with actual wallet address

    const copyToClipboard = () => {
        navigator.clipboard.writeText(walletAddress);
        toast.success('Wallet address copied to clipboard!');
    };

    const shareAddress = () => {
        if (navigator.share) {
            navigator.share({
                title: 'Wallet Address',
                text: `Here is my wallet address: ${walletAddress}`,
            })
            .then(() => toast.success('Address shared successfully!'))
            .catch((error) => toast.error(`Error sharing address: ${error}`));
        } else {
            toast.error('Sharing not supported on this browser.');
        }
    };

    return (
        <>
            <UserHeader />
            <ToastContainer />
            <Auth />
            <section className="cmn_heros pb-120 pt-120">
                <div className="container">
                    <div className="row justify-content-center mt-5 mt-md-8 mt-lg-0">
                        <div className="col-xxl-6">
                            <div className="cmn_heros__title text-center pt-15 pt-lg-6">
                                <h1 className="display-three mb-5 mb-md-7 wow fadeInUp">
                                    RECEIVE ASSET
                                </h1>
                                <p className="roboto wow fadeInUp">
                                    You can receive any asset directly from a BEP-20 wallet address to any exchange or wallet.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section className="blog_details blog_resource pt-120 pb-120 bg9-color">
                <div className="container">
                    <div className="row pb-120">
                        <div className="blog_resource__topcard d-flex align-items-center gap-5 gap-sm-6 gap-lg-10 rounded-20 flex-wrap flex-lg-nowrap p-3 p-sm-6 p-lg-8 wow fadeInUp">
                            <div className="col-md-12 col-xl-4 col-xxl-4 col-12">
                                <div className="text-center">
                                    <QRCodeCanvas value={walletAddress} size={200} />
                                    <p class="text-dark">Scan qr Code bep20 Address</p>
                                </div>
                            </div>
                            <div className="col-md-12 col-xl-4 col-xxl-8">
                                <div className="text-center">
                                    <div className="blog_details__cmncard-content">
                                        <div className="mb-5 mb-sm-7 mb-md-8">
                                            <p className="fw-bold roboto p4-color">Wallet Address:</p>
                                            <p className="wallet-address p4-color">{walletAddress}</p>
                                        </div>
                                        <div className="d-flex justify-content-center gap-3">
                                            <button
                                                onClick={copyToClipboard}
                                                className="cmn-btn second-alt px-4 py-2 d-center"
                                            >
                                                Copy Address
                                            </button>
                                            <button
                                                onClick={shareAddress}
                                                className="cmn-btn second-alt px-4 py-2 d-center"
                                            >
                                                Share Address
                                            </button>
                                        </div>
                                        <p className="mt-4 p4-color">
                                            Scan the QR code or use the wallet address to receive assets directly.
                                        </p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    );
}

export default Receive;
