import React from 'react'

function Swap_checkout() {
    return (
        <>
            {/* Scroll To Top Start*/}
            <button
                className="scrollToTop d-none d-md-flex d-center"
                aria-label="scroll Bar Button"
            >
                <i className="ti ti-chevron-up fs-four p6-color" />
            </button>
            {/* header-section start */}
            <div className="navbar_top bg2-color py-4 d-none d-lg-block">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 col-xxl-5">
                            <div className="navbar_top__left d-flex align-items-center gap-2 gap-xl-6">
                                <div className="navbar_top__location d-flex align-items-center gap-1 gap-xl-3">
                                    <i className="ti ti-map-pin-filled fs-four p7-color" />
                                    <span className="roboto p7-color">
                                        102/B New Market, Sandigo-USA
                                    </span>
                                </div>
                                <span className="v-line mb-9" />
                                <a
                                    href="https://softivus.com/cdn-cgi/l/email-protection#fb9e839a968b979ebb9c969a9297d5989496"
                                    className="navbar_top__email roboto p7-color d-flex align-items-center gap-3"
                                >
                                    <i className="ti ti-mail-opened-filled fs-four" />
                                    <span
                                        className="__cf_email__"
                                        data-cfemail="25405d4448554940654248444c490b464a48"
                                    >
                                        [email&nbsp;protected]
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xxl-6">
                            <div className="navbar_top__right d-flex align-items-center justify-content-end gap-2 gap-xl-6">
                                <div className="navbar_top__call d-flex align-items-center gap-3">
                                    <span className="bg6-color py-2 px-3 rounded-item">
                                        <i className="ti ti-phone-call fs-four p7-color " />
                                    </span>
                                    <div>
                                        <span className="p7-color fw-bolder d-block">Contact Us:</span>
                                        <a href="tel:+9802344567" className="d-block p7-color">
                                            +980 234 4567
                                        </a>
                                    </div>
                                </div>
                                <span className="v-line mb-9" />
                                <div className="navbar_top__social d-flex align-items-center gap-2 gap-xl-3">
                                    <span className="p7-color fw-bolder">Follow Us:</span>
                                    <div className="navbar_top__social-icon d-flex align-items-center  gap-1 gap-xl-2">
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-facebook fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-instagram fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-twitter fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-linkedin fs-four p7-color " />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
                <div className="navbar_mainhead header-fixed w-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 workready">
                                    <a
                                        href="/"
                                        className="navbar-brand d-flex align-items-center gap-2"
                                    >
                                        <img src="assets/images/logo.png" className="logo" alt="logo" />
                                    </a>
                                    <div
                                        className="collapse navbar-collapse justify-content-between"
                                        id="navbar-content"
                                    >
                                        <ul className="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                                            <li className="dropdown">
                                                <a href="/" className="fs-ten">
                                                    Home
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Staking <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/staking">
                                                            Staking Pool
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/staking_details"
                                                        >
                                                            Pool details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    IDO <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/ido">
                                                            IDO
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/idodetails"
                                                        >
                                                            IDO Details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Swap <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/swap">
                                                            Swap
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/swap_checkout"
                                                        >
                                                            Swap to share
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/apes" className="fs-ten">
                                                    ApeX
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Buy Crypto
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/buycrypto"
                                                        >
                                                            Buy Crypto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/pricing_plan"
                                                        >
                                                            Pricing plan
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Pages
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/rewards">
                                                            Rewards
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogs"
                                                        >
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogdetails"
                                                        >
                                                            Blog details
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/contact_us"
                                                        >
                                                            Contact us
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/terms_conditions"
                                                        >
                                                            Terms &amp; condition
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/error">
                                                            Error
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                                        <div className="single-item cart-area search-area">
                                            <div className="cmn-head">
                                                <button
                                                    type="button"
                                                    aria-label="Shopping Button"
                                                    className="common_toggles2 icon-area p-0 me-3 me-lg-0 box-second d-center position-relative"
                                                >
                                                    <i className="ti ti-search slide-toggle2 fs-four p6-color" />
                                                </button>
                                                <div className="msg_area common_area2 p2-bg p-5 rounded-2">
                                                    <form className="d-flex align-items-center ">
                                                        <input type="text" />
                                                        <button type="submit" className="p-2">
                                                            <i className="ti ti-search slide-toggle2 fs-four p2-color" />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-section__modalstyle">
                                            {/* Button trigger modal */}
                                            <button
                                                type="button"
                                                className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <span className="p7-color fw-semibold d-none d-sm-block">
                                                    Connect
                                                </span>
                                                Wallet
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        aria-label="Navbar Toggler"
                                        data-bs-target="#navbar-content"
                                        aria-expanded="true"
                                        id="nav-icon3"
                                    >
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="modal_areastyle">
                {/* Modal */}
                <div
                    className="modal"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content px-2 px-md-6 pt-md-3 pb-2 pb-md-6">
                            <div className="modal-header border-0">
                                <h3 className="modal-title p1-color" id="exampleModalLabel">
                                    Connect Your Wallet
                                </h3>
                                <button
                                    type="button"
                                    className="btn-close p7-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="ti ti-xbox-x p6-color fs-three fw-normal" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between mb-5 mb-md-6"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/dog.png" alt="Icon" />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/wallet-connect.png" alt="Icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* header-section end */}
            {/*Common hero Section Starts */}
            <section className="cmn_heros pb-120 pt-120">
                <div className="container">
                    <div className="row justify-content-center mt-5 mt-md-8 mt-lg-0">
                        <div className="col-xxl-8">
                            <div className="cmn_heros__title text-center pt-15 pt-lg-6">
                                <h1 className="display-three mb-5 mb-md-7 wow fadeInUp">
                                    Swap &amp; Hold to Share
                                </h1>
                                <p className="roboto wow fadeInUp">
                                    Earn passive income easily with just one click!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Common hero Section Ends */}
            {/* Count Down Starts*/}
            <section className="countdwon_event pt-120 pb-120">
                <div className="container">
                    <div className="row align-items-center justify-content-between">
                        <div className="col-xxl-5">
                            <div className="countdwon_event__title">
                                <h3 className="p4-color mb-5 mb-md-6 wow fadeInUp">
                                    Coinx Web3 USDC Odyssey - Swap &amp; Hold
                                </h3>
                                <a
                                    className="cmn-btn fifth-alt fw-bold py-3 px-5 px-md-6 d-inline-flex align-items-center gap-2 "
                                    href="javascript:void(0)"
                                >
                                    Check Out <i className="ti ti-arrow-right fs-five" />
                                </a>
                            </div>
                        </div>
                        <div className="col-xxl-5">
                            <div className="countdwon_event__part">
                                <span className="fs-ten p4-color text-end d-block mb-5 mb-mb-6">
                                    The event will end in
                                </span>
                                <div className="coundown-container d-flex align-items-center gap-4">
                                    <div className="countdown-el days-container d-flex align-items-center gap-4">
                                        <h3
                                            className="big-text p4-color py-3 px-5 px-md-6 bg6-color rounded-2"
                                            id="days"
                                        >
                                            0
                                        </h3>
                                        <span className="p4-color fs-five">d</span>
                                    </div>
                                    <div className="countdown-el hours-container d-flex align-items-center gap-4">
                                        <h3
                                            className="big-text p4-color py-3 px-5 px-md-6 bg6-color rounded-2"
                                            id="hours"
                                        >
                                            0
                                        </h3>
                                        <span className="p4-color fs-five">h</span>
                                    </div>
                                    <div className="countdown-el minutes-container d-flex align-items-center gap-4">
                                        <h3
                                            className="big-text p4-color p4-color py-3 px-5 px-md-6 bg6-color rounded-2"
                                            id="minutes"
                                        >
                                            0
                                        </h3>
                                        <span className="p4-color fs-five">m</span>
                                    </div>
                                    <div className="countdown-el seconds-container d-flex align-items-center gap-4">
                                        <h3
                                            className="big-text p4-color p4-color py-3 px-5 px-md-6 bg6-color rounded-2"
                                            id="seconds"
                                        >
                                            0
                                        </h3>
                                        <span className="p4-color fs-five">s</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Count Down Ends*/}
            {/* To Participate Starts */}
            <section className="participate how_join event_rules pt-120 pb-120 bg7-color position-relative">
                <div className="container">
                    <div className="row mb-10 mb-md-15 gy-5 gy-md-6">
                        <div className="completed_project__title mb-8 mb-md-10">
                            <h2 className="wow fadeInUp">To Participate</h2>
                        </div>
                        <div className="col-xl-6">
                            <div className="completed_project__item participate__item br2 rounded-20 bg1-color p-6 p-md-8 wow fadeInUp">
                                <div className="completed_project__itemhead d-flex gap-3 mb-5 mb-md-6 d-flex align-items-center">
                                    <h5 className="fw-normal ">Task 1</h5>
                                    <a href="javascript:void(0)" className="cmn-btn py-1 px-2">
                                        <i className="ti ti-gift" /> Connect Wallet
                                    </a>
                                </div>
                                <h4 className="mb-6 mb-md-8">
                                    Swap at least 200 USDC for a 2X gas refund 1
                                </h4>
                                <div className="completed_project__roitotal d-flex align-items-center justify-content-between gap-2 mb-10 mb-md-15 flex-wrap">
                                    <div className="text-start">
                                        <span className="mb-5 mb-md-6 d-block ">
                                            Max. USDC amount per swap
                                        </span>
                                        <span className="dash-style" />
                                    </div>
                                    <div className="text-md-end">
                                        <span className="mb-5 mb-md-6 d-block ">
                                            Max. USDC amount per swap
                                        </span>
                                        <span className="dash-style" />
                                    </div>
                                </div>
                                <p className="roboto mb-2 mb-md-3">Total assigned Gas Fee amount</p>
                                <div className="completed_project__ccard">
                                    <div className="progress-bar__wrapper mb-3">
                                        <progress id="progress-bar" value={40} max={100} />
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between">
                                        <span>976.12 USDC</span>
                                        <span>10,000 USDC</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-6">
                            <div className="completed_project__item participate__item br2 rounded-20 bg1-color p-6 p-md-8 h-100 wow fadeInUp">
                                <div className="completed_project__itemhead d-flex gap-3 mb-5 mb-md-6 d-flex align-items-center">
                                    <h5 className="fw-normal ">Task 2</h5>
                                    <a href="javascript:void(0)" className="cmn-btn py-1 px-2">
                                        <i className="ti ti-gift" /> $22,000
                                    </a>
                                </div>
                                <h4 className="mb-6 mb-md-8">
                                    Swap at least 200 USDC for a 2X gas refund
                                </h4>
                                <div className="completed_project__roitotal d-flex align-items-center justify-content-between gap-2 mb-10 mb-md-15 flex-wrap">
                                    <div className="text-start">
                                        <span className="mb-5 mb-md-6 d-block ">
                                            Total valid swap amount
                                        </span>
                                        <span className="dash-style" />
                                    </div>
                                    <div className="text-md-end">
                                        <span className="mb-5 mb-md-6 d-block ">Valid hold period</span>
                                        <span className="dash-style" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-5 gy-md-6 mb-10 mb-md-15">
                        <div className="completed_project__title mb-5 mb-md-4">
                            <h2 className="wow fadeInUp">Swap for USDC</h2>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xxl-4">
                            <div className="how_join__item bg1-color py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative text-center wow fadeInUp">
                                <div className="how_join__item-thumb mb-6 mb-md-8 text-center  rounded-item d-inline-block">
                                    <img src="assets/images/icon/ethereum_eth.png" alt="Icons" />
                                </div>
                                <h4 className="mb-5 mb-md-6 text-center">Ethereum</h4>
                                <p className="text-center mb-7 mb-md-8">
                                    Discover over 400 cryptocurrencies including all the trending new
                                    listings.
                                </p>
                                <a
                                    href="javascript:void(0)"
                                    className="d-flex align-items-center justify-content-center gap-2 cmn-btn p4-color py-3 px-6"
                                >
                                    Swap <i className="ti ti-chevron-right mt-1" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xxl-4">
                            <div className="how_join__item bg1-color py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative text-center wow fadeInUp">
                                <div className="how_join__item-thumb mb-6 mb-md-8 text-center  rounded-item d-inline-block">
                                    <img src="assets/images/icon/bnb.png" alt="Icons" />
                                </div>
                                <h4 className="mb-5 mb-md-6 text-center">BNB Chain</h4>
                                <p className="text-center mb-7 mb-md-8">
                                    Discover over 400 cryptocurrencies including all the trending new
                                    listings.
                                </p>
                                <a
                                    href="javascript:void(0)"
                                    className="d-flex align-items-center justify-content-center gap-2 cmn-btn p4-color py-3 px-6"
                                >
                                    Swap <i className="ti ti-chevron-right mt-1" />
                                </a>
                            </div>
                        </div>
                        <div className="col-sm-6 col-lg-4 col-xxl-4">
                            <div className="how_join__item bg1-color py-7 py-md-10 px-6 px-md-8 rounded-3 br2 position-relative text-center wow fadeInUp">
                                <div className="how_join__item-thumb mb-6 mb-md-8 text-center  rounded-item d-inline-block">
                                    <img src="assets/images/icon/near_protocol.png" alt="Icons" />
                                </div>
                                <h4 className="mb-5 mb-md-6 text-center">BNB Chain</h4>
                                <p className="text-center mb-7 mb-md-8">
                                    Discover over 400 cryptocurrencies including all the trending new
                                    listings.
                                </p>
                                <a
                                    href="javascript:void(0)"
                                    className="d-flex align-items-center justify-content-center gap-2 cmn-btn p4-color py-3 px-6"
                                >
                                    Swap <i className="ti ti-chevron-right mt-1" />
                                </a>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="event_rules__card p-7 p-md-8 br2 bg1-color rounded-20">
                            <div className="event_rules__card-title wow fadeInUp">
                                <h4 className="mb-8 mb-md-10">Event Rules</h4>
                                <div className="event_rules__card-content d-flex flex-column gap-5 gap-md-6 mb-8 mb-md-10">
                                    <p className="roboto">
                                        Event Period: 2024-11-02 10:00:00 - 2024-11-15 10:00:00 UTC
                                    </p>
                                    <p>
                                        How to participate: Register or log in to CoinX{" "}
                                        <a href="/sign_up" className="p1-color">
                                            link to sign-up,
                                        </a>{" "}
                                        and create your CoinX Wallet{" "}
                                        <a href="javascript:void(0)" className="p1-color">
                                            link to CoinX Wallet
                                        </a>{" "}
                                        if you don’t have one.
                                    </p>
                                    <div className="d-flex flex-wrap flex-xxl-nowrap gap-3 gap-sm-5 gap-md-6">
                                        <h5 className="fw-normal">Task 1:</h5>
                                        <p className="roboto">
                                            Swap at least 200 USDC on supported chains (Ethereum, BNB
                                            Chain, Arbitrum One) during the Swap Period to be eligible for
                                            a 2X gas fee refund.Only $10,000 in gas fee refunds will be
                                            distributed on a first-come, first-served basis. Each address
                                            can receive a maximum 2X gas fee refund of up to $20. Get
                                            started now!
                                        </p>
                                    </div>
                                    <div className="d-flex flex-wrap flex-xxl-nowrap gap-3 gap-sm-5 gap-md-6">
                                        <h5 className="fw-normal">Task 2:</h5>
                                        <p className="roboto">
                                            Hold your Swapped USDC for 7 days in a row to share 20,000
                                            USDC tokens.Hourly snapshots will capture participants' USDC
                                            assets in their Bybit Wallet. Falling below the originally
                                            swapped assets due to trading or transfers disqualifies users
                                            from getting the rewards.
                                        </p>
                                    </div>
                                </div>
                                <p className="roboto">
                                    Note: For Task 1 and 2, you need to Swap for a minimum of 200 USDC
                                    to be eligible for rewards.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
                <img
                    src="assets/images/bgmusts.png"
                    className="participate__shapeadd position-absolute"
                    alt="shpae"
                />
            </section>
            {/* To Participate Ends */}
            {/* Swap for USDC Starts */}
            {/* <section class="bg7-color pb-10 pb-md-15">
  <div class="container">
      
  </div>
    </section> */}
            {/* Swap for USDC Ends */}
            {/* Event Rules Section Starts */}
            {/* <section class="event_rules bg7-color pb-120">
  <div class="container">
      
  </div>
    </section> */}
            {/* Event Rules Section Ends */}
            {/* Footer Section Starts */}
            <footer className="footer pt-120 bg5-color">
                <div className="container">
                    <div className="row gy-8 pb-120 justify-content-between">
                        <div className="col-md-12 col-lg-6 col-xxl-5">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <h2>Coinx Wallet</h2>
                                </a>
                                <p className="fs-ten mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp">
                                    Welcome to Coinx, your gateway to the world of Web3 trading! Our
                                    user-friendly platform empowers you to explore a wide range of
                                    popular cryptocurrencies
                                </p>
                                <div className="footer__decs-subscribe mb-9 mb-md-12 wow fadeInUp">
                                    <form className="d-flex align-items-center rounded-1 py-2 pe-2">
                                        <input type="email" placeholder="Enter your email" />
                                        <button className="cmn-btn py-3  px-8 rounded-1 d-center gap-2">
                                            Subscribe
                                            <i className="ti ti-send fs-five d-none d-md-block" />
                                        </button>
                                    </form>
                                </div>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-facebook-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-linkedin p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-instagram p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2 ms-xxl-20">
                            <div className="footer__discover">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Company</h4>
                                <div className="footer__discover-nav">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                About Coinx Wallet
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Join Us
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Blog
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Academy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Security</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Contact Validator
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Protection fund
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Security
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Assets</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                BNB Chain
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Ethereum
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                ERC20
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-md-start">
                                        Copyright 2024 -
                                        <a href="/" className="p4-color">
                                            ConiX
                                        </a>
                                        All Rights Reserved{" "}
                                        <span className="p4-color fs-five mx-2">|</span> Design By
                                        <a
                                            href="https://themeforest.net/user/uiaxis"
                                            className="p4-color roboto"
                                        >
                                            {" "}
                                            UIAXIS
                                        </a>
                                    </span>
                                </div>
                                <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="p4-color roboto ps-4 ps-sm-6"
                                    >
                                        Privacy Policy
                                    </a>
                                    <span className="p4-color fs-five">|</span>
                                    <a href="javascript:void(0)" className="p4-color roboto">
                                        Cookie Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section Ends */}
        </>

    )
}

export default Swap_checkout