import React from 'react'

function Blogdetails() {
    return (
        <>
            {/* Scroll To Top Start*/}
            <button
                className="scrollToTop d-none d-md-flex d-center"
                aria-label="scroll Bar Button"
            >
                <i className="ti ti-chevron-up fs-four p6-color" />
            </button>
            {/* Scroll To Top End */}
            {/* header-section start */}
            <div className="navbar_top bg2-color py-4 d-none d-lg-block">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 col-xxl-5">
                            <div className="navbar_top__left d-flex align-items-center gap-2 gap-xl-6">
                                <div className="navbar_top__location d-flex align-items-center gap-1 gap-xl-3">
                                    <i className="ti ti-map-pin-filled fs-four p7-color" />
                                    <span className="roboto p7-color">
                                        102/B New Market, Sandigo-USA
                                    </span>
                                </div>
                                <span className="v-line mb-9" />
                                <a
                                    href="https://softivus.com/cdn-cgi/l/email-protection#8aeff2ebe7fae6efcaede7ebe3e6a4e9e5e7"
                                    className="navbar_top__email roboto p7-color d-flex align-items-center gap-3"
                                >
                                    <i className="ti ti-mail-opened-filled fs-four" />
                                    <span
                                        className="__cf_email__"
                                        data-cfemail="315449505c415d5471565c50585d1f525e5c"
                                    >
                                        [email&nbsp;protected]
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xxl-6">
                            <div className="navbar_top__right d-flex align-items-center justify-content-end gap-2 gap-xl-6">
                                <div className="navbar_top__call d-flex align-items-center gap-3">
                                    <span className="bg6-color py-2 px-3 rounded-item">
                                        <i className="ti ti-phone-call fs-four p7-color " />
                                    </span>
                                    <div>
                                        <span className="p7-color fw-bolder d-block">Contact Us:</span>
                                        <a href="tel:+9802344567" className="d-block p7-color">
                                            +980 234 4567
                                        </a>
                                    </div>
                                </div>
                                <span className="v-line mb-9" />
                                <div className="navbar_top__social d-flex align-items-center gap-2 gap-xl-3">
                                    <span className="p7-color fw-bolder">Follow Us:</span>
                                    <div className="navbar_top__social-icon d-flex align-items-center  gap-1 gap-xl-2">
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-facebook fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-instagram fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-twitter fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-linkedin fs-four p7-color " />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
                <div className="navbar_mainhead header-fixed w-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 workready">
                                    <a
                                        href="/"
                                        className="navbar-brand d-flex align-items-center gap-2"
                                    >
                                        <img src="assets/images/logo.png" className="logo" alt="logo" />
                                    </a>
                                    <div
                                        className="collapse navbar-collapse justify-content-between"
                                        id="navbar-content"
                                    >
                                        <ul className="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                                            <li className="dropdown">
                                                <a href="/" className="fs-ten">
                                                    Home
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Staking <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/staking">
                                                            Staking Pool
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/staking_details"
                                                        >
                                                            Pool details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    IDO <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/ido">
                                                            IDO
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/idodetails"
                                                        >
                                                            IDO Details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Swap <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/swap">
                                                            Swap
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/swap_checkout"
                                                        >
                                                            Swap to share
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/apes" className="fs-ten">
                                                    ApeX
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Buy Crypto
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/buycrypto"
                                                        >
                                                            Buy Crypto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/pricing_plan"
                                                        >
                                                            Pricing plan
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Pages
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/rewards">
                                                            Rewards
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogs"
                                                        >
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogdetails"
                                                        >
                                                            Blog details
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/contact_us"
                                                        >
                                                            Contact us
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/terms_conditions"
                                                        >
                                                            Terms &amp; condition
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/error">
                                                            Error
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                                        <div className="single-item cart-area search-area">
                                            <div className="cmn-head">
                                                <button
                                                    type="button"
                                                    aria-label="Shopping Button"
                                                    className="common_toggles2 icon-area p-0 me-3 me-lg-0 box-second d-center position-relative"
                                                >
                                                    <i className="ti ti-search slide-toggle2 fs-four p6-color" />
                                                </button>
                                                <div className="msg_area common_area2 p2-bg p-5 rounded-2">
                                                    <form className="d-flex align-items-center ">
                                                        <input type="text" />
                                                        <button type="submit" className="p-2">
                                                            <i className="ti ti-search slide-toggle2 fs-four p2-color" />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-section__modalstyle">
                                            {/* Button trigger modal */}
                                            <button
                                                type="button"
                                                className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <span className="p7-color fw-semibold d-none d-sm-block">
                                                    Connect
                                                </span>
                                                Wallet
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        aria-label="Navbar Toggler"
                                        data-bs-target="#navbar-content"
                                        aria-expanded="true"
                                        id="nav-icon3"
                                    >
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="modal_areastyle">
                {/* Modal */}
                <div
                    className="modal"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content px-2 px-md-6 pt-md-3 pb-2 pb-md-6">
                            <div className="modal-header border-0">
                                <h3 className="modal-title p1-color" id="exampleModalLabel">
                                    Connect Your Wallet
                                </h3>
                                <button
                                    type="button"
                                    className="btn-close p7-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="ti ti-xbox-x p6-color fs-three fw-normal" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between mb-5 mb-md-6"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/dog.png" alt="Icon" />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/wallet-connect.png" alt="Icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* header-section end */}
            {/*Common hero Section Starts */}
            <section className="cmn_heros pb-120 pt-120">
                <div className="container">
                    <div className="row justify-content-center mt-5 mt-md-8 mt-lg-0">
                        <div className="col-xxl-6">
                            <div className="cmn_heros__title text-center pt-15 pt-lg-6">
                                <h1 className="display-three mb-5 mb-md-7 wow fadeInUp">
                                    Blog Details
                                </h1>
                                <p className="roboto wow fadeInUp">
                                    Explore our blog and resources for valuable insights, expert
                                    opinions, and up-to-date information on the latest trends in the
                                    industry.
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Common hero Section Ends */}
            {/* Pricing Plan Section Starts Starts */}
            <section className="blog_details pt-120 pb-120 bg9-color">
                <div className="container">
                    <div className="row gy-6 align-items-end justify-content-between mb-6">
                        <div className="col-xl-6">
                            <div className="blog_details__title">
                                <h2 className="text_grdt wow fadeInUp">
                                    Why customer retention is the growth strategy.
                                </h2>
                            </div>
                        </div>
                        <div className="col-xl-3">
                            <div className="blog_details__btn d-flex align-items-center justify-content-end gap-5 gap-md-6 wow fadeInUp">
                                <a
                                    href="javascript:void(0)"
                                    className="p1-color py-2 px-4 br2 bg1-color"
                                >
                                    Marketing
                                </a>
                                <span>April 8, 2024</span>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="blog_details__chart br2 rounded-20 p-3 p-sm-7 p-md-10 wow fadeInUp mt-5 mt-md-7 mt-lg-9">
                                <div className="blog_details__chart-title d-flex align-items-center justify-content-between gap-3 flex-wrap mb-6 mb-sm-8 mb-md-10">
                                    <h2>Analytics</h2>
                                    <div className="d-flex align-items-center flex-wrap gap-3 gap-sm-5 gap-md-6">
                                        <div className="d-flex align-items-center gap-2">
                                            <i className="ti ti-point-filled fs-two p1-color" />
                                            <span className="fs-four">Income</span>
                                        </div>
                                        <div className="d-flex align-items-center gap-2">
                                            <i className="ti ti-point-filled fs-two p1-color" />
                                            <span className="fs-four">Outcome</span>
                                        </div>
                                        <a
                                            href="javascript:void(0)"
                                            className="cmn-btn py-2 px-4 rounded-1"
                                        >
                                            2024
                                        </a>
                                    </div>
                                </div>
                                <div className="blog_details__chart-thumb">
                                    <img src="assets/images/blogdetailschart.png" alt="Images" />
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="terms_condition__content mt-5 mt-sm-7 mt-lg-9">
                                <h3 className="mb-5 mb-md-6 wow fadeInUp">Introduction</h3>
                                <p className="mb-5 mb-md-6 wow fadeInUp">
                                    Customer retention is a vital growth strategy that focuses on
                                    retaining existing customers and maximizing their value over time.
                                    While acquiring new customers is important, businesses should not
                                    overlook the power of customer retention in driving sustainable
                                    growth. In this blog post, we will explore why customer retention
                                    should be a top priority for businesses and how it can contribute
                                    to their overall growth and success.
                                </p>
                                <hr className="bg2-color mb-7 mb-sm-10 mb-lg-15" />
                                <h3 className="mb-5 mb-md-6 wow fadeInUp">
                                    The Value of Existing Customers
                                </h3>
                                <p className="mb-5 mb-md-6 wow fadeInUp">
                                    Retaining existing customers is highly valuable for businesses.
                                    These customers have already experienced the value and quality of
                                    your products or services, and if satisfied, they are more likely
                                    to make repeat purchases. Not only do repeat customers contribute
                                    to increased revenue, but they also have higher average
                                    transaction values, boosting profitability.
                                </p>
                                <ol className="d-flex flex-column gap-1 mb-5 mb-md-6 wow fadeInUp">
                                    <li>
                                        Tasks and rewards limited; issued first-come, first-served.
                                    </li>
                                    <li>
                                        Limited tasks, rewards; given on first-come, first-served basis.
                                    </li>
                                    <li>Availability limited; rewards issued in order of receipt.</li>
                                    <li>Tasks limited, rewards given on first-come basis.</li>
                                </ol>
                                <hr className="bg2-color mb-7 mb-sm-10 mb-lg-15" />
                                <h3 className="mb-5 mb-md-6 wow fadeInUp">
                                    Cost-Effective Strategy
                                </h3>
                                <p className="mb-5 mb-md-6 wow fadeInUp">
                                    Customer retention is a cost-effective approach to growth.
                                    Acquiring new customers typically involves extensive marketing and
                                    advertising efforts, which can be costly. In contrast, retaining
                                    existing customers requires less investment as you already have a
                                    relationship established with them. By focusing on delivering
                                    exceptional experiences, personalized services, and excellent
                                    customer support, businesses can increase customer loyalty and
                                    reduce the need for constant acquisition campaigns.
                                </p>
                                <hr className="bg2-color mb-7 mb-sm-10 mb-lg-15" />
                                <h3 className="mb-5 mb-md-6 wow fadeInUp">
                                    Building Loyalty and Advocacy
                                </h3>
                                <p className="mb-5 mb-md-6 wow fadeInUp">
                                    Loyal customers are more than just revenue generators; they become
                                    brand advocates. Satisfied customers are more likely to recommend
                                    your business to others, whether through word-of-mouth, online
                                    reviews, or social media. These recommendations carry significant
                                    weight and can bring in new customers without the need for
                                    additional marketing efforts. By prioritizing customer retention,
                                    businesses can foster loyalty, strengthen their reputation, and
                                    expand their customer base through positive referrals.
                                </p>
                                <ol className="d-flex flex-column gap-1 mb-5 mb-md-6 wow fadeInUp">
                                    <li>
                                        The final interpretation of terms and conditions rests with
                                        CoinX.
                                    </li>
                                    <li>
                                        All terms and conditions interpretation is at the discretion of
                                        CoinX
                                    </li>
                                    <li>
                                        The definitive interpretation of terms belongs to CoinX's
                                        authority.
                                    </li>
                                    <li>
                                        CoinX has the final say in interpreting terms and conditions.
                                    </li>
                                </ol>
                                <hr className="bg2-color mb-7 mb-sm-10 mb-lg-15" />
                                <h3 className="mb-5 mb-md-6 wow fadeInUp">Conclusion</h3>
                                <p className="mb-5 mb-md-6 wow fadeInUp">
                                    Customer retention is a growth strategy that should not be
                                    underestimated. By recognizing the value of existing customers,
                                    implementing cost-effective retention strategies, and leveraging
                                    customer loyalty for advocacy, businesses can achieve sustainable
                                    growth and long-term success. Prioritizing customer retention
                                    alongside customer acquisition ensures a balanced approach to
                                    business growth and sets the foundation for building strong
                                    customer relationships. In the competitive marketplace, customer
                                    retention becomes a key driver of growth and profitability, making
                                    it an indispensable strategy for businesses of all sizes.
                                </p>
                                <hr className="bg2-color mb-7 mb-sm-10 mb-lg-15" />
                            </div>
                            <div className="blog_details__quet rounded-20 br2 bg1-color mb-4 mb-md-6 mb-xl-9">
                                <p className="fs-four py-3 py-sm-8 py-md-10 py-lg-15 py-xl-20 px-3 px-sm-10 px-lg-20 text-center wow fadeInUp">
                                    "Web3 protocols propel us towards a borderless, inclusive digital
                                    world, where users have control over their data and assets."
                                </p>
                            </div>
                        </div>
                        <div className="col-12">
                            <div className="blog_details__card py-3 py-sm-6 py-lg-10 py-xl-15 px-3 px-sm-8 py-lx-15 px-xl-20 rounded-20 d-center justify-content-md-start flex-wrap flex-md-nowrap gap-4 gap-sm-5 gap-md-8 gap-xl-10 wow fadeInUp">
                                <div className="blog_details__card-thumb">
                                    <img
                                        src="assets/images/details-image-nayem.png"
                                        className="max-un"
                                        alt="Images"
                                    />
                                </div>
                                <div className="blog_details__card-content">
                                    <div className="d-flex align-items-center justify-content-center justify-content-sm-between gap-3 flex-wrap mb-5 mb-md-6">
                                        <h2>Ryan Marshall</h2>
                                        <div className="blog_details__card-social d-flex align-items-center gap-2 gap-md-3">
                                            <a
                                                href="javascript:void(0)"
                                                className="px-2 px-md-3 py-1 py-md-2  d-center"
                                            >
                                                <i className="ti ti-brand-facebook-filled fs-four" />
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                className="px-2 px-md-3 py-1 py-md-2  d-center"
                                            >
                                                <i className="ti ti-brand-instagram fs-four" />
                                            </a>
                                            <a
                                                href="javascript:void(0)"
                                                className="px-2 px-md-3 py-1 py-md-2  d-center"
                                            >
                                                <i className="ti ti-brand-twitter-filled fs-four" />
                                            </a>
                                        </div>
                                    </div>
                                    <p className="roboto text-center text-md-start">
                                        Id ipsum mi tempor eget. Pretium consectetur scelerisque blandit
                                        habitasse non ullamcorper enim, diam quam id et, tempus massa.
                                        Sed nam vulputate pellentesque quis. Varius a, nunc faucibus
                                        proin
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-5 gy-md-6">
                        <div className="col-12 mt-10 mt-md-15">
                            <div className="blog_details__cmncardtitile d-flex align-items-center justify-content-between gap-3 flex-wrap mb-5 mb-md-9 wow fadeInUp">
                                <h2>Our recent news</h2>
                                <a
                                    href="javascript:void(0)"
                                    className="cmn-btn py-3 px-5 px-md-6 roboto"
                                >
                                    View More
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="blog_details__cmncard p-3 p-sm-6 p-md-8 rounded-20 br2 bg1-color position-relative wow fadeInUp">
                                <div className="blog_details__cmncard-thumb mb-5 mb-sm-7 mb-md-8 rounded-4">
                                    <img src="assets/images/detailscard1.png" alt="Images" />
                                </div>
                                <div className="blog_details__cmncard-content">
                                    <div className="blog_details__cmncard-head d-flex align-items-center gap-2 mb-5 mb-sm-7 mb-md-8">
                                        <span className="fw-bold roboto">June 27, 2024</span>
                                        <i className="ti ti-point-filled pointed fs-nine" />
                                        <span className="fw-bold roboto">June 27, 2024</span>
                                    </div>
                                    <a href="javascript:void(0)">
                                        <h4 className="mb-5 mb-md-6">
                                            Why customer retention is the growth strategy.
                                        </h4>
                                    </a>
                                    <p className="mb-5 mb-sm-7 mb-md-8">
                                        Trading cryptocurrencies from your phone offers convenience and
                                        flexibility. The rise has opened up opportunities in
                                        cryptocurrency world.
                                    </p>
                                    <div className="blog_details__cmncard-btn">
                                        <a
                                            href="javascript:void(0)"
                                            className="cmn-btn d-inline-flex px-4 px-sm-5 px-md-6 py-2 py-md-3 d-center gap-2"
                                        >
                                            Read More <i className="ti ti-arrow-narrow-right fs-four" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-lg-6">
                            <div className="blog_details__cmncard p-3 p-sm-6 p-md-8 rounded-20 br2 bg1-color position-relative wow fadeInUp">
                                <div className="blog_details__cmncard-thumb mb-5 mb-sm-7 mb-md-8 rounded-4">
                                    <img src="assets/images/detailscard2.png" alt="Images" />
                                </div>
                                <div className="blog_details__cmncard-content">
                                    <div className="blog_details__cmncard-head d-flex align-items-center gap-2 mb-5 mb-sm-7 mb-md-8">
                                        <span className="fw-bold roboto">June 27, 2024</span>
                                        <i className="ti ti-point-filled pointed fs-nine" />
                                        <span className="fw-bold roboto">June 27, 2024</span>
                                    </div>
                                    <a href="javascript:void(0)">
                                        <h4 className="mb-5 mb-md-6">
                                            Why customer retention is the growth strategy.
                                        </h4>
                                    </a>
                                    <p className="mb-5 mb-sm-7 mb-md-8">
                                        Trading cryptocurrencies from your phone offers convenience and
                                        flexibility. The rise has opened up opportunities in
                                        cryptocurrency world.
                                    </p>
                                    <div className="blog_details__cmncard-btn">
                                        <a
                                            href="javascript:void(0)"
                                            className="cmn-btn d-inline-flex px-4 px-sm-5 px-md-6 py-2 py-md-3 d-center gap-2"
                                        >
                                            Read More <i className="ti ti-arrow-narrow-right fs-four" />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Pricing Plan Section Starts Ends */}
            {/* Footer Section Starts */}
            <footer className="footer pt-120 bg5-color">
                <div className="container">
                    <div className="row gy-8 pb-120 justify-content-between">
                        <div className="col-md-12 col-lg-6 col-xxl-5">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <h2>Coinx Wallet</h2>
                                </a>
                                <p className="fs-ten mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp">
                                    Welcome to Coinx, your gateway to the world of Web3 trading! Our
                                    user-friendly platform empowers you to explore a wide range of
                                    popular cryptocurrencies
                                </p>
                                <div className="footer__decs-subscribe mb-9 mb-md-12 wow fadeInUp">
                                    <form className="d-flex align-items-center rounded-1 py-2 pe-2">
                                        <input type="email" placeholder="Enter your email" />
                                        <button className="cmn-btn py-3  px-8 rounded-1 d-center gap-2">
                                            Subscribe
                                            <i className="ti ti-send fs-five d-none d-md-block" />
                                        </button>
                                    </form>
                                </div>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-facebook-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-linkedin p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-instagram p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2 ms-xxl-20">
                            <div className="footer__discover">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Company</h4>
                                <div className="footer__discover-nav">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                About Coinx Wallet
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Join Us
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Blog
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Academy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Security</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Contact Validator
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Protection fund
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Security
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Assets</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                BNB Chain
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Ethereum
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                ERC20
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-md-start">
                                        Copyright 2024 -
                                        <a href="/" className="p4-color">
                                            ConiX
                                        </a>
                                        All Rights Reserved{" "}
                                        <span className="p4-color fs-five mx-2">|</span> Design By
                                        <a
                                            href="https://themeforest.net/user/uiaxis"
                                            className="p4-color roboto"
                                        >
                                            {" "}
                                            UIAXIS
                                        </a>
                                    </span>
                                </div>
                                <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="p4-color roboto ps-4 ps-sm-6"
                                    >
                                        Privacy Policy
                                    </a>
                                    <span className="p4-color fs-five">|</span>
                                    <a href="javascript:void(0)" className="p4-color roboto">
                                        Cookie Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section Ends */}
        </>

    )
}

export default Blogdetails