import { BrowserRouter, Routes, Route } from 'react-router-dom';
import Home from './Pages/Home';
import Staking from './Pages/Staking';
import Staking_details from './Pages/Staking_details';
import Blogs from './Pages/Blogs';
import Blogdetails from './Pages/Blogdetails';
import Swap from './Pages/Swap';
import Apes from './Pages/Apes';
import Buycrypto from './Pages/Buycrypto';
import Swap_checkout from './Pages/Swap_checkout';
import Ido from './Pages/Ido';
import Idodetails from './Pages/Idodetails';
import Pricing_plan from './Pages/Pricing_plan';
import Rewards from './Pages/Rewards';
import Contact_us from './Pages/Contact_us';
import Terms_conditions from './Pages/Terms_conditions';
import Error from './Pages/Error';
import Sign_up from './Pages/Sign_up';
import Login from './Pages/Login';
import UserDashboard from './Pages/User/Dashboard';
import Direct from './Pages/User/Direct';
import Joinphase from './Pages/User/joinphase';
import Wallet from './Pages/User/Wallet';
import Receive from './Pages/User/Receive';
import Sent from './Pages/User/Sent';
import Myjoining from './Pages/User/Myjoining';
import Income from './Pages/User/Income'; 
import Directbonus from './Pages/User/Directbonus';
import Levelincome from './Pages/User/Levelincome';

//User Access Pages
function App() {
  return (
    <BrowserRouter>
    <Routes>
      <Route excat path='/' element={<Home/>}/>
      <Route path='/staking' element={<Staking/>}/>
      <Route path="/staking_details" element={<Staking_details/>}/>
      <Route path="/blogs" element={<Blogs/>}/>
      <Route path='blogdetails' element={<Blogdetails/>}></Route>
      <Route path='swap' element={<Swap/>}></Route>
      <Route path='/apes' element={<Apes/>}></Route>
      <Route path='/buycrypto' element={<Buycrypto/>}></Route>
      <Route path='/swap_checkout' element={<Swap_checkout/>}></Route>
      <Route path='/ido' element={<Ido/>}></Route>
      <Route path='/idodetails' element={<Idodetails/>}></Route>
      <Route path='/pricing_plan' element={<Pricing_plan/>}></Route>
      <Route path='/rewards' element={<Rewards/>}></Route>
      <Route path='/contact_us' element={<Contact_us/>}></Route>
      <Route path='/terms_conditions' element={<Terms_conditions/>}></Route>
      <Route path='/*' element={<Error/>}></Route>
      <Route path='/sign_up' element={<Sign_up/>}></Route>
      <Route path='/login' element={<Login/>}></Route>
      <Route path='/user-dashboard' element={<UserDashboard/>}></Route>
      <Route path='/user-community' element={<Direct/>}></Route>
      <Route path='/user-join-phase' element={<Joinphase/>}></Route>
      <Route path='/user-wallet' element={<Wallet/>}></Route>
      <Route path='/user-receive' element={<Receive/>}></Route>
      <Route path='/user-sent' element={<Sent/>}></Route>
      <Route path='/user-my-package' element={<Myjoining/>}></Route>
      <Route path='/user-income' element={<Income/>}></Route>
      <Route path='/user-directbonus' element={<Directbonus/>}></Route>
      <Route path='/user-levelincome' element={<Levelincome/>}></Route>
      

      

    </Routes>
    
    </BrowserRouter>
  );
}

export default App;
