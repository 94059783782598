import React from 'react'

function Staking_details() {
    return (
        <>
            {/* Scroll To Top Start*/}
            <button
                className="scrollToTop d-none d-md-flex d-center"
                aria-label="scroll Bar Button"
            >
                <i className="ti ti-chevron-up fs-four p6-color" />
            </button>
            {/* Scroll To Top End */}
            {/* header-section start */}
            <div className="navbar_top bg2-color py-4 d-none d-lg-block">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 col-xxl-5">
                            <div className="navbar_top__left d-flex align-items-center gap-2 gap-xl-6">
                                <div className="navbar_top__location d-flex align-items-center gap-1 gap-xl-3">
                                    <i className="ti ti-map-pin-filled fs-four p7-color" />
                                    <span className="roboto p7-color">
                                        102/B New Market, Sandigo-USA
                                    </span>
                                </div>
                                <span className="v-line mb-9" />
                                <a
                                    href="https://softivus.com/cdn-cgi/l/email-protection#62071a030f120e0722050f030b0e4c010d0f"
                                    className="navbar_top__email roboto p7-color d-flex align-items-center gap-3"
                                >
                                    <i className="ti ti-mail-opened-filled fs-four" />
                                    <span
                                        className="__cf_email__"
                                        data-cfemail="4b2e332a263b272e0b2c262a222765282426"
                                    >
                                        [email&nbsp;protected]
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xxl-6">
                            <div className="navbar_top__right d-flex align-items-center justify-content-end gap-2 gap-xl-6">
                                <div className="navbar_top__call d-flex align-items-center gap-3">
                                    <span className="bg6-color py-2 px-3 rounded-item">
                                        <i className="ti ti-phone-call fs-four p7-color " />
                                    </span>
                                    <div>
                                        <span className="p7-color fw-bolder d-block">Contact Us:</span>
                                        <a href="tel:+9802344567" className="d-block p7-color">
                                            +980 234 4567
                                        </a>
                                    </div>
                                </div>
                                <span className="v-line mb-9" />
                                <div className="navbar_top__social d-flex align-items-center gap-2 gap-xl-3">
                                    <span className="p7-color fw-bolder">Follow Us:</span>
                                    <div className="navbar_top__social-icon d-flex align-items-center  gap-1 gap-xl-2">
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-facebook fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-instagram fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-twitter fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-linkedin fs-four p7-color " />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
                <div className="navbar_mainhead header-fixed w-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 workready">
                                    <a
                                        href="/"
                                        className="navbar-brand d-flex align-items-center gap-2"
                                    >
                                        <img src="assets/images/logo.png" className="logo" alt="logo" />
                                    </a>
                                    <div
                                        className="collapse navbar-collapse justify-content-between"
                                        id="navbar-content"
                                    >
                                        <ul className="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                                            <li className="dropdown">
                                                <a href="/" className="fs-ten">
                                                    Home
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Staking <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/staking">
                                                            Staking Pool
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/staking_details"
                                                        >
                                                            Pool details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    IDO <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/ido">
                                                            IDO
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/idodetails"
                                                        >
                                                            IDO Details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Swap <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/swap">
                                                            Swap
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/swap-checkout"
                                                        >
                                                            Swap to share
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/apes" className="fs-ten">
                                                    ApeX
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Buy Crypto
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/buycrypto"
                                                        >
                                                            Buy Crypto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/pricing_plan"
                                                        >
                                                            Pricing plan
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Pages
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/rewards">
                                                            Rewards
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogs"
                                                        >
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogdetails"
                                                        >
                                                            Blog details
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/contact_us"
                                                        >
                                                            Contact us
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/terms_conditaions"
                                                        >
                                                            Terms &amp; condition
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/error">
                                                            Error
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                                        <div className="single-item cart-area search-area">
                                            <div className="cmn-head">
                                                <button
                                                    type="button"
                                                    aria-label="Shopping Button"
                                                    className="common_toggles2 icon-area p-0 me-3 me-lg-0 box-second d-center position-relative"
                                                >
                                                    <i className="ti ti-search slide-toggle2 fs-four p6-color" />
                                                </button>
                                                <div className="msg_area common_area2 p2-bg p-5 rounded-2">
                                                    <form className="d-flex align-items-center ">
                                                        <input type="text" />
                                                        <button type="submit" className="p-2">
                                                            <i className="ti ti-search slide-toggle2 fs-four p2-color" />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-section__modalstyle">
                                            {/* Button trigger modal */}
                                            <button
                                                type="button"
                                                className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <span className="p7-color fw-semibold d-none d-sm-block">
                                                    Connect
                                                </span>
                                                Wallet
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        aria-label="Navbar Toggler"
                                        data-bs-target="#navbar-content"
                                        aria-expanded="true"
                                        id="nav-icon3"
                                    >
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="modal_areastyle">
                {/* Modal */}
                <div
                    className="modal"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content px-2 px-md-6 pt-md-3 pb-2 pb-md-6">
                            <div className="modal-header border-0">
                                <h3 className="modal-title p1-color" id="exampleModalLabel">
                                    Connect Your Wallet
                                </h3>
                                <button
                                    type="button"
                                    className="btn-close p7-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="ti ti-xbox-x p6-color fs-three fw-normal" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between mb-5 mb-md-6"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/dog.png" alt="Icon" />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/wallet-connect.png" alt="Icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* header-section end */}
            {/* stETH-NG section starts */}
            <section className="staking_details markets_section pb-120 pt-120 bg9-color">
                <div className="container pt-6 pt-sm-10 pt-lg-0">
                    <div className="row">
                        <div className="col-xl-8 mb-10 mb-xl-0">
                            <div className="row">
                                <div className="col-12">
                                    <div className="staking_details__head d-flex align-items-center justify-content-between mb-8 mb-md-10 wow fadeInUp">
                                        <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6 flex-wrap">
                                            <div className="d-flex align-items-center">
                                                <img
                                                    src="assets/images/icon/steth-ngicon.png"
                                                    alt="Icons"
                                                />
                                                <img
                                                    className="pools_table__totalitem-img"
                                                    src="assets/images/icon/tableicon1.png"
                                                    alt="Icons"
                                                />
                                            </div>
                                            <div className="d-flex flex-column">
                                                <a href="javascript:void(0)" className="roboto fs-four">
                                                    stETH-NG
                                                </a>
                                                <span className="roboto">curve</span>
                                            </div>
                                        </div>
                                        <div className="d-flex align-items-end gap-2">
                                            <h2 className="fw_500 p1-color">3.40%</h2>
                                            <span className="fs-four fw-normal">APY</span>
                                        </div>
                                    </div>
                                    <div className="staking_details__chartsitem bg7-color rounded-20 mb-8 mb-md-10 wow fadeInUp">
                                        <div id="chart-timelinetwo"></div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-10 mb-15">
                                <div className="col-12">
                                    <div className="wow fadeInUp">
                                        <div className="staking_details__tableone p-3 p-sm-5 p-md-6 rounded-20 br1 position-relative overflow-hidden">
                                            <h4 className="mb-6 mb-md-8">Current Investment in Pool</h4>
                                            <div className="staking_details__tableone-scol overflow-auto">
                                                <div className="staking_details__tableone-part p-3 p-sm-5 p-md-6 rounded-20">
                                                    <table className="w-100">
                                                        <tbody>
                                                            <tr>
                                                                <th className="p1-color">Investment</th>
                                                                <th className="p1-color">Portfolio PnL</th>
                                                                <th className="p1-color">LP Quantity</th>
                                                                <th className="p1-color">Portfolio Value</th>
                                                                <th className="p1-color">Accumulated Unclaimed</th>
                                                            </tr>
                                                            <tr>
                                                                <td>0.00</td>
                                                                <td>0.00</td>
                                                                <td>0.00</td>
                                                                <td>0.00</td>
                                                                <td>0.00</td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row mb-10 mb-15">
                                <div className="col-12">
                                    <div className="overflow-auto wow fadeInUp">
                                        <div className="staking_details__tableone p-3 p-sm-5 p-md-6 rounded-20 br1 position-relative overflow-hidden">
                                            <h4 className="mb-6 mb-md-8">Pool Information</h4>
                                            <div className="staking_details__tableone-scol overflow-auto">
                                                <div className="staking_details__tableone-part p-3 p-sm-5 p-md-6 rounded-20 ">
                                                    <table className="w-100">
                                                        <tbody>
                                                            <tr>
                                                                <th className="p1-color">TVL</th>
                                                                <th className="p1-color">Pool Composition</th>
                                                                <th className="p1-color">Network</th>
                                                            </tr>
                                                            <tr>
                                                                <td>$ 106.45 M</td>
                                                                <td>
                                                                    <div className="d-flex align-items-center gap-15 gap-md-20 mb-3">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <img
                                                                                src="assets/images/icon/stethtwo.png"
                                                                                alt="Icon"
                                                                            />
                                                                            <span className="roboto">STETH</span>
                                                                        </div>
                                                                        <span className="roboto">17.92 K</span>
                                                                    </div>
                                                                    <div className="d-flex align-items-center gap-15 gap-md-20">
                                                                        <div className="d-flex align-items-center gap-2">
                                                                            <img
                                                                                src="assets/images/icon/stethone.png"
                                                                                alt="Icon"
                                                                            />
                                                                            <span className="roboto">STETH</span>
                                                                        </div>
                                                                        <span className="roboto">33.92 K</span>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="d-flex align-items-center gap-2">
                                                                        <img
                                                                            src="assets/images/icon/stethtwo.png"
                                                                            alt="Icon"
                                                                        />
                                                                        <span className="roboto">Ethereum</span>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="col-12">
                                    <div className="overflow-auto wow fadeInUp">
                                        <div className="staking_details__tableone p-3 p-sm-5 p-md-6 rounded-20 br1 position-relative overflow-hidden">
                                            <h4 className="mb-6 mb-md-8">Smart Contracts</h4>
                                            <div className="staking_details__tableone-scol overflow-auto">
                                                <div className="staking_details__tableone-part p-3 p-sm-5 p-md-6 rounded-20 ">
                                                    <table className="w-100">
                                                        <tbody>
                                                            <tr>
                                                                <th className="p1-color">Pool</th>
                                                                <th className="p1-color">Token</th>
                                                                <th className="p1-color">Gauge</th>
                                                            </tr>
                                                            <tr>
                                                                <td>
                                                                    <div className="copy-text">
                                                                        <input
                                                                            type="text"
                                                                            className="text w-50"
                                                                            defaultValue="0xe2...843a"
                                                                        />
                                                                        <button>
                                                                            <i className="ti ti-copy" />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="copy-text">
                                                                        <input
                                                                            type="text"
                                                                            className="text w-50"
                                                                            defaultValue="0xe2...843a"
                                                                        />
                                                                        <button>
                                                                            <i className="ti ti-copy" />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                                <td>
                                                                    <div className="copy-text">
                                                                        <input
                                                                            type="text"
                                                                            className="text w-50"
                                                                            defaultValue="0xe2...843a"
                                                                        />
                                                                        <button>
                                                                            <i className="ti ti-copy" />
                                                                        </button>
                                                                    </div>
                                                                </td>
                                                            </tr>
                                                        </tbody>
                                                    </table>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-4">
                            <div className="staking_details__aside py-6 py-md-8 px-5 px-md-6 bg7-color br2 rounded-20 wow fadeInUp">
                                <div className="staking_details__aside-title d-flex align-items-center justify-content-between mb-5 mb-md-6">
                                    <span className="p1-color fs-four">Invest</span>
                                    <span className="fs-four">Redeem</span>
                                </div>
                                <div className="apex_section__slider-selector markets_section__rcard-selector mb-6 w-50">
                                    <div className="f-group">
                                        <select id="select5" className="f-control f-dropdown">
                                            <option
                                                value={1}
                                                selected=""
                                                data-image="assets/images/icon/tableicon11.png"
                                            >
                                                Bitcoin (BTC)
                                            </option>
                                            <option
                                                value={2}
                                                data-image="assets/images/icon/currencyCategory5333a.png"
                                            >
                                                ETH
                                            </option>
                                            <option
                                                value={3}
                                                data-image="assets/images/icon/currencyCategory6bd4a.png"
                                            >
                                                LFC
                                            </option>
                                            <option
                                                value={4}
                                                data-image="assets/images/icon/currencyCategory71949.png"
                                            >
                                                USDT
                                            </option>
                                        </select>
                                    </div>
                                </div>
                                <div className="markets_section__rcard-card p-4 p-sm-5 p-md-6 bg1-color rounded-20 mb-4">
                                    <div className="d-flex align-items-center gap-3 justify-content-between mb-3">
                                        <span className="roboto">Pay</span>
                                        <div className="d-flex align-items-center gap-2">
                                            <i className="ti ti-wallet fs-five" />
                                            <span className="opacity-50">---</span>
                                            <span>ETH</span>
                                        </div>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-3">
                                        <div className="text-end">
                                            <div className="apex_section__slider-selector markets_section__rcard-selector">
                                                <div className="f-group">
                                                    <select id="select3" className="f-control f-dropdown">
                                                        <option
                                                            value={1}
                                                            selected=""
                                                            data-image="assets/images/icon/cryptocurrency56304.png"
                                                        >
                                                            USDT
                                                        </option>
                                                        <option
                                                            value={2}
                                                            data-image="assets/images/icon/currencyCategory5333a.png"
                                                        >
                                                            ETH
                                                        </option>
                                                        <option
                                                            value={3}
                                                            data-image="assets/images/icon/currencyCategory6bd4a.png"
                                                        >
                                                            LFC
                                                        </option>
                                                        <option
                                                            value={4}
                                                            data-image="assets/images/icon/currencyCategory71949.png"
                                                        >
                                                            TLP
                                                        </option>
                                                    </select>
                                                </div>
                                            </div>
                                        </div>
                                        <span className="fs-four opacity-50">0.00</span>
                                    </div>
                                </div>
                                <div className="staking_details__aside-inputarea d-flex align-items-center justify-content-between gap-3 py-3 px-3 px-md-4 rounded-3 br2">
                                    <span>Estimated Cost</span>
                                    <span>---</span>
                                </div>
                                <div className="staking_details__aside-dropdown text-center">
                                    <button id="asidecard" className="cmn-btn px-2 py-1 rounded-5">
                                        <i className="ti ti-chevron-down fs-five" />
                                    </button>
                                    <div
                                        id="asidecardpanel"
                                        className="staking_details__aside-dropdownitem"
                                    >
                                        <div className="staking_details__aside-inputarea d-flex align-items-center justify-content-between gap-3 py-3 px-3 px-md-4 rounded-3 br2 mb-4">
                                            <span>Estimated Daily Yield</span>
                                            <span>---</span>
                                        </div>
                                        <div className="staking_details__aside-inputarea d-flex align-items-center justify-content-between gap-3 py-3 px-3 px-md-4 rounded-3 br2 mb-5 mb-md-6">
                                            <span>Estimated Cost</span>
                                            <span>---</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="staking_details__aside-inputarea d-flex align-items-center justify-content-between gap-3 py-3 px-3 px-md-4 rounded-3 br2 mb-4">
                                    <span>Estimated Daily Yield</span>
                                    <span>---</span>
                                </div>
                                <div className="d-flex align-items-center gap-2 mb-5 mb-md-6">
                                    <input
                                        type="checkbox"
                                        id="order"
                                        name="order"
                                        defaultValue="order"
                                    />
                                    <label htmlFor="order">Stake Protect Order</label>
                                </div>
                                <button className="cmn-btn py-3 px-6 w-100">Log In</button>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* stETH-NG section ends */}
            {/* Footer Section Starts */}
            <footer className="footer pt-120 bg5-color">
                <div className="container">
                    <div className="row gy-8 pb-120 justify-content-between">
                        <div className="col-md-12 col-lg-6 col-xxl-5">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <h2>Coinx Wallet</h2>
                                </a>
                                <p className="fs-ten mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp">
                                    Welcome to Coinx, your gateway to the world of Web3 trading! Our
                                    user-friendly platform empowers you to explore a wide range of
                                    popular cryptocurrencies
                                </p>
                                <div className="footer__decs-subscribe mb-9 mb-md-12 wow fadeInUp">
                                    <form className="d-flex align-items-center rounded-1 py-2 pe-2">
                                        <input type="email" placeholder="Enter your email" />
                                        <button className="cmn-btn py-3  px-8 rounded-1 d-center gap-2">
                                            Subscribe
                                            <i className="ti ti-send fs-five d-none d-md-block" />
                                        </button>
                                    </form>
                                </div>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-facebook-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-linkedin p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-instagram p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2 ms-xxl-20">
                            <div className="footer__discover">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Company</h4>
                                <div className="footer__discover-nav">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                About Coinx Wallet
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Join Us
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Blog
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Academy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Security</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Contact Validator
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Protection fund
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Security
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Assets</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                BNB Chain
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Ethereum
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                ERC20
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-md-start">
                                        Copyright 2024 -
                                        <a href="/" className="p4-color">
                                            ConiX
                                        </a>
                                        All Rights Reserved{" "}
                                        <span className="p4-color fs-five mx-2">|</span> Design By
                                        <a
                                            href="https://themeforest.net/user/uiaxis"
                                            className="p4-color roboto"
                                        >
                                            {" "}
                                            UIAXIS
                                        </a>
                                    </span>
                                </div>
                                <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="p4-color roboto ps-4 ps-sm-6"
                                    >
                                        Privacy Policy
                                    </a>
                                    <span className="p4-color fs-five">|</span>
                                    <a href="javascript:void(0)" className="p4-color roboto">
                                        Cookie Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section Ends */}
        </>

    )
}

export default Staking_details