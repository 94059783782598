import React, { useEffect, useState } from 'react';
import UserHeader from '../../component/userHeader';
import Auth from '../../component/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

function Direct() {
    const [downlineData, setDownlineData] = useState([]);
    const [levelData, setLevelData] = useState([]);
    const [levels, setLevel] = useState();
    const [activeTab, setActiveTab] = useState('direct'); // State to manage the active tab
    const [selectedLevel, setSelectedLevel] = useState(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const loginToken = localStorage.getItem('userToken');

    // Fetch direct team data on component mount
    useEffect(() => {
        directTeam();
    }, []);

    const directTeam = async () => {
        try {
            const responseDirect = await fetch(`${baseApiUrl}/user/directTeam`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginToken}`,
                },
            });
            const resultDirect = await responseDirect.json();
            console.log('Direct Team', resultDirect);
            if (resultDirect.status) {
                setDownlineData(resultDirect.downline);
                setLevel(resultDirect.levelCounts);
                setLevelData(resultDirect.allDownline);
            } else {
               // toast.error("Failed to fetch downline data");
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error("Error fetching downline data");
        }
    };

    const levelHistory = (level) => {
        setSelectedLevel(level);
        setIsModalOpen(true);
    };

    const closeModal = () => {
        setIsModalOpen(false);
        setSelectedLevel(null);
    };

    return (
        <>
            <UserHeader />
            <ToastContainer />
            <Auth />
            
            <section className="counter_section pb-120 pt-120 bg5-color">
                <div className="mt-20 bm-20"></div>
                <div className="container">
                    <div className="row">
                        <div className="d-flex align-items-center justify-content-center justify-content-sm-evenly flex-wrap gap-5 br2 p-3 p-sm-6 p-md-8 rounded-20 bg1-color mb-10 mb-md-15">
                            <div className="cyber_arena__item text-center">
                                <div className="cyber_arena__countdown counters d-flex align-items-center justify-content-center">
                                    <span className="fs-three fw_500">{localStorage.getItem('directTeam')}</span>
                                </div>
                                <span className="text-center fs-ten">Referrals</span>
                            </div>
                            <span className="v-line lgx mb-20 pb-6 d-none d-md-block" />
                            <div className="cyber_arena__item">
                                <div className="cyber_arena__countdown counters d-flex align-items-center justify-content-center">
                                    <span className="fs-three fw_500">{localStorage.getItem('totalTeam')}</span>
                                </div>
                                <span className="text-center fs-ten">Community</span>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Direct Team Section */}
            <section className="pools_table pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="pools_table__title mb-5 mb-md-6">
                            <h2 className="mb-5 mb-md-6 wow fadeInUp">My Community</h2>
                            <p className="roboto wow fadeInUp">
                                Here is the list of all direct referral members and Community!
                            </p>
                        </div>
                        <div className="pools_table__part">
                            <div className="singletab">
                                <div className="text-center mb-10">
                                    {/* Toggle buttons for Direct and Team */}
                                    <button
                                        className={`cmn-btn py-3 px-5 px-md-6 ${activeTab === 'direct' ? 'active' : ''}`}
                                        style={{ marginRight: "10px" }}
                                        onClick={() => setActiveTab('direct')}
                                    >
                                        DIRECT
                                    </button>
                                    <button
                                        className={`cmn-btn py-3 px-5 px-md-6 ${activeTab === 'team' ? 'active' : ''}`}
                                        onClick={() => setActiveTab('team')}
                                    >
                                        COMMUNITY
                                    </button>
                                </div>
                                <div className="tabcontents">
                                    {/* Direct downline table */}
                                    {activeTab === 'direct' && (
                                        <div className="tabitem active wow fadeInUp">
                                            <div className="cyber_arena__table wow fadeInUp">
                                                <table className="table">
                                                    <thead>
                                                        <tr className="rounded-20">
                                                            <th>Unique ID</th>
                                                            <th>Email</th>
                                                            <th>Join Date</th>
                                                            <th>Status</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {downlineData.map((member, index) => (
                                                            <tr key={index}>
                                                                <td>{member.user_id}</td>
                                                                <td>{member.email}</td>
                                                                <td>{new Date(member.created_at).toLocaleDateString()}</td>
                                                                <td className="p1-color fs-ten">{member.packageStatus}</td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}

                                    {/* Levels table */}
                                    {activeTab === 'team' && (
                                        <div className="tabitem active wow fadeInUp">
                                            <div className="cyber_arena__table wow fadeInUp">
                                                <table className="table">
                                                    <thead>
                                                        <tr className="rounded-20">
                                                            <th>Level</th>
                                                            <th>Community</th>
                                                            <th>Action</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {Array.from({ length: 10 }, (_, i) => (
                                                            <tr key={i + 1}> 
                                                                <td>{i + 1}</td>
                                                                <td>{levels[i + 1] || '0'}</td>
                                                                <td>
                                                                    <button onClick={() => levelHistory(i + 1)} className="btn btn-primary btn-sm">View</button>
                                                                </td>
                                                            </tr>
                                                        ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                                    )}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {/* Modal for Level History */}
            {isModalOpen && (
                <div className="modal fade show" style={{ display: 'block' }}>
                    <div className="modal-dialog ">
                        <div className="modal-content bg-black">
                            <div className="modal-header">
                                <h5 className="modal-title">Level {selectedLevel} History</h5>
                                <button type="button" className="close" onClick={closeModal}>&times;</button>
                            </div>
                            <div className="modal-body">
                                <div className="tabitem active wow fadeInUp">
                                            <div className="cyber_arena__table wow fadeInUp">
                                                <table className="table">
                                                    <thead>
                                                        <tr className="rounded-20">
                                                            <th>Unique Coe</th>
                                                            <th>Join Date</th>
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                    {levelData.map((member, index) => (
                                                        <tr key={index}>
                                                            {member.level === selectedLevel && (
                                                                <>
                                                                    <td>{member.user_id}</td>
                                                                    <td>{member.date_time}</td>
                                                                </>
                                                            )}
                                                        </tr>
                                                    ))}
                                                    </tbody>
                                                </table>
                                            </div>
                                        </div>
                            </div>
                            
                            <div className="modal-footer">
                                <button type="button" className="btn btn-secondary" onClick={closeModal}>Close</button>
                            </div>
                        </div>
                    </div>
                </div>
            )}
        </>
    );
}

export default Direct;
