import React from 'react'

function Apes() {
    return (
        <>
            {/* Scroll To Top Start*/}
            <button
                className="scrollToTop d-none d-md-flex d-center"
                aria-label="scroll Bar Button"
            >
                <i className="ti ti-chevron-up fs-four p6-color" />
            </button>
            {/* Scroll To Top End */}
            {/* header-section start */}
            <div className="navbar_top bg2-color py-4 d-none d-lg-block">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 col-xxl-5">
                            <div className="navbar_top__left d-flex align-items-center gap-2 gap-xl-6">
                                <div className="navbar_top__location d-flex align-items-center gap-1 gap-xl-3">
                                    <i className="ti ti-map-pin-filled fs-four p7-color" />
                                    <span className="roboto p7-color">
                                        102/B New Market, Sandigo-USA
                                    </span>
                                </div>
                                <span className="v-line mb-9" />
                                <a
                                    href="https://softivus.com/cdn-cgi/l/email-protection#4a2f322b273a262f0a2d272b232664292527"
                                    className="navbar_top__email roboto p7-color d-flex align-items-center gap-3"
                                >
                                    <i className="ti ti-mail-opened-filled fs-four" />
                                    <span
                                        className="__cf_email__"
                                        data-cfemail="5b3e233a362b373e1b3c363a323775383436"
                                    >
                                        [email&nbsp;protected]
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xxl-6">
                            <div className="navbar_top__right d-flex align-items-center justify-content-end gap-2 gap-xl-6">
                                <div className="navbar_top__call d-flex align-items-center gap-3">
                                    <span className="bg6-color py-2 px-3 rounded-item">
                                        <i className="ti ti-phone-call fs-four p7-color " />
                                    </span>
                                    <div>
                                        <span className="p7-color fw-bolder d-block">Contact Us:</span>
                                        <a href="tel:+9802344567" className="d-block p7-color">
                                            +980 234 4567
                                        </a>
                                    </div>
                                </div>
                                <span className="v-line mb-9" />
                                <div className="navbar_top__social d-flex align-items-center gap-2 gap-xl-3">
                                    <span className="p7-color fw-bolder">Follow Us:</span>
                                    <div className="navbar_top__social-icon d-flex align-items-center  gap-1 gap-xl-2">
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-facebook fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-instagram fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-twitter fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-linkedin fs-four p7-color " />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
                <div className="navbar_mainhead header-fixed w-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 workready">
                                    <a
                                        href="/"
                                        className="navbar-brand d-flex align-items-center gap-2"
                                    >
                                        <img src="assets/images/logo.png" className="logo" alt="logo" />
                                    </a>
                                    <div
                                        className="collapse navbar-collapse justify-content-between"
                                        id="navbar-content"
                                    >
                                        <ul className="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                                            <li className="dropdown">
                                                <a href="/" className="fs-ten">
                                                    Home
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Staking
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/staking">
                                                            Staking Pool
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/staking_details"
                                                        >
                                                            Pool details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    IDO <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/ido">
                                                            IDO
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/idodetails"
                                                        >
                                                            IDO Details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Swap
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/swap">
                                                            Swap
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/swap_checkout"
                                                        >
                                                            Swap to share
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/apes" className="fs-ten">
                                                    ApeX
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Buy Crypto
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/buycrypto"
                                                        >
                                                            Buy Crypto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/pricing_plan"
                                                        >
                                                            Pricing plan
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Pages
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/rewards">
                                                            Rewards
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogs"
                                                        >
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogdetails"
                                                        >
                                                            Blog details
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/contact_us"
                                                        >
                                                            Contact us
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/terms_conditions"
                                                        >
                                                            Terms &amp; condition
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/error">
                                                            Error
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                                        <div className="single-item cart-area search-area">
                                            <div className="cmn-head">
                                                <button
                                                    type="button"
                                                    aria-label="Shopping Button"
                                                    className="common_toggles2 icon-area p-0 me-3 me-lg-0 box-second d-center position-relative"
                                                >
                                                    <i className="ti ti-search slide-toggle2 fs-four p6-color" />
                                                </button>
                                                <div className="msg_area common_area2 p2-bg p-5 rounded-2">
                                                    <form className="d-flex align-items-center ">
                                                        <input type="text" />
                                                        <button type="submit" className="p-2">
                                                            <i className="ti ti-search slide-toggle2 fs-four p2-color" />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-section__modalstyle">
                                            {/* Button trigger modal */}
                                            <button
                                                type="button"
                                                className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <span className="p7-color fw-semibold d-none d-sm-block">
                                                    Connect
                                                </span>
                                                Wallet
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        aria-label="Navbar Toggler"
                                        data-bs-target="#navbar-content"
                                        aria-expanded="true"
                                        id="nav-icon3"
                                    >
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="modal_areastyle">
                {/* Modal */}
                <div
                    className="modal"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content px-2 px-md-6 pt-md-3 pb-2 pb-md-6">
                            <div className="modal-header border-0">
                                <h3 className="modal-title p1-color" id="exampleModalLabel">
                                    Connect Your Wallet
                                </h3>
                                <button
                                    type="button"
                                    className="btn-close p7-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="ti ti-xbox-x p6-color fs-three fw-normal" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between mb-5 mb-md-6"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/dog.png" alt="Icon" />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/wallet-connect.png" alt="Icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* header-section end */}
            {/* Apex Section Starts */}
            <section className="apex_section pt-120 pb-120 bg7-color">
                <div className="container-fluid pt-17 pt-sm-20 pt-lg-0">
                    <div className="row gap-6 gap-xxl-0 mb-6 mb-md-8">
                        <div className="col-xxl-7">
                            <div className="row justify-content-center gap-5 gap-xxl-15">
                                <div className="col-xxl-3">
                                    <div className="apex_section__slider d-inline-flex align-items-center gap-3">
                                        <div className="apex_section__slider-selector">
                                            <div className="f-group">
                                                <select id="select2" className="f-control f-dropdown">
                                                    <option
                                                        value={1}
                                                        selected=""
                                                        data-image="assets/images/icon/cryptocurrency56304.png"
                                                    >
                                                        Ethereum
                                                    </option>
                                                    <option
                                                        value={2}
                                                        data-image="assets/images/icon/currencyCategory5333a.png"
                                                    >
                                                        ETH
                                                    </option>
                                                    <option
                                                        value={3}
                                                        data-image="assets/images/icon/currencyCategory6bd4a.png"
                                                    >
                                                        LFC
                                                    </option>
                                                    <option
                                                        value={4}
                                                        data-image="assets/images/icon/currencyCategory71949.png"
                                                    >
                                                        TLP
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                        <span className="v-line smx mb-4" />
                                        <div className="apex_section__slider-total">
                                            <h5>$40,108.8</h5>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-xxl-8 apex_section__respon">
                                    <div className="apex_section__slideritem position-relative">
                                        <div className="apex_section_sliders_btn position-absolute w-100 start-0">
                                            <div className="slider-btn d-flex align-items-center justify-content-between gap-4 me-7 me-xxl-0">
                                                <button
                                                    type="button"
                                                    aria-label="Slide Prev"
                                                    className="ara-prev slide-buttonpart box_styleone"
                                                >
                                                    <i className="ti ti-chevron-left fs-four p6-color" />
                                                </button>
                                                <button
                                                    type="button"
                                                    aria-label="Slide Next"
                                                    className="ara-next box_styletwo"
                                                >
                                                    <i className="ti ti-chevron-right fs-four p6-color" />
                                                </button>
                                            </div>
                                        </div>
                                        <div className="swiper apex_section_sliders ms-sm-8 me-3 me-sm-8 me-xxl-0">
                                            <div className="swiper-wrapper">
                                                <div className="swiper-slide">
                                                    <div className="items-wrapper">
                                                        <span className="apex_section__slideritem-text mb-1 d-block fs-seven">
                                                            Index Price
                                                        </span>
                                                        <span className="fs-ten">$40,108.8</span>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="items-wrapper">
                                                        <span className="apex_section__slideritem-text mb-1 d-block fs-seven">
                                                            Oracle Price
                                                        </span>
                                                        <span className="fs-ten">$35,563.1</span>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="items-wrapper">
                                                        <span className="apex_section__slideritem-text mb-1 d-block fs-seven">
                                                            24H Change
                                                        </span>
                                                        <span className="fs-ten h24">-2.71%</span>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="items-wrapper">
                                                        <span className="apex_section__slideritem-text mb-1 d-block fs-seven">
                                                            24H Low
                                                        </span>
                                                        <span className="fs-ten">$34,823.5</span>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="items-wrapper">
                                                        <span className="apex_section__slideritem-text mb-1 d-block fs-seven">
                                                            24H Low
                                                        </span>
                                                        <span className="fs-ten">$34,823.5</span>
                                                    </div>
                                                </div>
                                                <div className="swiper-slide">
                                                    <div className="items-wrapper">
                                                        <span className="apex_section__slideritem-text mb-1 d-block fs-seven">
                                                            Funding Rate
                                                        </span>
                                                        <span className="fs-ten p1-color">+0.001250%</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-5">
                            <div className="row justify-content-xxl-between gap-5">
                                <div className="col-md-6 col-xxl-8">
                                    <div className="apex_section__groupbtn d-flex align-items-center gap-2 gap-md-4 ms-sm-4">
                                        <button className="cmn-btn fourth-alt py-3 px-5 px-md-6 d-flex align-items-center gap-1">
                                            <i className="ti ti-brand-binance" />
                                            Dashboards
                                        </button>
                                        <button className="cmn-btn py-3 px-5 px-md-6 d-flex align-items-center gap-1">
                                            <i className="ti ti-brand-binance" />
                                            Apex x ARB
                                        </button>
                                    </div>
                                </div>
                                <div className="col-md-5 col-xxl-3">
                                    <div className="apex_section__settingchat d-flex align-items-center justify-content-end gap-2 ">
                                        <button className="p-1">
                                            <i className="ti ti-settings fs-four" />
                                        </button>
                                        <button className="p-1">
                                            <i className="ti ti-message-dots fs-four" />
                                        </button>
                                        <button className="px-1 br2 rounded-item">
                                            <i className="ti ti-question-mark fs-four" />
                                        </button>
                                        <a href="/" className="fw-bold p-1">
                                            CoinX
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row gy-6">
                        <div className="col-xxl-9">
                            <div className="row gy-5 gy-md-6">
                                <div className="col-lg-7 col-xxl-8">
                                    <div className="apex_section__chartarea br2 rounded-20 bg1-color overflow-hidden h-100">
                                        <div className="apex_section__chartheader p-5 p-md-6 d-flex align-items-center gap-3 gap-sm-5 gap-md-6 flex-wrap">
                                            <a href="javascript:void(0)">Price</a>
                                            <a href="javascript:void(0)">Depth</a>
                                            <a href="javascript:void(0)">Funding Rate</a>
                                            <a href="javascript:void(0)">Details</a>
                                            <a href="javascript:void(0)">Risk Limit</a>
                                        </div>
                                        <div className="apex_section__chartitem p-5 p-md-6">
                                            <div className="apex_section__chartitem-min d-flex align-items-center gap-3 gap-sm-5 gap-md-6 mb-5 mb-md-6 flex-wrap">
                                                <span>1m</span>
                                                <span>5m</span>
                                                <span>15m</span>
                                                <span>30m</span>
                                                <span>1h</span>
                                                <span>4h</span>
                                                <span>D</span>
                                                <span>W</span>
                                                <span>M</span>
                                                <span>Y</span>
                                                <span>|</span>
                                                <span>M</span>
                                                <span>|</span>
                                                <span>M</span>
                                                <span>|</span>
                                                <span>M</span>
                                            </div>
                                            <div className="apex_section__chartitem-title d-flex align-items-center gap-2">
                                                <p>036989.5 H37120.5 L36973,5 C37082.0 +92.5 (+0.25%)</p>
                                                <span className="p1-color fw_500 cpoint">$223K</span>
                                            </div>
                                            <div id="bigchart" />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-lg-5 col-xxl-4">
                                    <div className="apex_section__spcialx overflow-auto">
                                        <div className="custom-singletab apex_section__parttable br2 rounded-20 bg1-color overflow-hidden h-100 position-relative">
                                            <ul className="apex_section__heads apex_section__chartheader custom-tabs d-flex align-items-center gap-4 p-4 p-md-6">
                                                <li className="custom-nav-links ">
                                                    <button className="tablinktwo activetwo clickable-active active">
                                                        Order Book
                                                    </button>
                                                </li>
                                                <li className="custom-nav-links">
                                                    <button className="tablinktwo clickable-active">
                                                        Recent Trades
                                                    </button>
                                                </li>
                                            </ul>
                                            <div className="tabcontentstwo px-5 px-md-6 py-5 py-md-6">
                                                <div className="apex_section__orderbook custom-tabitem active">
                                                    <div className="apex_section__tabletop mb-5 mb-md-6">
                                                        <div className="apex_section__tabletop-up d-flex align-items-center justify-content-between">
                                                            {/* <div class="d-flex align-items-center gap-2">
                                                  <img class="cpoint" src="assets/images/icon/cryptocurrencytab.png"
                                                      alt="Icon">
                                                  <img class="cpoint" src="assets/images/icon/cryptocurrencytab.png"
                                                      alt="Icon">
                                                  <img class="cpoint" src="assets/images/icon/cryptocurrencytab.png"
                                                      alt="Icon">
                                              </div> */}
                                                            <div className="singletab w-100 ">
                                                                <div className="d-flex align-items-center justify-content-between gap-2 w-100">
                                                                    <ul className="tablinks d-flex gap-1 mb-5">
                                                                        <li className="nav-links">
                                                                            <button className="tablink active">
                                                                                <img
                                                                                    className="cpoint"
                                                                                    src="assets/images/icon/taboneimag1.png"
                                                                                    alt="Icon"
                                                                                />
                                                                            </button>
                                                                        </li>
                                                                        <li className="nav-links">
                                                                            <button className="tablink">
                                                                                <img
                                                                                    className="cpoint"
                                                                                    src="assets/images/icon/taboneimag2.png"
                                                                                    alt="Icon"
                                                                                />
                                                                            </button>
                                                                        </li>
                                                                        <li className="nav-links">
                                                                            <button className="tablink">
                                                                                <img
                                                                                    className="cpoint"
                                                                                    src="assets/images/icon/taboneimag3.png"
                                                                                    alt="Icon"
                                                                                />
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                    <select
                                                                        className="apex_section__selects bg1-color p6-color py-2 rounded-2 mb-2"
                                                                        name="state"
                                                                    >
                                                                        <option>05</option>
                                                                        <option>06</option>
                                                                        <option>07</option>
                                                                        <option>08</option>
                                                                        <option>09</option>
                                                                    </select>
                                                                </div>
                                                                <div className="tabcontents">
                                                                    <div className="tabitem active">
                                                                        <div className="apex_section__orderbook-table">
                                                                            <table className="w-100">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>Price(USD)</th>
                                                                                        <th className="text-end">
                                                                                            Amount(BTC)
                                                                                        </th>
                                                                                        <th className="text-end">Total(BTC)</th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p6-color">37,163.0</td>
                                                                                        <td className="text-end">13.920</td>
                                                                                        <td className="text-end">45.769</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p6-color">37,162,5</td>
                                                                                        <td className="text-end">7.624</td>
                                                                                        <td className="text-end">31.849</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p6-color">37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="text-end">24.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p6-color">37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="text-end">24.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p6-color">37,128.5</td>
                                                                                        <td className="text-end">7.912</td>
                                                                                        <td className="text-end">93.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color fs-ten">
                                                                                            36,828.5{" "}
                                                                                            <i className="ti ti-arrow-narrow-up fs-five" />
                                                                                        </td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end fs-ten">
                                                                                            <i className="ti ti-flag-filled fs-five" />
                                                                                            61.225
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tabitem">
                                                                        <div className="apex_section__orderbook-table">
                                                                            <table className="w-100">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>Price(USD)</th>
                                                                                        <th className="text-end">
                                                                                            Amount(BTC)
                                                                                        </th>
                                                                                        <th className="text-end">Total(BTC)</th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color fs-ten">
                                                                                            36,828.5{" "}
                                                                                            <i className="ti ti-arrow-narrow-up fs-five" />
                                                                                        </td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="p1-color text-end fs-ten">
                                                                                            <i className="ti ti-flag-filled fs-five" />
                                                                                            61.225
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">37,163.0</td>
                                                                                        <td className="text-end">13.920</td>
                                                                                        <td className="p1-color text-end">
                                                                                            45.769
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">37,162,5</td>
                                                                                        <td className="text-end">7.624</td>
                                                                                        <td className="p1-color text-end">
                                                                                            31.849
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            24.225
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            24.225
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">37,128.5</td>
                                                                                        <td className="text-end">7.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            93.225
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tabitem">
                                                                        <div className="apex_section__orderbook-table">
                                                                            <table className="w-100">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>Price(USD)</th>
                                                                                        <th className="text-end">
                                                                                            Amount(BTC)
                                                                                        </th>
                                                                                        <th className="text-end">Total(BTC)</th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>37,163.0</td>
                                                                                        <td className="text-end">13.920</td>
                                                                                        <td className="text-end">45.769</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>37,162,5</td>
                                                                                        <td className="text-end">7.624</td>
                                                                                        <td className="text-end">31.849</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="text-end">24.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="text-end">24.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>37,128.5</td>
                                                                                        <td className="text-end">7.912</td>
                                                                                        <td className="text-end">93.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="text-end">36,828.5</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="text-end">36,828.5</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="text-end">36,828.5</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="text-end">36,828.5</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="text-end">36,828.5</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="apex_section__orderbook custom-tabitem">
                                                    <div className="apex_section__tabletop mb-5 mb-md-6">
                                                        <div className="apex_section__tabletop-up d-flex align-items-center justify-content-between">
                                                            <div className="singletab w-100 ">
                                                                <div className="d-flex align-items-center justify-content-between gap-2 w-100">
                                                                    <ul className="tablinks d-flex gap-1 mb-5">
                                                                        <li className="nav-links">
                                                                            <button className="tablink active">
                                                                                <img
                                                                                    className="cpoint"
                                                                                    src="assets/images/icon/taboneimag1.png"
                                                                                    alt="Icon"
                                                                                />
                                                                            </button>
                                                                        </li>
                                                                        <li className="nav-links">
                                                                            <button className="tablink">
                                                                                <img
                                                                                    className="cpoint"
                                                                                    src="assets/images/icon/taboneimag2.png"
                                                                                    alt="Icon"
                                                                                />
                                                                            </button>
                                                                        </li>
                                                                        <li className="nav-links">
                                                                            <button className="tablink">
                                                                                <img
                                                                                    className="cpoint"
                                                                                    src="assets/images/icon/taboneimag3.png"
                                                                                    alt="Icon"
                                                                                />
                                                                            </button>
                                                                        </li>
                                                                    </ul>
                                                                    <select
                                                                        className="apex_section__selects bg1-color p6-color py-2 rounded-2 mb-2"
                                                                        name="state"
                                                                    >
                                                                        <option>05</option>
                                                                        <option>06</option>
                                                                        <option>07</option>
                                                                        <option>08</option>
                                                                        <option>09</option>
                                                                    </select>
                                                                </div>
                                                                <div className="tabcontents">
                                                                    <div className="tabitem ">
                                                                        <div className="apex_section__orderbook-table">
                                                                            <table className="w-100">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>Price(USD)</th>
                                                                                        <th className="text-end">
                                                                                            Amount(BTC)
                                                                                        </th>
                                                                                        <th className="text-end">Total(BTC)</th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p6-color">37,163.0</td>
                                                                                        <td className="text-end">13.920</td>
                                                                                        <td className="text-end">45.769</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p6-color">37,162,5</td>
                                                                                        <td className="text-end">7.624</td>
                                                                                        <td className="text-end">31.849</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p6-color">37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="text-end">24.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p6-color">37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="text-end">24.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p6-color">37,128.5</td>
                                                                                        <td className="text-end">7.912</td>
                                                                                        <td className="text-end">93.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color fs-ten">
                                                                                            36,828.5{" "}
                                                                                            <i className="ti ti-arrow-narrow-up fs-five" />
                                                                                        </td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end fs-ten">
                                                                                            <i className="ti ti-flag-filled fs-five" />
                                                                                            61.225
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tabitem active">
                                                                        <div className="apex_section__orderbook-table">
                                                                            <table className="w-100">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>Price(USD)</th>
                                                                                        <th className="text-end">
                                                                                            Amount(BTC)
                                                                                        </th>
                                                                                        <th className="text-end">Total(BTC)</th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color fs-ten">
                                                                                            36,828.5{" "}
                                                                                            <i className="ti ti-arrow-narrow-up fs-five" />
                                                                                        </td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="p1-color text-end fs-ten">
                                                                                            <i className="ti ti-flag-filled fs-five" />
                                                                                            61.225
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">37,163.0</td>
                                                                                        <td className="text-end">13.920</td>
                                                                                        <td className="p1-color text-end">
                                                                                            45.769
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">37,162,5</td>
                                                                                        <td className="text-end">7.624</td>
                                                                                        <td className="p1-color text-end">
                                                                                            31.849
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            24.225
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            24.225
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">37,128.5</td>
                                                                                        <td className="text-end">7.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            93.225
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td className="p1-color">32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="p1-color text-end">
                                                                                            36,828.5
                                                                                        </td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                    <div className="tabitem">
                                                                        <div className="apex_section__orderbook-table">
                                                                            <table className="w-100">
                                                                                <tbody>
                                                                                    <tr>
                                                                                        <th>Price(USD)</th>
                                                                                        <th className="text-end">
                                                                                            Amount(BTC)
                                                                                        </th>
                                                                                        <th className="text-end">Total(BTC)</th>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>37,163.0</td>
                                                                                        <td className="text-end">13.920</td>
                                                                                        <td className="text-end">45.769</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>37,162,5</td>
                                                                                        <td className="text-end">7.624</td>
                                                                                        <td className="text-end">31.849</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="text-end">24.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>37,152.5</td>
                                                                                        <td className="text-end">3.912</td>
                                                                                        <td className="text-end">24.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>37,128.5</td>
                                                                                        <td className="text-end">7.912</td>
                                                                                        <td className="text-end">93.225</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="text-end">36,828.5</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="text-end">36,828.5</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="text-end">36,828.5</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="text-end">36,828.5</td>
                                                                                    </tr>
                                                                                    <tr>
                                                                                        <td>32,118.5</td>
                                                                                        <td className="text-end">6.912</td>
                                                                                        <td className="text-end">36,828.5</td>
                                                                                    </tr>
                                                                                </tbody>
                                                                            </table>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12">
                                    <div className="apex_section__tablearea br2 rounded-20 bg1-color overflow-auto mt-5 mt-md-6">
                                        <div className="apex_section__tablearea-header p-5 p-md-6 d-flex gap-4 align-items-center justify-content-between">
                                            <div className="apex_section__tablearea-hitem d-flex align-items-center gap-2 gap-sm-5 gap-md-6">
                                                <a href="javascript:void(0)">Position</a>
                                                <a href="javascript:void(0)">Closed P&amp;L</a>
                                                <a href="javascript:void(0)">Active</a>
                                                <a href="javascript:void(0)">Conditional </a>
                                                <a href="javascript:void(0)">Fillod</a>
                                                <a href="javascript:void(0)">Order</a>
                                                <a href="javascript:void(0)">History</a>
                                            </div>
                                            <div className="apex_section__tablearea-hitemtwo d-flex align-items-center justify-content-end gap-2">
                                                <i className="ti ti-arrow-bear-right-2 p-1 bg2-color p4-color" />
                                                <a href="javascript:void(0)">Show All Position</a>
                                            </div>
                                        </div>
                                        <div className="apex_section__tablechart">
                                            <table>
                                                <tbody>
                                                    <tr className="text-center">
                                                        <th className="fs-ten">Market</th>
                                                        <th className="fs-ten">Qty</th>
                                                        <th className="fs-ten">Value</th>
                                                        <th className="fs-ten">Entry Price</th>
                                                        <th className="fs-ten">Oracle Price</th>
                                                        <th className="fs-ten">Liq. Price</th>
                                                        <th className="fs-ten">TP/SL</th>
                                                        <th className="fs-ten">Close By</th>
                                                    </tr>
                                                    <tr className="text-center tablehover">
                                                        <td>3.15%</td>
                                                        <td>0.027</td>
                                                        <td>$810.8010</td>
                                                        <td>$30,016.6</td>
                                                        <td>$30,017.7</td>
                                                        <td>5532565</td>
                                                        <td>
                                                            <button className="roboto addbtn fw-bold px-3  px-md-4 py-1 rounded-1">
                                                                <i className="ti ti-plus" />
                                                                Add
                                                            </button>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center gap-3">
                                                            <button className="roboto fw-bold px-3 px-md-4 py-1 rounded-1 addbtn">
                                                                Limit
                                                            </button>
                                                            <button className="roboto fw-bold px-3 py-1 rounded-1 bg2-color p4-color">
                                                                Market
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="text-center tablehover">
                                                        <td>3.15%</td>
                                                        <td>0.03</td>
                                                        <td>$42.7980</td>
                                                        <td>$1426.70</td>
                                                        <td>$1.426.70</td>
                                                        <td>$0.05</td>
                                                        <td>
                                                            <button className="roboto addbtn fw-bold px-3 px-md-4 py-1 rounded-1">
                                                                <i className="ti ti-plus" />
                                                                Add
                                                            </button>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center gap-3">
                                                            <button className="roboto fw-bold px-3 px-md-4 py-1 rounded-1 addbtn">
                                                                Limit
                                                            </button>
                                                            <button className="roboto fw-bold px-3 py-1 rounded-1 bg2-color p4-color">
                                                                Market
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="text-center tablehover">
                                                        <td>3.15%</td>
                                                        <td>1000</td>
                                                        <td>$19.9980</td>
                                                        <td>$0.0662</td>
                                                        <td>$0.0658</td>
                                                        <td>$0.06953</td>
                                                        <td>
                                                            <button className="roboto addbtn fw-bold px-3 px-md-4 py-1 rounded-1">
                                                                <i className="ti ti-plus" />
                                                                Add
                                                            </button>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center gap-3">
                                                            <button className="roboto fw-bold px-3 px-md-4 py-1 rounded-1 addbtn">
                                                                Limit
                                                            </button>
                                                            <button className="roboto fw-bold px-3 py-1 rounded-1 bg2-color p4-color">
                                                                Market
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="text-center tablehover">
                                                        <td>3.15%</td>
                                                        <td>1000</td>
                                                        <td>$19.9980</td>
                                                        <td>$0.0662</td>
                                                        <td>$0.0658</td>
                                                        <td>$0.06953</td>
                                                        <td>
                                                            <button className="roboto addbtn fw-bold px-3 px-md-4 py-1 rounded-1">
                                                                <i className="ti ti-plus" />
                                                                Add
                                                            </button>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center gap-3">
                                                            <button className="roboto fw-bold px-3 px-md-4 py-1 rounded-1 addbtn">
                                                                Limit
                                                            </button>
                                                            <button className="roboto fw-bold px-3 py-1 rounded-1 bg2-color p4-color">
                                                                Market
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="text-center tablehover">
                                                        <td>3.15%</td>
                                                        <td>1000</td>
                                                        <td>$19.9980</td>
                                                        <td>$0.0662</td>
                                                        <td>$0.0658</td>
                                                        <td>$0.06953</td>
                                                        <td>
                                                            <button className="roboto addbtn fw-bold px-3 px-md-4 py-1 rounded-1">
                                                                <i className="ti ti-plus" />
                                                                Add
                                                            </button>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center gap-3">
                                                            <button className="roboto fw-bold px-3 px-md-4 py-1 rounded-1 addbtn">
                                                                Limit
                                                            </button>
                                                            <button className="roboto fw-bold px-3 py-1 rounded-1 bg2-color p4-color">
                                                                Market
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="text-center tablehover">
                                                        <td>3.15%</td>
                                                        <td>1000</td>
                                                        <td>$19.9980</td>
                                                        <td>$0.0662</td>
                                                        <td>$0.0658</td>
                                                        <td>$0.06953</td>
                                                        <td>
                                                            <button className="roboto addbtn fw-bold px-3 px-md-4 py-1 rounded-1">
                                                                <i className="ti ti-plus" />
                                                                Add
                                                            </button>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center gap-3">
                                                            <button className="roboto fw-bold px-3 px-md-4 py-1 rounded-1 addbtn">
                                                                Limit
                                                            </button>
                                                            <button className="roboto fw-bold px-3 py-1 rounded-1 bg2-color p4-color">
                                                                Market
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="text-center tablehover">
                                                        <td>3.15%</td>
                                                        <td>1000</td>
                                                        <td>$19.9980</td>
                                                        <td>$0.0662</td>
                                                        <td>$0.0658</td>
                                                        <td>$0.06953</td>
                                                        <td>
                                                            <button className="roboto addbtn fw-bold px-3 px-md-4 py-1 rounded-1">
                                                                <i className="ti ti-plus" />
                                                                Add
                                                            </button>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center gap-3">
                                                            <button className="roboto fw-bold px-3 px-md-4 py-1 rounded-1 addbtn">
                                                                Limit
                                                            </button>
                                                            <button className="roboto fw-bold px-3 py-1 rounded-1 bg2-color p4-color">
                                                                Market
                                                            </button>
                                                        </td>
                                                    </tr>
                                                    <tr className="text-center tablehover">
                                                        <td>3.15%</td>
                                                        <td>1000</td>
                                                        <td>$19.9980</td>
                                                        <td>$0.0662</td>
                                                        <td>$0.0658</td>
                                                        <td>$0.06953</td>
                                                        <td>
                                                            <button className="roboto addbtn fw-bold px-3 px-md-4 py-1 rounded-1">
                                                                <i className="ti ti-plus" />
                                                                Add
                                                            </button>
                                                        </td>
                                                        <td className="d-flex align-items-center justify-content-center gap-3">
                                                            <button className="roboto fw-bold px-3 px-md-4 py-1 rounded-1 addbtn">
                                                                Limit
                                                            </button>
                                                            <button className="roboto fw-bold px-3 py-1 rounded-1 bg2-color p4-color">
                                                                Market
                                                            </button>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xxl-3">
                            <div className="apex_section__aside rounded-20 bg1-color h-100">
                                <div className="apex_section__aside-login pt-120 pb-120 px-7 px-md-13 text-center">
                                    <img
                                        src="assets/images/icon/chartlogin.png"
                                        className="mb-4 mb-md-5"
                                        alt="Icons"
                                    />
                                    <p className="roboto mb-4 mb-md-5">
                                        Connect your CoinX Web3 Wallet and enter the gateways to Web3
                                        derivatives trading.
                                    </p>
                                    <a
                                        className="cmn-btn px-10 px-md-15 py-3"
                                        data-bs-toggle="modal"
                                        href="#exampleModalToggle"
                                        role="button"
                                    >
                                        CoinX Login
                                    </a>
                                    <div
                                        className="modal fade"
                                        id="exampleModalToggle"
                                        aria-hidden="true"
                                        aria-labelledby="exampleModalToggleLabel"
                                        tabIndex={-1}
                                    >
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content bg7-color rounded-20">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title fs-5"
                                                        id="exampleModalToggleLabel"
                                                    >
                                                        Welcome to CoinX
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="p6-color"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <i className="ti ti-x fs-four" />
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="apex_section__aside-logintop d-flex align-items-center gap-3">
                                                        <span>New to Bybit?</span>{" "}
                                                        <i className="ti ti-arrows-exchange-2 fs-four p1-color" />
                                                        <button
                                                            className="d-flex align-items-center gap-3 p1-color"
                                                            data-bs-target="#exampleModalToggle2"
                                                            data-bs-toggle="modal"
                                                        >
                                                            Sign up
                                                        </button>
                                                    </div>
                                                    <div className="apex_section__aside-tabs mt-4">
                                                        <div className="singletab mb-5 mb-md-6">
                                                            <ul className="tablinks d-flex align-items-center gap-5 gap-md-6 mb-5 mb-md-6">
                                                                <li className="nav-links position-relative">
                                                                    <button className="tablink fw-bold">Email</button>
                                                                </li>
                                                                <li className="nav-links position-relative">
                                                                    <button className="tablink fw-bold">
                                                                        Mobile
                                                                    </button>
                                                                </li>
                                                                <li className="nav-links position-relative">
                                                                    <button className="tablink fw-bold">
                                                                        QR Code
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                            <div className="tabcontents">
                                                                <div className="tabitem active">
                                                                    <div className="apex_section__forms">
                                                                        <form>
                                                                            <input
                                                                                className="br2 mb-3 mb-md-4"
                                                                                type="email"
                                                                                placeholder="Email"
                                                                            />
                                                                            <input
                                                                                className="br2 mb-3 mb-md-4"
                                                                                type="password"
                                                                                placeholder="Swap Fee"
                                                                            />
                                                                            <a
                                                                                className="d-block text-end p1-color"
                                                                                href="javascript:void(0)"
                                                                            >
                                                                                Forgot password
                                                                            </a>
                                                                            <button className="cmn-btn px-6 py-3 w-100 mt-5 mt-md-6">
                                                                                Login
                                                                            </button>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                                <div className="tabitem">
                                                                    <div className="apex_section__forms">
                                                                        <form>
                                                                            <input
                                                                                className="br2 mb-3 mb-md-4"
                                                                                type="tel"
                                                                                placeholder="Phone"
                                                                            />
                                                                            <input
                                                                                className="br2 mb-3 mb-md-4"
                                                                                type="password"
                                                                                placeholder="Swap Fee"
                                                                            />
                                                                            <a
                                                                                className="d-block "
                                                                                href="javascript:void(0)"
                                                                            >
                                                                                Forgot password
                                                                            </a>
                                                                            <button className="cmn-btn px-6 py-3 w-100 mt-5 mt-md-6">
                                                                                Login
                                                                            </button>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                                <div className="tabitem">
                                                                    <div className="apex_section__qrcode">
                                                                        <img
                                                                            src="assets/images/qrcode.png"
                                                                            alt="rqcode"
                                                                        />
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center mb-3 mb-md-4">
                                                            <hr className="w-100 br2" />
                                                            <span className="w-100">or log in with</span>
                                                            <hr className="w-100 br2" />
                                                        </div>
                                                        <div className="d-flex align-items-center gap-5 gap-md-6 mb-3">
                                                            <a
                                                                className="cmn-btn w-100 py-3 px-5 px-md-6 d-center gap-2 fourth-alt"
                                                                href="javascript:void(0)"
                                                            >
                                                                <img
                                                                    src="assets/images/icon/google-icon.png"
                                                                    alt="icons"
                                                                />
                                                                Google
                                                            </a>
                                                            <a
                                                                className="cmn-btn w-100 py-3 px-5 px-md-6 d-center gap-2 fourth-alt"
                                                                href="javascript:void(0)"
                                                            >
                                                                <img
                                                                    src="assets/images/icon/apple-icon.png"
                                                                    alt="icons"
                                                                />
                                                                Google
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="modal-footer d-center">
                                                    <a
                                                        href="javascript:void(0)"
                                                        className="d-flex align-items-center gap-3 p1-color"
                                                    >
                                                        Log in with Subaccount
                                                        <i className="ti ti-arrow-right fs-four p1-color" />
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div
                                        className="modal fade"
                                        id="exampleModalToggle2"
                                        aria-hidden="true"
                                        aria-labelledby="exampleModalToggleLabel3"
                                        tabIndex={-1}
                                    >
                                        <div className="modal-dialog modal-dialog-centered">
                                            <div className="modal-content bg7-color rounded-20">
                                                <div className="modal-header">
                                                    <h5
                                                        className="modal-title fs-5"
                                                        id="exampleModalToggleLabel3"
                                                    >
                                                        Create Account
                                                    </h5>
                                                    <button
                                                        type="button"
                                                        className="p6-color"
                                                        data-bs-dismiss="modal"
                                                        aria-label="Close"
                                                    >
                                                        <i className="ti ti-x fs-four" />
                                                    </button>
                                                </div>
                                                <div className="modal-body">
                                                    <div className="apex_section__aside-logintop d-flex align-items-center gap-3 flex-wrap">
                                                        <span>Already have an account ?</span>{" "}
                                                        <i className="ti ti-arrows-exchange-2 fs-four p1-color" />
                                                        <button
                                                            className="d-flex align-items-center gap-3 p1-color"
                                                            data-bs-target="#exampleModalToggle2"
                                                            data-bs-toggle="modal"
                                                        >
                                                            Log In
                                                        </button>
                                                    </div>
                                                    <div className="apex_section__aside-tabs mt-4">
                                                        <div className="singletab mb-5 mb-md-6">
                                                            <ul className="tablinks d-flex align-items-center gap-5 gap-md-6 mb-5 mb-md-6">
                                                                <li className="nav-links position-relative">
                                                                    <button className="tablink fw-bold">Email</button>
                                                                </li>
                                                                <li className="nav-links position-relative">
                                                                    <button className="tablink fw-bold">
                                                                        Mobile
                                                                    </button>
                                                                </li>
                                                            </ul>
                                                            <div className="tabcontents">
                                                                <div className="tabitem active">
                                                                    <div className="apex_section__forms">
                                                                        <form className="d-flex flex-column ">
                                                                            <input
                                                                                className="br2 mb-3 mb-md-4"
                                                                                type="email"
                                                                                placeholder="Email"
                                                                            />
                                                                            <input
                                                                                className="br2 mb-3 mb-md-4"
                                                                                type="password"
                                                                                placeholder="Swap Fee"
                                                                            />
                                                                            <span className="mb-3 mb-md-4 text-start">
                                                                                Referral Code (Optional)
                                                                            </span>
                                                                            <input
                                                                                className="br2 mb-3 mb-md-4"
                                                                                type="text"
                                                                            />
                                                                            <div className="d-flex align-items-center gap-3">
                                                                                <input
                                                                                    type="checkbox"
                                                                                    id="rcode"
                                                                                    name="vehicle1"
                                                                                    defaultValue="rcode"
                                                                                />
                                                                                <label
                                                                                    className="i-aggres text-start"
                                                                                    htmlFor="rcode"
                                                                                >
                                                                                    Agree to Terms of Service and Privacy
                                                                                    Policy
                                                                                </label>
                                                                            </div>
                                                                            <button className="cmn-btn px-6 py-3 w-100 mt-5 mt-md-6">
                                                                                Create Account
                                                                            </button>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                                <div className="tabitem">
                                                                    <div className="apex_section__forms">
                                                                        <form>
                                                                            <input
                                                                                className="br2 mb-3 mb-md-4"
                                                                                type="tel"
                                                                                placeholder="Phone"
                                                                            />
                                                                            <input
                                                                                className="br2 mb-3 mb-md-4"
                                                                                type="password"
                                                                                placeholder="Swap Fee"
                                                                            />
                                                                            <a
                                                                                className="d-block "
                                                                                href="javascript:void(0)"
                                                                            >
                                                                                Forgot password
                                                                            </a>
                                                                            <button className="cmn-btn px-6 py-3 w-100 mt-5 mt-md-6">
                                                                                Login
                                                                            </button>
                                                                        </form>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="d-flex align-items-center mb-3 mb-md-4">
                                                            <hr className="w-100 br2" />
                                                            <span className="w-100">or log in with</span>
                                                            <hr className="w-100 br2" />
                                                        </div>
                                                        <div className="d-flex align-items-center gap-5 gap-md-6 mb-3">
                                                            <a
                                                                className="cmn-btn w-100 py-3 px-5 px-md-6 d-center gap-2 fourth-alt"
                                                                href="javascript:void(0)"
                                                            >
                                                                <img
                                                                    src="assets/images/icon/google-icon.png"
                                                                    alt="icons"
                                                                />
                                                                Google
                                                            </a>
                                                            <a
                                                                className="cmn-btn w-100 py-3 px-5 px-md-6 d-center gap-2 fourth-alt"
                                                                href="javascript:void(0)"
                                                            >
                                                                <img
                                                                    src="assets/images/icon/apple-icon.png"
                                                                    alt="icons"
                                                                />
                                                                Google
                                                            </a>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Apex Section Ends */}
            {/* Footer Section Starts */}
            <footer className="footer pt-120 bg5-color">
                <div className="container">
                    <div className="row gy-8 pb-120 justify-content-between">
                        <div className="col-md-12 col-lg-6 col-xxl-5">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <h2>Coinx Wallet</h2>
                                </a>
                                <p className="fs-ten mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp">
                                    Welcome to Coinx, your gateway to the world of Web3 trading! Our
                                    user-friendly platform empowers you to explore a wide range of
                                    popular cryptocurrencies
                                </p>
                                <div className="footer__decs-subscribe mb-9 mb-md-12 wow fadeInUp">
                                    <form className="d-flex align-items-center rounded-1 py-2 pe-2">
                                        <input type="email" placeholder="Enter your email" />
                                        <button className="cmn-btn py-3  px-8 rounded-1 d-center gap-2">
                                            Subscribe
                                            <i className="ti ti-send fs-five d-none d-md-block" />
                                        </button>
                                    </form>
                                </div>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-facebook-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-linkedin p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-instagram p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2 ms-xxl-20">
                            <div className="footer__discover">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Company</h4>
                                <div className="footer__discover-nav">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                About Coinx Wallet
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Join Us
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Blog
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Academy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Security</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Contact Validator
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Protection fund
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Security
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Assets</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                BNB Chain
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Ethereum
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                ERC20
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-md-start">
                                        Copyright 2024 -
                                        <a href="/" className="p4-color">
                                            ConiX
                                        </a>
                                        All Rights Reserved{" "}
                                        <span className="p4-color fs-five mx-2">|</span> Design By
                                        <a
                                            href="https://themeforest.net/user/uiaxis"
                                            className="p4-color roboto"
                                        >
                                            {" "}
                                            UIAXIS
                                        </a>
                                    </span>
                                </div>
                                <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="p4-color roboto ps-4 ps-sm-6"
                                    >
                                        Privacy Policy
                                    </a>
                                    <span className="p4-color fs-five">|</span>
                                    <a href="javascript:void(0)" className="p4-color roboto">
                                        Cookie Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section Ends */}
        </>

    )
}

export default Apes