import React, { useEffect, useState } from 'react';
import UserHeader from '../../component/userHeader';
import Auth from '../../component/auth';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

function Myjoining() {
    const [packageDetails, setPackageDetails] = useState([]);
    const loginToken = localStorage.getItem('userToken');
    const packageId = localStorage.getItem('myPackageId');

    // Fetch direct team data on component mount
    useEffect(() => {
        directTeam();
        console.log('MY Package Id',packageId)
    }, []);

    const directTeam = async () => {
        const mypackageId = {
            packageId: packageId,
        };
        try {
            const response = await fetch(`${baseApiUrl}/phase/details`, {
                method: 'POST', // Change to POST if your API requires it
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${loginToken}`,
                },
                body: JSON.stringify(mypackageId),
            });
            const result = await response.json();
            console.log('Package Details', result);
            if (result.status) {
                // Set downline data in state
                setPackageDetails(result.data);
            } else {
                toast.error("Failed to fetch downline data");
            }
        } catch (error) {
            console.error('Fetch error:', error);
            toast.error("Error fetching downline data");
        }
    };

    return (
        <>
            {/* Header and Auth Components */}
            <UserHeader />
            <ToastContainer />
            <Auth />
            <section className="cyber_arena pt-120 pb-120 bg5-color">
                <div className="container pt-17 pt-sm-20 pt-lg-0">
                    <div className="row ">
                        <div className="col-12">
                            <div className="cyber_arena__tophead d-flex align-items-center justify-content-between flex-wrap gap-4 mb-10 mb-md-15 wow fadeInUp">
                                <div className="cyber_arena__tphead d-flex align-items-center gap-3 flex-wrap">
                                    <div className="cyber_arena__tphead-itemone d-flex align-items-center gap-3 gap-sm-6 flex-wrap">
                                        <div className="cyber_arena__tphead-icon">
                                            <img src="assets/images/alderCoin.png" alt="Icon"  style={{height:"60px"}}/>
                                        </div>
                                        <a
                                            href="javascript:void(0)"
                                            className="cyber_arena__tphead-name"
                                        >
                                            <h2>Package Details</h2>
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-12">
                            <p className="roboto mb-6 mb-md-8 wow fadeInUp">
                            Alder Coin is more than just a digital asset—it’s a gateway to financial growth and investment opportunities within the Alder ecosystem. With Alder Coin, you have the flexibility to join packages that offer different levels of rewards based on your investment goals. Whether you’re looking to diversify your portfolio or explore the long-term growth of crypto assets, Alder Coin is a smart choice.
                            </p>
                        </div>
                    </div>
                    <div className="row gy-5 gy-sm-6 mb-10 mb-md-15">
                        
                        <div className="col-md-6 col-xl-6">
                            <div className="cyber_arena__totalcard p-6 p-md-8 br2 bg1-color rounded-20 h-100">
                                <div className="d-flex align-items-center justify-content-between mb-6 mb-md-8 gap-4 flex-wrap flex-sm-nowrap">
                                    <div className="cyber_arena__totalcard-title">
                                        <span className="mb-3 fs-ten">Package Amount</span>
                                        <div className="d-flex align-items-end gap-2">
                                            <span className="fs-four p1-color fw-bold">{packageDetails.amount || 'Loading..'}</span>
                                            <span className="fs-ten">USDT</span>
                                        </div>
                                    </div>
                                    <div className="cyber_arena__totalcard-title text-sm-end">
                                        <span className="mb-3 fs-ten">Total Issued</span>
                                        <div className="d-flex align-items-end gap-2">
                                            <span className="fs-four p1-color fw-bold">{packageDetails.coinAmount || 'Loading..'}</span>
                                            <span className="fs-ten">ALDER</span>
                                        </div>
                                    </div>
                                </div>
                                <div className="cyber_arena__nastcard p-5 p-md-6 rounded-20 bg1-color d-flex flex-column gap-4 gap-md-6 br2">
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Joining Phase</span>
                                        <span>{packageDetails.phase_name || 'Loading..'}</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Alder Price</span>
                                        <span>{packageDetails.coinPrice || 'Loading..'} USDT</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Joining Code</span>
                                        <span>{packageDetails.joiningToken || 'Loading..'}</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Phase Value</span>
                                        <span>{packageDetails.phaseSale || 'Loading..'} USDT</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Joining Date</span>
                                        <span>{packageDetails.join_date || 'Loading..'}</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Lock type</span>
                                        <span >{packageDetails.unlockType || 'Loading..'}</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Lock Status</span>
                                        <span className="fs-four p1-color">{packageDetails.status || 'Loading..'}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-6 col-xl-6">
                            <div className="cyber_arena__totalcardtwo bg1-color rounded-20 br2 py-7 py-md-9 px-5 px-md-6 br2 text-center">
                                <div className="d-flex align-items-center justify-content-center gap-2 gap-sm-3 mb-3">
                                    <img src="assets/images/icon/unlockIcon.png" alt="icon" style={{height:"50px"}} />
                                    <span className="fs-five">Unlock Phase</span>
                                </div>
                                <div className="d-flex align-items-center mb-3 mb-md-6">
                                    <hr className="w-100 " />
                                    <i className="ti ti-arrow-down fs-four br2 px-1 rounded-5" />
                                    <hr className="w-100" />
                                </div>
                                <div className=" p-5 p-md-6   d-flex flex-column gap-4 gap-md-6 ">
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Unlock Phase</span>
                                        <span>Phase {packageDetails.unlockPhase || 'Loading..'}</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Alder Price</span>
                                        <span>{packageDetails.currentCoinPrice || 'Loading..'} USDT</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Unlock Percentage</span>
                                        <span>{packageDetails.unlockPercentage || 'Loading..'} %</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Alder Value</span>
                                        <span>{packageDetails.finalAmount || 'Loading..'} USDT</span>
                                    </div>
                                    <div className="d-flex align-items-center justify-content-between gap-4 flex-wrap flex-sm-nowrap">
                                        <span>Unlock Date Time</span>
                                        <span>{packageDetails.unlockDate || 'Loading..'}</span>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center mb-3 mb-md-6">
                                    <hr className="w-100 " />
                                    <i className="ti ti-arrow-down fs-four br2 px-1 rounded-5" />
                                    <hr className="w-100" />
                                </div>
                                <div className="d-flex align-items-center justify-content-center gap-2 gap-sm-3 mb-3">
                                
                                    <a className="d-inline-flex align-items-center rounded-2 gap-2 roboto py-3 px-5 px-md-6 bg8-color p7-color fw-bold">UN-LOCK</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                    <div className="cyber_arena__decs mb-8 mb-md-10">
                        <h3 className="mb-5 mb-md-6 wow fadeInUp">Unlock Condition</h3>
                        <div style={{ fontFamily: 'Arial, sans-serif', lineHeight: '1.6' }}>
                            <p className="mb-10">
                                You are currently buying Alder Coin in <strong>{packageDetails.phase_name || '....'}</strong> with a package number of <strong>{packageDetails.joiningToken || '...'}</strong>. 
                                The current phase value is <strong>{packageDetails.phaseSale || '...'}</strong> USDT and the unlock phase type is set to <strong>{packageDetails.unlockType || '...'}</strong>.
                                This setup allows for Alder Coin unlocking across multiple phases based on specified conditions.
                            </p>

                            <h6>Unlocking Your Alder Coins</h6>
                            <p>
                                To unlock your Alder Coins in <strong>Phase {packageDetails.unlockPhase || '...'}</strong>, the following conditions need to be met:
                            </p>
                            <ul className="mb-10">
                                <li><strong>Phase Value:</strong> The phase value in Phase {packageDetails.unlockPhase || '...'} must be greater than <strong>{packageDetails.phaseSale || '...'}</strong> USDT.</li>
                                <li><strong>Sales Volume:</strong> The number of sales in Phase {packageDetails.unlockPhase || '...'} must also exceed <strong>{packageDetails.joiningToken || '...'}</strong>.</li>
                                <li><strong>Asset Growth:</strong> Once these conditions are met, your asset growth will reach <strong>{packageDetails.finalAmount || 'Loading..'}</strong> USDT,
                                    showcasing the growth potential of your Alder Coin investment.
                                </li>
                            </ul >

                            <h6 className="mb-2">Benefits of Your Alder Coin Package</h6>
                            <p>Investing in Alder Coin through structured phases provides several advantages:</p>
                            <ul>
                                <li><strong>Increased Value:</strong> As the phase progresses and sales volume increases, the value of your assets grows, allowing you to benefit from rising demand.</li>
                                <li><strong>Strategic Unlocking:</strong> With the multiple unlock phase type, you can strategically unlock and potentially sell coins when market conditions are favorable.</li>
                                <li><strong>Portfolio Growth:</strong> Your investment is designed to reach targeted growth milestones such as <strong>{packageDetails.finalAmount || 'Loading..'}</strong> USDT over time.</li>
                            </ul>

                            <p>
                                Alder Coin's phase-based investment approach provides flexibility, growth potential, and a clear path to maximize your crypto holdings.
                                Enjoy the benefits of being part of Alder Coin, a forward-thinking platform in the cryptocurrency space!
                            </p>
                        </div>
                    </div>

                        
                    </div>
                </div>
            </section>
            {/* Cyber Arena Ends */}
            
        </>
    );
}

export default Myjoining;
