import React from 'react';

function UserHeader() {
    const handleLogout = () => {
        // Clear all localStorage and sessionStorage data
        localStorage.clear();
        sessionStorage.clear();
        
        // Redirect to the homepage or login page after logout
        window.location.href = '/';
    };
    return (
        <>
            <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
                <div className="navbar_mainhead header-fixed w-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 workready">
                                    <a
                                        href="/"
                                        className="navbar-brand d-flex align-items-center gap-2"
                                    >
                                         <img src="assets/images/alderCoin.png" className="logo" alt="logo" style={{height:"50px"}} />
                                    </a>
                                    <div
                                        className="collapse navbar-collapse justify-content-between"
                                        id="navbar-content"
                                    >
                                        <ul className="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                                            <li className="dropdown">
                                                <a href="/user-dashboard" className="fs-ten">
                                                    Dashboard
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/user-community" className="fs-ten">
                                                    Community
                                                    <i className="ti ti-chevron-down" />
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/user-join-phase" className="fs-ten">
                                                    Phase
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/user-income" className="fs-ten">
                                                    Income
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/user-wallet" className="fs-ten">
                                                    Wallet
                                                </a>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                                        <div className="single-item cart-area search-area">
                                            <div className="cmn-head">
                                                <button
                                                    type="button"
                                                    aria-label="Shopping Button"
                                                    className="common_toggles2 icon-area p-0 me-3 me-lg-0 box-second d-center position-relative"
                                                >
                                                    <i className="ti ti-search slide-toggle2 fs-four p6-color" />
                                                </button>
                                                <div className="msg_area common_area2 p2-bg p-5 rounded-2">
                                                    <form className="d-flex align-items-center ">
                                                        <input type="text" />
                                                        <button type="submit" className="p-2">
                                                            <i className="ti ti-search slide-toggle2 fs-four p2-color" />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-section__modalstyle">
                                            {/* Logout button */}
                                            <button onClick={handleLogout} className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1">
                                                <span className="p7-color d-sm-block">
                                                    Logout
                                                </span>
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        aria-label="Navbar Toggler"
                                        data-bs-target="#navbar-content"
                                        aria-expanded="true"
                                        id="nav-icon3"
                                    >
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
        </>
    );
}

export default UserHeader;
