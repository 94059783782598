import React from 'react'

function Error() {
    return (
        <>
            {/* Scroll To Top Start*/}
            <button
                className="scrollToTop d-none d-md-flex d-center"
                aria-label="scroll Bar Button"
            >
                <i className="ti ti-chevron-up fs-four p6-color" />
            </button>
            {/* Scroll To Top End */}
            {/* Not Found page section starts */}
            <section className="not_found bg5-color pt-20 pb-120">
                <div className="container">
                    <div className="row justify-content-center">
                        <div className="col-xxl-6">
                            <div className="not_found__content">
                                <div className="not_found__thumb mb-10 mb-md-15 text-center wow fadeInUp">
                                    <img src="assets/images/404.png" alt="Image" />
                                </div>
                                <div className="not_found__decs text-center">
                                    <h4 className="mb-5 mb-md-6 roboto wow fadeInUp">
                                        Oops, You Still Haven’t Found what you looking for
                                    </h4>
                                    <p className="roboto mb-6 mb-md-8 wow fadeInUp">
                                        Please note that all tasks and associated rewards are subject to
                                        availability and are limited in quantity.{" "}
                                    </p>
                                    <a
                                        href="/"
                                        className="cmn-btn px-5 px-md-6 py-3 wow fadeInUp"
                                    >
                                        Back to Home
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Not Found page section Ends */}
        </>

    )
}

export default Error