import React from 'react'

function Staking() {
    return (
        <>
            {/* Scroll To Top Start*/}
            <button
                className="scrollToTop d-none d-md-flex d-center"
                aria-label="scroll Bar Button"
            >
                <i className="ti ti-chevron-up fs-four p6-color" />
            </button>
            {/* Scroll To Top End */}
            {/* header-section start */}
            <div className="navbar_top bg2-color py-4 d-none d-lg-block">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-lg-5 col-xxl-5">
                            <div className="navbar_top__left d-flex align-items-center gap-2 gap-xl-6">
                                <div className="navbar_top__location d-flex align-items-center gap-1 gap-xl-3">
                                    <i className="ti ti-map-pin-filled fs-four p7-color" />
                                    <span className="roboto p7-color">
                                        102/B New Market, Sandigo-USA
                                    </span>
                                </div>
                                <span className="v-line mb-9" />
                                <a
                                    href="https://softivus.com/cdn-cgi/l/email-protection#8beef3eae6fbe7eecbece6eae2e7a5e8e4e6"
                                    className="navbar_top__email roboto p7-color d-flex align-items-center gap-3"
                                >
                                    <i className="ti ti-mail-opened-filled fs-four" />
                                    <span
                                        className="__cf_email__"
                                        data-cfemail="c9acb1a8a4b9a5ac89aea4a8a0a5e7aaa6a4"
                                    >
                                        [email&nbsp;protected]
                                    </span>
                                </a>
                            </div>
                        </div>
                        <div className="col-lg-7 col-xxl-6">
                            <div className="navbar_top__right d-flex align-items-center justify-content-end gap-2 gap-xl-6">
                                <div className="navbar_top__call d-flex align-items-center gap-3">
                                    <span className="bg6-color py-2 px-3 rounded-item">
                                        <i className="ti ti-phone-call fs-four p7-color " />
                                    </span>
                                    <div>
                                        <span className="p7-color fw-bolder d-block">Contact Us:</span>
                                        <a href="tel:+9802344567" className="d-block p7-color">
                                            +980 234 4567
                                        </a>
                                    </div>
                                </div>
                                <span className="v-line mb-9" />
                                <div className="navbar_top__social d-flex align-items-center gap-2 gap-xl-3">
                                    <span className="p7-color fw-bolder">Follow Us:</span>
                                    <div className="navbar_top__social-icon d-flex align-items-center  gap-1 gap-xl-2">
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-facebook fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-instagram fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-twitter fs-four p7-color " />
                                        </a>
                                        <a
                                            href="javascript:void(0)"
                                            className="br3 py-2 px-3 rounded-item d-center"
                                        >
                                            <i className="ti ti-brand-linkedin fs-four p7-color " />
                                        </a>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <header className="header-section header-menu w-100 pt-1 pt-lg-0 pb-3 pb-lg-0">
                <div className="navbar_mainhead header-fixed w-100">
                    <div className="container">
                        <div className="row align-items-center">
                            <div className="col-12">
                                <nav className="navbar navbar-expand-lg position-relative py-md-3 py-lg-6 workready">
                                    <a
                                        href="/"
                                        className="navbar-brand d-flex align-items-center gap-2"
                                    >
                                        <img src="assets/images/logo.png" className="logo" alt="logo" />
                                    </a>
                                    <div
                                        className="collapse navbar-collapse justify-content-between"
                                        id="navbar-content"
                                    >
                                        <ul className="navbar-nav d-flex align-items-lg-center gap-5 gap-lg-1 gap-xl-4 gap-xxl-7 py-2 py-lg-0 ms-2 ms-xl-10 ms-xxl-20 ps-0 ps-xxl-10 align-self-center">
                                            <li className="dropdown">
                                                <a href="/" className="fs-ten">
                                                    Home
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Staking <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/staking">
                                                            Staking Pool
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/staking_details"
                                                        >
                                                            Pool details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    IDO <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/ido">
                                                            IDO
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/idodetails"
                                                        >
                                                            IDO Details
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Swap <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/swap">
                                                            Swap
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/swap_checkout"
                                                        >
                                                            Swap to share
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <a href="/apes" className="fs-ten">
                                                    ApeX
                                                </a>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Buy Crypto
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/buycrypto"
                                                        >
                                                            Buy Crypto
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/pricing_plan"
                                                        >
                                                            Pricing plan
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                            <li className="dropdown show-dropdown">
                                                <button
                                                    type="button"
                                                    aria-label="Navbar Dropdown Button"
                                                    className="dropdown-toggle dropdown-nav d-flex align-items-center fs-ten"
                                                >
                                                    Pages
                                                    <i className="ti ti-chevron-down" />
                                                </button>
                                                <ul className="dropdown-menu">
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/rewards">
                                                            Rewards
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogs"
                                                        >
                                                            Blog
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/blogdetails"
                                                        >
                                                            Blog details
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/contact_us"
                                                        >
                                                            Contact us
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            className="dropdown-item fs-ten"
                                                            href="/terms_conditaions"
                                                        >
                                                            Terms &amp; condition
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a className="dropdown-item fs-ten" href="/error">
                                                            Error
                                                        </a>
                                                    </li>
                                                </ul>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="right-area custom-pos position-relative d-flex gap-0 gap-lg-2 align-items-center">
                                        <div className="single-item cart-area search-area">
                                            <div className="cmn-head">
                                                <button
                                                    type="button"
                                                    aria-label="Shopping Button"
                                                    className="common_toggles2 icon-area p-0 me-3 me-lg-0 box-second d-center position-relative"
                                                >
                                                    <i className="ti ti-search slide-toggle2 fs-four p6-color" />
                                                </button>
                                                <div className="msg_area common_area2 p2-bg p-5 rounded-2">
                                                    <form className="d-flex align-items-center ">
                                                        <input type="text" />
                                                        <button type="submit" className="p-2">
                                                            <i className="ti ti-search slide-toggle2 fs-four p2-color" />
                                                        </button>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="header-section__modalstyle">
                                            {/* Button trigger modal */}
                                            <button
                                                type="button"
                                                className="cmn-btn px-3 px-sm-5 px-md-6 py-2 py-sm-3 d-flex align-items-center gap-1"
                                                data-bs-toggle="modal"
                                                data-bs-target="#exampleModal"
                                            >
                                                <span className="p7-color fw-semibold d-none d-sm-block">
                                                    Connect
                                                </span>
                                                Wallet
                                            </button>
                                        </div>
                                    </div>
                                    <button
                                        className="navbar-toggler"
                                        type="button"
                                        data-bs-toggle="collapse"
                                        aria-label="Navbar Toggler"
                                        data-bs-target="#navbar-content"
                                        aria-expanded="true"
                                        id="nav-icon3"
                                    >
                                        <span />
                                        <span />
                                        <span />
                                        <span />
                                    </button>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </header>
            <div className="modal_areastyle">
                {/* Modal */}
                <div
                    className="modal"
                    id="exampleModal"
                    tabIndex={-1}
                    aria-labelledby="exampleModalLabel"
                    aria-hidden="true"
                >
                    <div className="modal-dialog modal-dialog-centered">
                        <div className="modal-content px-2 px-md-12 pt-md-12 pb-2 pb-md-12">
                            <div className="modal-header border-0">
                                <h3 className="modal-title p1-color" id="exampleModalLabel">
                                    Connect Your Wallet
                                </h3>
                                <button
                                    type="button"
                                    className="btn-close p7-color"
                                    data-bs-dismiss="modal"
                                    aria-label="Close"
                                >
                                    <i className="ti ti-xbox-x p6-color fs-three fw-normal" />
                                </button>
                            </div>
                            <div className="modal-body">
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between mb-5 mb-md-6"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/dog.png" alt="Icon" />
                                </a>
                                <a
                                    href="javascript:void(0)"
                                    className="modal_areastyle__item rounded-3 br2 px-3 px-md-4 py-2 py-md-3 d-flex align-items-center justify-content-between"
                                >
                                    <span className="fw_500">Conntect with Metamask</span>
                                    <img src="assets/images/icon/wallet-connect.png" alt="Icon" />
                                </a>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/* header-section end */}
            {/*Common hero Section Starts */}
            <section className="cmn_heros pb-120 pt-120">
                <div className="container">
                    <div className="row justify-content-center mt-5 mt-md-8 mt-lg-0">
                        <div className="col-xxl-6">
                            <div className="cmn_heros__title text-center pt-15 pt-lg-6">
                                <h1 className="display-three mb-5 mb-md-7 wow fadeInUp">
                                    Staking Pools
                                </h1>
                                <p className="roboto wow fadeInUp">
                                    Earn passive income easily with just one click!
                                </p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/*Common hero Section Ends */}
            {/* What's Trending Section starts */}
            <section className="what_trending bg9-color pt-120 pb-120">
                <div className="container">
                    <div className="row categories__header mb-4 mb-md-6">
                        <div className="col-xl-7 col-lg-8 col-sm-8">
                            <div className="categories__title">
                                <h2 className="mb-3 mb-md-4 wow fadeInUp">What's Trending</h2>
                            </div>
                        </div>
                        <div className="col-xl-5 col-lg-4 col-sm-4 categories_top_btn mt-6 mt-sm-0">
                            <div className="slider-btn d-center justify-content-start justify-content-sm-end gap-4">
                                <button
                                    type="button"
                                    aria-label="Slide Prev"
                                    className="ara-prev d-center cmn-btn px-2 py-1 rounded-5"
                                >
                                    <i className="ti ti-chevron-left fs-four" />
                                </button>
                                <button
                                    type="button"
                                    aria-label="Slide Next"
                                    className="ara-next d-center cmn-btn px-2 py-1 rounded-5"
                                >
                                    <i className="ti ti-chevron-right fs-four" />
                                </button>
                            </div>
                        </div>
                    </div>
                    <div className="row">
                        <div className="swiper categories_top">
                            <div className="swiper-wrapper">
                                <div className="swiper-slide">
                                    <div className="items-wrapper">
                                        <div className="what_trending__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color br2 rounded-3">
                                            <img
                                                className="mb-3 mb-md-4"
                                                src="assets/images/icon/poolsicon1.png"
                                                alt="icon"
                                            />
                                            <h4 className="mb-1">ETH</h4>
                                            <span className="mb-5 mb-md-6 d-block ">Lido Dao</span>
                                            <span className="fs-one p1-color fw_500 mb-1 d-block ">
                                                3.7%
                                            </span>
                                            <span className="mb-6 mb-md-8 d-block ">Apy</span>
                                            <a
                                                href="javascript:void(0)"
                                                className="cmn-btn py-3 px-5 px-md-6 w-100 "
                                            >
                                                Invest
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="items-wrapper">
                                        <div className="what_trending__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color br2 rounded-3">
                                            <img
                                                className="mb-3 mb-md-4"
                                                src="assets/images/icon/poolsicon2.png"
                                                alt="icon"
                                            />
                                            <h4 className="mb-1">DAI</h4>
                                            <span className="mb-5 mb-md-6 d-block ">Maker Dao</span>
                                            <span className="fs-one p1-color fw_500 mb-1 d-block ">
                                                5.0%
                                            </span>
                                            <span className="mb-6 mb-md-8 d-block ">Apy</span>
                                            <a
                                                href="javascript:void(0)"
                                                className="cmn-btn py-3 px-5 px-md-6 w-100 "
                                            >
                                                Invest
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="items-wrapper">
                                        <div className="what_trending__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color br2 rounded-3">
                                            <img
                                                className="mb-3 mb-md-4"
                                                src="assets/images/icon/poolsicon3.png"
                                                alt="icon"
                                            />
                                            <h4 className="mb-1">BNB</h4>
                                            <span className="mb-5 mb-md-6 d-block ">BabySwap</span>
                                            <span className="fs-one p1-color fw_500 mb-1 d-block ">
                                                5.8%
                                            </span>
                                            <span className="mb-6 mb-md-8 d-block ">Apy</span>
                                            <a
                                                href="javascript:void(0)"
                                                className="cmn-btn py-3 px-5 px-md-6 w-100 "
                                            >
                                                Invest
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="items-wrapper">
                                        <div className="what_trending__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color br2 rounded-3">
                                            <img
                                                className="mb-3 mb-md-4"
                                                src="assets/images/icon/poolsicon4.png"
                                                alt="icon"
                                            />
                                            <h4 className="mb-1">USDC</h4>
                                            <span className="mb-5 mb-md-6 d-block ">Curve</span>
                                            <span className="fs-one p1-color fw_500 mb-1 d-block ">
                                                4.5%
                                            </span>
                                            <span className="mb-6 mb-md-8 d-block ">Apy</span>
                                            <a
                                                href="javascript:void(0)"
                                                className="cmn-btn py-3 px-5 px-md-6 w-100 "
                                            >
                                                Invest
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="items-wrapper">
                                        <div className="what_trending__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color br2 rounded-3">
                                            <img
                                                className="mb-3 mb-md-4"
                                                src="assets/images/icon/poolsicon1.png"
                                                alt="icon"
                                            />
                                            <h4 className="mb-1">ETH</h4>
                                            <span className="mb-5 mb-md-6 d-block ">Lido Dao</span>
                                            <span className="fs-one p1-color fw_500 mb-1 d-block ">
                                                3.7%
                                            </span>
                                            <span className="mb-6 mb-md-8 d-block ">Apy</span>
                                            <a
                                                href="javascript:void(0)"
                                                className="cmn-btn py-3 px-5 px-md-6 w-100 "
                                            >
                                                Invest
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="items-wrapper">
                                        <div className="what_trending__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color br2 rounded-3">
                                            <img
                                                className="mb-3 mb-md-4"
                                                src="assets/images/icon/poolsicon2.png"
                                                alt="icon"
                                            />
                                            <h4 className="mb-1">DAI</h4>
                                            <span className="mb-5 mb-md-6 d-block ">Maker Dao</span>
                                            <span className="fs-one p1-color fw_500 mb-1 d-block ">
                                                5.0%
                                            </span>
                                            <span className="mb-6 mb-md-8 d-block ">Apy</span>
                                            <a
                                                href="javascript:void(0)"
                                                className="cmn-btn py-3 px-5 px-md-6 w-100 "
                                            >
                                                Invest
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="swiper-slide">
                                    <div className="items-wrapper">
                                        <div className="what_trending__item text-center py-8 py-md-10  px-5 px-md-6 bg1-color br2 rounded-3">
                                            <img
                                                className="mb-3 mb-md-4"
                                                src="assets/images/icon/poolsicon3.png"
                                                alt="icon"
                                            />
                                            <h4 className="mb-1">BNB</h4>
                                            <span className="mb-5 mb-md-6 d-block ">BabySwap</span>
                                            <span className="fs-one p1-color fw_500 mb-1 d-block ">
                                                5.8%
                                            </span>
                                            <span className="mb-6 mb-md-8 d-block ">Apy</span>
                                            <a
                                                href="javascript:void(0)"
                                                className="cmn-btn py-3 px-5 px-md-6 w-100 "
                                            >
                                                Invest
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* What's Trending Section ends */}
            {/* Pools section starts */}
            <section className="pools_table pt-120 pb-120">
                <div className="container">
                    <div className="row">
                        <div className="pools_table__title mb-5 mb-md-6">
                            <h2 className="mb-5 mb-md-6 wow fadeInUp">Pools</h2>
                            <p className="roboto wow fadeInUp">
                                Access DApps via the Bybit Wallet extension or the respective mobile
                                app
                            </p>
                        </div>
                        <div className="pools_table__part">
                            <div className="singletab">
                                <ul className="tablinks d-flex align-items-center gap-5 gap-sm-10 gap-md-15 gap-lg-19 mb-6 mb-md-8 wow fadeInUp">
                                    <li className="nav-links clickable-active active">
                                        <button className="tablink ">All</button>
                                    </li>
                                    <li className="nav-links clickable-active">
                                        <button className="tablink ">Events</button>
                                    </li>
                                    <li className="nav-links clickable-active">
                                        <button className="tablink ">Single Asset</button>
                                    </li>
                                </ul>
                                <div className="tabcontents">
                                    <div className="tabitem active wow fadeInUp">
                                        <div className="pools_table__totalitem overflow-auto">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Pool Name</th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>Current APY</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint pools_table__totalitem-ticonone" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint pools_table__totalitem-ticontwo" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>CNetwork</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint pools_table__totalitem-ticonone" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint pools_table__totalitem-ticontwo" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>TVL</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint pools_table__totalitem-ticonone" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint pools_table__totalitem-ticontwo" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon1.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">USDC</span>
                                                                    <span className="roboto">LDO</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">26.80%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>$181.48 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8 p1-color"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto">stETH-ng</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.15%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$ 96.88 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon10.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">cvxeth</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">8.72%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">ironback</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">10.09%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$6.85 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="pools_table__pagination mt-6 mt-md-8">
                                            <nav>
                                                <ul className="d-flex align-items-center gap-3 gap-md-5">
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-left fs-four p1-color" />
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">1</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">2</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">3</span>
                                                    </li>
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-right fs-four p1-color" />
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="tabitem wow fadeInUp">
                                        <div className="pools_table__totalitem overflow-auto">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Pool Name</th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>Current APY</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>CNetwork</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>TVL</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon1.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">USDC</span>
                                                                    <span className="roboto">LDO</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">26.80%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>$181.48 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8 p1-color"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto">stETH-ng</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.15%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$ 96.88 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon10.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">cvxeth</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">8.72%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">ironback</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">10.09%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$6.85 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="pools_table__pagination mt-6 mt-md-8">
                                            <nav>
                                                <ul className="d-flex align-items-center gap-3 gap-md-5">
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-left fs-four p1-color" />
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">1</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">2</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">3</span>
                                                    </li>
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-right fs-four p1-color" />
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                    <div className="tabitem wow fadeInUp">
                                        <div className="pools_table__totalitem overflow-auto">
                                            <table>
                                                <tbody>
                                                    <tr>
                                                        <th>Pool Name</th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>Current APY</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>CNetwork</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <span>TVL</span>
                                                                <div className="d-flex flex-column gap-0">
                                                                    <i className="ti ti-caret-up-filled fs-nine cpoint" />
                                                                    <i className="ti ti-caret-down-filled fs-nine cpoint" />
                                                                </div>
                                                            </div>
                                                        </th>
                                                        <th>Type</th>
                                                        <th>Action</th>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon1.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">USDC</span>
                                                                    <span className="roboto">LDO</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">26.80%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center">
                                                                <img
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon11.png"
                                                                    alt="Icons"
                                                                />
                                                                <img
                                                                    className="pools_table__totalitem-img"
                                                                    src="assets/images/icon/tableicon12.png"
                                                                    alt="Icons"
                                                                />
                                                            </div>
                                                        </td>
                                                        <td>$181.48 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8 p1-color"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto">stETH-ng</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.15%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$ 96.88 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon4.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon10.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">cvxeth</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">8.72%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon6.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">ironback</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">10.09%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Ethereum</span>
                                                            </div>
                                                        </td>
                                                        <td>$6.85 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon7.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">yCRV</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">12.68%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Arbitrum</span>
                                                            </div>
                                                        </td>
                                                        <td>$5.27 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon2.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon8.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">Overnight</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">3.01%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$194.89 K</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                    <tr>
                                                        <td>
                                                            <div className="pools_table__totalitem-ftd d-flex align-items-center gap-3 gap-md-4 ms-3 ms-md-6">
                                                                <div className="d-flex align-items-center">
                                                                    <img
                                                                        src="assets/images/icon/tableicon5.png"
                                                                        alt="Icons"
                                                                    />
                                                                    <img
                                                                        className="pools_table__totalitem-img"
                                                                        src="assets/images/icon/tableicon9.png"
                                                                        alt="Icons"
                                                                    />
                                                                </div>
                                                                <div className="d-flex flex-column">
                                                                    <span className="roboto fw-bold">FRAXBP</span>
                                                                    <span className="roboto">curve</span>
                                                                </div>
                                                            </div>
                                                        </td>
                                                        <td className="p1-color fs-ten">5.52%</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-1">
                                                                <img
                                                                    src="assets/images/icon/tableicon3.png"
                                                                    alt="Icons"
                                                                />
                                                                <span>Polygon</span>
                                                            </div>
                                                        </td>
                                                        <td>$2.42 M</td>
                                                        <td>
                                                            <div className="d-flex align-items-center gap-2">
                                                                <span>Flexi-Term</span>
                                                                <a
                                                                    className="py-1 px-2 bg1-color rounded-1 p1-color"
                                                                    href="javascript:void(0)"
                                                                >
                                                                    Withdraw
                                                                </a>
                                                            </div>
                                                        </td>
                                                        <td>
                                                            <a
                                                                className="cmn-btn py-2 px-6 px-md-8"
                                                                href="javascript:void(0)"
                                                            >
                                                                Stack
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                        <div className="pools_table__pagination mt-6 mt-md-8">
                                            <nav>
                                                <ul className="d-flex align-items-center gap-3 gap-md-5">
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-left fs-four p1-color" />
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">1</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">2</span>
                                                    </li>
                                                    <li className="px-5 py-3 br1 rounded-5 d-center">
                                                        <span className="p1-color fs-five">3</span>
                                                    </li>
                                                    <li className="px-3 py-2 br1 rounded-5 d-center">
                                                        <i className="ti ti-chevron-right fs-four p1-color" />
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Pools section ends */}
            {/* Staking Process starts */}
            <section className="staking_process bg9-color pt-120 pb-120">
                <div className="container">
                    <div className="row gy-10 gy-sm-6">
                        <div className="staking_process__title text-center mb-md-15">
                            <h2 className="mb-5 mb-md-6 wow fadeInUp">Staking Process</h2>
                            <p className="roboto wow fadeInUp">
                                Earn rewards by participating in our straightforward and rewarding
                                staking process.
                            </p>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-3">
                            <div className="staking_process__item text-center wow fadeInUp position-relative">
                                <img
                                    className="mb-6 mb-md-8"
                                    src="assets/images/icon/process1.png"
                                    alt="Icon"
                                />
                                <h4 className="mb-4">Prepare Funds</h4>
                                <p>Convert assets required for staking</p>
                                <img
                                    className="staking_process__item-arrowicon position-absolute d-none d-sm-block"
                                    src="assets/images/icon/processuparrow.png"
                                    alt="arrow icon"
                                />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-3">
                            <div className="staking_process__item text-center wow fadeInUp position-relative">
                                <img
                                    className="mb-6 mb-md-8"
                                    src="assets/images/icon/process2.png"
                                    alt="Icon"
                                />
                                <h4 className="mb-4">Stake Assets</h4>
                                <p>Invest assets into staking pools</p>
                                <img
                                    className="staking_process__item-arrowicontwo position-absolute d-none d-md-block"
                                    src="assets/images/icon/processdownarrow.png"
                                    alt="arrow icon"
                                />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-3 position-relative">
                            <div className="staking_process__item text-center wow fadeInUp">
                                <img
                                    className="mb-6 mb-md-8"
                                    src="assets/images/icon/process3.png"
                                    alt="Icon"
                                />
                                <h4 className="mb-4">Earn Yield</h4>
                                <p>Invest assets into staking pools</p>
                                <img
                                    className="staking_process__item-arrowicon position-absolute d-none d-sm-block  d-md-none d-xl-block"
                                    src="assets/images/icon/processuparrow.png"
                                    alt="arrow icon"
                                />
                            </div>
                        </div>
                        <div className="col-sm-6 col-md-4 col-xl-3">
                            <div className="staking_process__item text-center wow fadeInUp">
                                <img
                                    className="mb-6 mb-md-8"
                                    src="assets/images/icon/process4.png"
                                    alt="Icon"
                                />
                                <h4 className="mb-4">Collect Proceeds</h4>
                                <p>Invest assets into staking pools</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            {/* Staking Process ends */}
            {/* Rewards FAQs Starts */}
            <section className="rewards_faq pt-120 pb-120 position-relative">
                <div className="container">
                    <div className="row align-items-center justify-content-center gy-8">
                        <div className="col-xl-7">
                            <div className="rewards_faq__content">
                                <h2 className="mb-5 mb-md-6 wow fadeInUp">FAQs</h2>
                                <p className="roboto mb-8 mb-md-10 wow fadeInUp">
                                    Explore our FAQs for fast, informative answers to frequently asked
                                    questions and common concerns.
                                </p>
                                <div className="accordion-section">
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What currencies does the Coinx Exchange support?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What is the Coinx Exchange?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What currencies does the Coinx Exchange support?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What currencies does the Coinx Exchange support?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What is the Coinx Exchange?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="accordion-single accsingle mb-5 mb-md-6 wow fadeInUp">
                                        <h5 className="header-area d-flex align-items-center justify-content-between">
                                            <button
                                                className="accordion-btn d-flex align-items-start position-relative w-100 fs-five fw-bolder text-start"
                                                type="button"
                                            >
                                                What currencies does the Coinx Exchange support?
                                            </button>
                                        </h5>
                                        <div className="content-area">
                                            <div className="content-body">
                                                <p>
                                                    These are traditional online platforms where users <br />{" "}
                                                    create accounts and trade{" "}
                                                </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="d-flex align-items-center flex-wrap gap-2 wow fadeInUp">
                                    <span>Can't see your question listed above?</span>
                                    <a
                                        href="javascript:void(0)"
                                        className="d-flex align-items-center gap-1 p6-color astyle"
                                    >
                                        Visit our Help Center{" "}
                                        <i className="ti ti-arrow-narrow-right fs-four mt-1" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-5">
                            <div className="rewards_faq__thumb">
                                <img
                                    src="assets/images/faq-thumb.png"
                                    className="max-un leftright-animation"
                                    alt="Images"
                                />
                            </div>
                        </div>
                    </div>
                </div>
                {/* <img class="rewards_faq__light position-absolute top-0 start-0" src="assets/images/light-faq.png" alt="Icon"> */}
            </section>
            {/* Rewards FAQs Ends */}
            {/* Footer Section Starts */}
            <footer className="footer pt-120 bg5-color">
                <div className="container">
                    <div className="row gy-8 pb-120 justify-content-between">
                        <div className="col-md-12 col-lg-6 col-xxl-5">
                            <div className="footer__decs wow fadeInUp">
                                <a href="/">
                                    <h2>Coinx Wallet</h2>
                                </a>
                                <p className="fs-ten mt-5 mt-md-6 mb-8 mb-md-10 wow fadeInUp">
                                    Welcome to Coinx, your gateway to the world of Web3 trading! Our
                                    user-friendly platform empowers you to explore a wide range of
                                    popular cryptocurrencies
                                </p>
                                <div className="footer__decs-subscribe mb-9 mb-md-12 wow fadeInUp">
                                    <form className="d-flex align-items-center rounded-1 py-2 pe-2">
                                        <input type="email" placeholder="Enter your email" />
                                        <button className="cmn-btn py-3  px-8 rounded-1 d-center gap-2">
                                            Subscribe
                                            <i className="ti ti-send fs-five d-none d-md-block" />
                                        </button>
                                    </form>
                                </div>
                                <div className="contact_info__card-social d-flex align-items-center justify-content-start gap-2 gap-md-3 wow fadeInUp">
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-facebook-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-linkedin p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-instagram p4-color fs-four fw-normal p-2" />
                                    </a>
                                    <a href="javascript:void(0)">
                                        <i className="ti ti-brand-twitter-filled p4-color fs-four fw-normal p-2" />
                                    </a>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2 ms-xxl-20">
                            <div className="footer__discover">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Company</h4>
                                <div className="footer__discover-nav">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                About Coinx Wallet
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Join Us
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Blog
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Academy
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Security</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Contact Validator
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Protection fund
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Security
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                        <div className="col-6 col-sm-3 col-md-4 col-lg-3 col-xxl-2">
                            <div className="footer__community">
                                <h4 className="mb-6 mb-sm-8 mb-md-10 wow fadeInUp">Assets</h4>
                                <div className="footer__community-item">
                                    <ul className="d-flex flex-column gap-4 gap-md-5">
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                BNB Chain
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                Ethereum
                                            </a>
                                        </li>
                                        <li className="dropdown d-flex align-items-center wow fadeInUp">
                                            <i className="ti ti-arrow-badge-right fs-ten s2-color" />
                                            <a href="javascript:void(0)" className="fs-ten">
                                                ERC20
                                            </a>
                                        </li>
                                    </ul>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="container-fluid ">
                    <div className="row align-items-center justify-content-center py-3 py-sm-4 py-lg-6 bg2-color">
                        <div className="col-sm-10 col-xxl-8 order-2 order-sm-1">
                            <div className="footer__copyright text-center d-flex align-items-center justify-content-center justify-content-md-between flex-wrap flex-md-nowrap">
                                <div className="coyp-rightarea">
                                    <span className="p4-color roboto text-center text-md-start">
                                        Copyright 2024 -
                                        <a href="/" className="p4-color">
                                            ConiX
                                        </a>
                                        All Rights Reserved{" "}
                                        <span className="p4-color fs-five mx-2">|</span> Design By
                                        <a
                                            href="https://themeforest.net/user/uiaxis"
                                            className="p4-color roboto"
                                        >
                                            {" "}
                                            UIAXIS
                                        </a>
                                    </span>
                                </div>
                                <div className="privacy-policay d-flex align-items-center gap-3">
                                    <a
                                        href="javascript:void(0)"
                                        className="p4-color roboto ps-4 ps-sm-6"
                                    >
                                        Privacy Policy
                                    </a>
                                    <span className="p4-color fs-five">|</span>
                                    <a href="javascript:void(0)" className="p4-color roboto">
                                        Cookie Policy
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </footer>
            {/* Footer Section Ends */}
        </>

    )
}

export default Staking