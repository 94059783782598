import React, { useState, useEffect } from 'react';
import Header from '../component/header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function Sign_up() {
    const [formData, setFormData] = useState({
        name: '',
        email: '',
        mobile: '',
        password: '',
        sponsor_code: '',
        agree: false
    });
    const [loader, setLoader] = useState(false);
    const baseApiUrl = process.env.REACT_APP_BASE_API_URL;

    // Extract invite code from URL and set it in sponsor_code field
    useEffect(() => {
        const urlParams = new URLSearchParams(window.location.search);
        const inviteCode = urlParams.get('invite');
        if (inviteCode) {
            setFormData(prevData => ({ ...prevData, sponsor_code: inviteCode }));
        }
    }, []);

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        setFormData({
            ...formData,
            [name]: type === 'checkbox' ? checked : value
        });
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        if (!formData.agree) {
            toast.error('Please agree to the terms and conditions');
            return;
        }

        try {
            setLoader(true);
            const response = await fetch(`${baseApiUrl}/register`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            const data = await response.json();

            if (data.status) {
                toast.success('Account created successfully');
                setFormData({
                    name: '',
                    email: '',
                    mobile: '',
                    password: '',
                    sponsor_code: '',
                    agree: false
                });
            } else {
                toast.error(data.message || 'Failed to create account');
            }
        } catch (error) {
            toast.error('Something went wrong. Please try again later.');
        } finally {
            setLoader(false);
        }
    };

    return (
        <>
            <ToastContainer />
            <Header />
            <section className="pt-120 pb-120 bg9-color">
                <div className="container pt-15 pt-md-20">
                    <div className="row justify-content-center justify-content-center">
                        <div className="col-md-8 col-lg-6 col-xl-5">
                            <div className="modal-content bg7-color rounded-20 p-3 p-sm-4 p-md-6">
                                <div className="modal-header">
                                    <h5 className="modal-title fs-5">Create Account</h5>
                                </div>
                                <div className="modal-body">
                                    <div className="apex_section__aside-logintop d-flex align-items-center gap-3 flex-wrap">
                                        <span>Already have an account?</span>{" "}
                                        <i className="ti ti-arrows-exchange-2 fs-four p1-color" />
                                        <a href="/login" className="d-flex align-items-center gap-3 p1-color">
                                            Log In
                                        </a>
                                    </div>
                                    <div className="apex_section__aside-tabs mt-4">
                                        <div className="singletab mb-5 mb-md-6">
                                            <div className="tabcontents">
                                                <div className="tabitem active">
                                                    <div className="apex_section__forms">
                                                        <form className="d-flex flex-column" onSubmit={handleSubmit}>
                                                            {/* Input fields */}
                                                            <div>
                                                                <span className="mb-3 mb-md-4 text-start">
                                                                    Full Name
                                                                </span>
                                                                <input
                                                                    className="br2 mb-3 mb-md-4"
                                                                    type="text"
                                                                    placeholder="Enter Full Name"
                                                                    name="name"
                                                                    value={formData.name}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                            <div>
                                                                <span className="mb-3 mb-md-4 text-start">
                                                                    Email
                                                                </span>
                                                                <input
                                                                    className="br2 mb-3 mb-md-4"
                                                                    type="email"
                                                                    placeholder="Enter Email"
                                                                    name="email"
                                                                    value={formData.email}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                            <div>
                                                                <span className="mb-3 mb-md-4 text-start"> Mobile Number</span>
                                                                <div className="form-control text-white" style={{ background: 'none', display: 'flex', alignItems: 'center' }}>
                                                                    <select className="text-white"  style={{  background: 'none',  marginRight: '8px', width: '70px',  height: '100%', border: 'none', color: 'white', appearance: 'none',  padding: '0 5px' }}>
                                                                    <option value="+1">+1 (USA/Canada)</option>
                                                                    <option value="+44">+44 (UK)</option>
                                                                    <option value="+61">+61 (Australia)</option>
                                                                    <option value="+81">+81 (Japan)</option>
                                                                    <option value="+82">+82 (South Korea)</option>
                                                                    <option value="+86">+86 (China)</option>
                                                                    <option value="+91">+91 (India)</option>
                                                                    <option value="+92">+92 (Pakistan)</option>
                                                                    <option value="+93">+93 (Afghanistan)</option>
                                                                    <option value="+94">+94 (Sri Lanka)</option>
                                                                    <option value="+95">+95 (Myanmar)</option>
                                                                    <option value="+212">+212 (Morocco)</option>
                                                                    <option value="+213">+213 (Algeria)</option>
                                                                    <option value="+234">+234 (Nigeria)</option>
                                                                    <option value="+249">+249 (Sudan)</option>
                                                                    <option value="+250">+250 (Rwanda)</option>
                                                                    <option value="+254">+254 (Kenya)</option>
                                                                    <option value="+255">+255 (Tanzania)</option>
                                                                    <option value="+256">+256 (Uganda)</option>
                                                                    <option value="+260">+260 (Zambia)</option>
                                                                    <option value="+263">+263 (Zimbabwe)</option>
                                                                    <option value="+358">+358 (Finland)</option>
                                                                    <option value="+380">+380 (Ukraine)</option>
                                                                    <option value="+852">+852 (Hong Kong)</option>
                                                                    <option value="+853">+853 (Macau)</option>
                                                                    <option value="+886">+886 (Taiwan)</option>
                                                                    <option value="+971">+971 (UAE)</option>
                                                                    <option value="+972">+972 (Israel)</option>
                                                                    <option value="+974">+974 (Qatar)</option>
                                                                    <option value="+966">+966 (Saudi Arabia)</option>
                                                                    <option value="+998">+998 (Uzbekistan)</option>
                                                                        {/* Add other essential country codes */}
                                                                    </select>
                                                                    <input className="text-white"  style={{ background: 'none', border: 'none', outline: 'none', padding: '5px 10px', flexGrow: 1 }} type="number" placeholder="Enter Mobile Number" name="mobile" value={formData.mobile} onChange={handleChange} required/>
                                                                </div>
                                                            </div>
                                                            <div>
                                                                <span className="mb-3 mb-md-4 text-start">
                                                                    Password
                                                                </span>
                                                                <input
                                                                    className="br2 mb-3 mb-md-4"
                                                                    type="password"
                                                                    placeholder="Enter Password"
                                                                    name="password"
                                                                    value={formData.password}
                                                                    onChange={handleChange}
                                                                    required
                                                                />
                                                            </div>
                                                            <span className="mb-3 mb-md-4 text-start">
                                                                Referral Code (Optional)
                                                            </span>
                                                            <input
                                                                className="br2 mb-3 mb-md-4"
                                                                type="text"
                                                                name="sponsor_code"
                                                                value={formData.sponsor_code}
                                                                onChange={handleChange}
                                                            />
                                                            <div className="d-flex align-items-center gap-3">
                                                                <input
                                                                    type="checkbox"
                                                                    id="rcode"
                                                                    name="agree"
                                                                    checked={formData.agree}
                                                                    onChange={handleChange}
                                                                />
                                                                <label className="i-aggres text-start" htmlFor="rcode">
                                                                    Agree to Terms of Service and Privacy Policy
                                                                </label>
                                                            </div>

                                                            {/* Button or Loader */}
                                                            {loader ? (
                                                                <div className="text-center">
                                                                    <img src="assets/images/loader/loader.webp" style={{ height: "120px" }} alt="loader" />
                                                                </div>
                                                            ) : (
                                                                <button className="cmn-btn px-6 py-3 w-100 mt-5 mt-md-6" type="submit">
                                                                    Create Account
                                                                </button>
                                                            )}
                                                        </form>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <footer className="footer pt-120 bg5-color">
                {/* Footer content */}
            </footer>
        </>
    );
}

export default Sign_up;
